<template>
<div class="home-tags-box" style="">
<a v-on:click="$router.push('/tags/'+'سوم')">
<span>#</span><span>ع السوم</span>
</a>
<a v-on:click="$router.push('/tags/'+'شيول')">#شيول</a>
<a v-on:click="$router.push('/tags/'+'كتر')">#كتربلر</a>
<a v-on:click="$router.push('/tags/'+'بوكلين')">#بوكلين</a>
<a v-on:click="$router.push('/tags/'+'قريدر')">#قريدر</a>
<a v-on:click="$router.push('/tags/'+'بلدوزر')">#بلدوزر</a>
<a v-on:click="$router.push('/tags/'+'950')">#950M</a>
<a v-on:click="$router.push('/tags/'+'980')">#980C</a>
<a v-on:click="$router.push('/tags/'+'XGMA')">#XGMA</a>
<a v-on:click="$router.push('/tags/'+'DR9')">#DR9</a>
<a v-on:click="$router.push('/tags/'+'لوحة')">#لوحة</a>
<a v-on:click="$router.push('/tags/'+'استم')">#استماره</a>
<a v-on:click="$router.push('/tags/'+'مضخة')">#مضخة</a>
<a v-on:click="$router.push('/tags/'+'هيتاشي')">#هيتاشي</a>
<a v-on:click="$router.push('/tags/'+'فولفو')">#فولفو</a>
<a v-on:click="$router.push('/tags/'+'امريكي')">#فراده</a>
<a v-on:click="$router.push('/tags/'+'راس')">#راس مان</a>
<a v-on:click="$router.push('/tags/'+'مولد')">#مولد</a>
<a v-on:click="$router.push('/tags/'+'JCB')">#JCB</a>
<a v-on:click="$router.push('/tags/'+'مرس')">#مرسيدس</a>
<a v-on:click="$router.push('/tags/'+'بوبكات')">#بوبكات</a>
<a v-on:click="$router.push('/tags/'+'2021')"><span>#</span>2021</a>
<a v-on:click="$router.push('/tags/'+'2020')"><span>#</span>2020</a>
<a v-on:click="$router.push('/tags/'+'2017')"><span>#</span>2017</a>
<a v-on:click="$router.push('/tags/'+'2017')"><span>#</span>2017</a>
<a v-on:click="$router.push('/tags/'+'2015')"><span>#</span>2015</a>
<a v-on:click="$router.push('/tags/'+'2014')"><span>#</span>2014</a>
<a v-on:click="$router.push('/tags/'+'2013')"><span>#</span>2013</a>
<a v-on:click="$router.push('/tags/'+'201')"><span>#</span>2010</a>
<a style="min-width:120px;" v-on:click="$router.push('/tags/'+'بطاق')">#بطاقة جمركية</a>
<a style="min-width:120px;" v-on:click="$router.push('/tags/'+'اكتروس')">#راس اكتروس</a>
</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
name: 'ViewTags',
components: {
},
//props: {msg: String},
data(){
return{
}
},
methods:{

},
watch:{

},
computed: {
...mapState({
setup: state => state.setup,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-tags-box{
/*background: linear-gradient(to bottom right, #fff 50%,#f1f1f1 20%,#fdfdfd 30%);*/
position:relative;display: flex;flex-wrap:wrap;
width:100%;height: auto;color:#4e4e4e;
position:relative;z-index:0;
/*padding:14px;*/
padding:18px;margin-right:auto;margin-left:auto;
font-family: 'Cairo', sans-serif;font-weight:400;
cursor:pointer;justify-content: right;
}
.home-tags-box a{
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
font-size:16px;color:#4e4e4e;height:50px;border-radius:8px;min-width:92px;
margin-left:12px;margin-bottom:12px;
border-top:1px solid #ccc;background:#fff;
border-right:1px solid #ccc;
border-bottom:1px solid #cdcdcd;
border-left:1px solid #ccc;
}
.home-tags-box a:link{color:#4e4e4e;}
.home-tags-box a:visited{color:#4e4e4e;}

@media (min-width: 200px) and (max-width: 575px){
.home-tags-box{
justify-content:center;
}
}
@media (min-width: 800px) and (max-width: 1200px){

}
@media (min-width: 1200px){

}

</style>