<template>
<vue-horizontal class="swip-item-home" ref="horizontal_item"
 snap="center" :displacement="displacement" :button="false"
 @scroll-debounce="onScrollDebounce">
<div class="menu-item-box listbox-home-warp" v-for="(cat, index) in cats">
<div class="--scroll_items-- listbox-home-box" v-on:scroll="handleScroll" style="">
<div v-opacity class="listbox-home-item" 
v-for="(item, index) in filteredList"  
v-show="item.tags.includes(''+cat.Aname+'')"
v-on:click="$router.push('/'+item.guid),setitem_index(index)" :data-row="index">
<!--<router-link to="/item" replace></router-link>-->
<!--hosturl+'/media/sm'+img.src-->
<div class="listbox-home-imgbox" v-for="(img, index) in item.imgaes" v-if="img.home"
 style="position:relative;background: rgba(0,0,0,.3);">
<div class="listbox-home-imgkm"
style="position:absolute;top:0px;right:0px;display: flex;flex-wrap:wrap;
height:38px;max-height:38px;width:auto;justify-content: center; align-content:center; z-index:1;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);
border-radius: 4px;height:24px;max-height:24px;width:auto;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;justify-content: center;padding-left:7px;padding-right:7px;">
مميز بلس
</div>
</div>
<div class="listbox-home-imglike"
style="position:absolute;top:0px;left:0px;display: flex;flex-wrap:wrap;
height:38px;max-height:38px;width:38px;justify-content: center; align-content:center; z-index:1;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);
border-radius: 4px;height:24px;max-height:24px;width:24px;
font-size:12px;font-weight:400;white-space:nowrap;justify-content: center;">
<span class="mdi mdi-18px mdi-heart-outline j7app-span-icon" style="color:rgba(255,255,255,.9);line-height:24px;"></span>
</div>
</div>
<div class="listbox-home-imgcat"
style="background: rgba(0,0,0,.4);position:absolute;bottom:1px;right:0px; display: flex;flex-wrap:wrap;
width:100%;justify-content: center;align-content:center;z-index:1;">
<span style="display: flex;flex-wrap:wrap;width:auto;max-width:90%;border-radius:5px;color:#ddd;align-content:center;
font-size:12px;font-weight:400;height:32px;max-height:32px;overflow:hidden;white-space:nowrap;">
4{{index+1}}2 متر مربع
</span>
</div>
<div class="listbox-home-img" style="position:relative;z-index:0;max-height:128px;overflow:hidden;">
<img 
 alt="يتم التحميل..." class="lazy" style="position:relative;z-index:0;
height:100%;width:100%;object-fit: cover;"
draggable="false" :data-src="img.home==true?hosturl+'/media/sm'+img.src:''">
</div>
</div>
<div class="listbox-home-data">
<div class="listbox-home-data-box" style="position:relative;">
<div class="listbox-home-data-name" style="background:rgba(255,255,255,.9);width:100%;height:30px;font-size:13px;font-weight:400;align-items:center;">
<span>
{{item.title}}
</span>
</div>
<div class="listbox-home-data-info" style="background:rgba(255,255,255,.9);width:100%;height:30px;justify-items:center;">
<div style="border:1px solid #f5f5f5;border-left:none; position:relative;width:33.33%;height:100%;">
<a style="border-radius:2px;width:22px;height:22px;justify-content:center;">
{{item.rooms}}
</a>
<span class="mdi mdi-18px mdi-bed-king-outline" style="color:#4e4e4e;"></span>
<!--<img src="img/bed.png" style="display:flex; justify-content:center;max-width:20px;height:20px;" />-->
</div>
<div style="border:1px solid #f5f5f5;border-left:none; position:relative;width:33.33%;height:100%;">
<a style="border-radius:2px;width:22px;height:22px;justify-content:center;">
{{item.lounges}}</a>
<span class="mdi mdi-18px mdi-sofa-outline" style="color:#4e4e4e;"></span>
<!--<img src="img/living-room.png" style="display:flex; justify-content:center;max-width:20px;height:20px;" />-->
</div>
<div style="border:1px solid #f5f5f5;border-left:none; position:relative;width:33.33%;height:100%;">
<a style="border-radius:2px;width:22px;height:22px;justify-content:center;">
{{item.bathrooms}}</a>
<span class="mdi mdi-18px mdi-shower" style="color:#4e4e4e;"></span>
<!--<img src="img/shower.png" style="display:flex; justify-content:center;max-width:20px;height:20px;" />-->
</div>
</div>
<div class="listbox-home-data-address" style="background:rgba(255,255,255,.6);padding:0;width:100%;height:25px;align-items:center;">
<span>
{{item.address}} 
</span>
</div>
<div class="listbox-home-data-price" style="position:relative;background:rgba(255,255,255,.2);border-top:1px solid #f5f5f5;padding:0;width:100%;height:30px;align-items:center;">
<span style="color:#4e4e4e;height:100%;width:auto;justify-content:center;align-items:center;">
{{item.price}} {{currency}}  
</span>
</div>
</div>
</div>
</div>
</div>
</div>
</vue-horizontal>
</template>
<script>
import {mapState} from 'vuex';
import VueHorizontal from 'vue-horizontal';

export default {
name: 'ItemList',
components: {VueHorizontal},
//props: {msg: String},
data(){
return {
iframe:null,
width: 0,
scrollWidth: 0,
left: 0,
index: 0,
swaip:0,load:'show',
ref_item_box:''
}
},
methods:{
formatPrice : function (value) {
if (typeof value !== "number") {
return value;
}
const formatter = new Intl.NumberFormat('en-US', {
minimumFractionDigits: 2,
maximumFractionDigits: 2
});
return formatter.format(value);
},
lazy_update(){
this.$store.commit("lazy_update");
},
onScrollDebounce({ scrollWidth, width, left }) {
//this.left = left;
//this.width = width;
//this.pages = Math.round(scrollWidth / width);
this.scrollWidth = scrollWidth;
this.index = Math.round(left / width);
var num = '' + this.index + '';
var num1 = num.replace('-','');
var num2 = parseInt(num1);
this.setpage(num2);
},
SetViewCat: function (val) {
this.$store.commit("SetViewCat", val);
},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
pan_load_down: function (e,val) {
var deltaNowY = 0;
var el = val ;
deltaNowY = e.deltaY;
$('.refresh-item-box').show();
//this.ref_item_box='show';
if(deltaNowY < 50){
$(el).css("-webkit-transform", "translateY(" + deltaNowY + "px)");
$(el).css("transform", "translateY(" + deltaNowY + "px)");
}
else {
setTimeout(function () {
$(el).css("transform", "translate(0px,0px)");
$(el).css("-webkit-transform", "translate(0px,0px)");
}, 100)
}
},
pan_load_end: function (e,val) {
var el = val ;
setTimeout(function () {
$(el).css("transform", "translate(0px,0px)");
$(el).css("-webkit-transform", "translate(0px,0px)");
}, 400)
},
formatPrice : function (val) {
},
SetPreview : function (val) {
this.$store.commit("SetPreview", val);
},
setitem_index(val){
this.$store.commit("setitem_index", val);
},
Setheight_navbar(val) {
var vh = window.innerHeight;
if (val == 55) {
$('.main-home-page').css('height', vh - 55);
} else {
$('.main-home-page').css('height', vh - 148);
}
this.$store.commit("Setheight_navbar", val);
},
Reset_search_val: function ()
{
this.$store.commit("Set_search_val", '');
this.lazy_update();
},
setpage:function (val) {
this.$store.commit("setpage", val);
$('.nav-icon').addClass('mdi-tag-multiple');
$('.item-home-nbtn').removeClass('active');
$('.item-home-nbtn:nth-child(' + (val+1) + ')').addClass('active');
$('.item-home-nbtn:nth-child(' + (val+1) + ')').find('span').removeClass('mdi-tag-multiple').addClass('mdi-checkbox-marked');
},
handleScroll(event){
var vh = 0;
var scrollTop = event.currentTarget.scrollTop;
   
vh = window.innerHeight;

if(this.hide_navbar == '') {
if(scrollTop > 55) {
this.SetviewNav('');
this.Setheight_navbar(55);     
//this.load = 'hide';
$('.home-nav').css('height', '55px');
$('.main-home-page').css('height', vh - 55);
$('.scroll_items').css('height', vh - 55);
$('.scroll_items').css('maxHeight', vh - 55);
$('.refresh-item-box').hide();
}
else {
this.SetviewNav('menu');
this.Setheight_navbar(55);
//this.load='show';
$('.home-nav').css('height', '148px');
$('.main-home-page').css('height', vh - 148);
$('.scroll_items').css('height', vh - 148);
$('.scroll_items').css('maxHeight', vh - 148);
}
}
},
},
created() {

},
mounted(){
this.lazy_update();
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();

},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
items : state => state.items,
posts : state => state.posts,
viewCat : state => state.viewCat,
view_Nav : state => state.view_Nav,
displacement: state => state.displacement,
hosturl : state => state.hosturl,
currency : state => state.currency,
hide_navbar : state => state.hide_navbar,
preview_index : state => state.preview_index,
pageview:state => state.pageview,
preview: state => state.preview,
search_val: state => state.search_val,
height_navbar : state => state.height_navbar

}),
filteredList(){
return this.posts.filter(item =>
{
//return this.search_val.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
return item.title.toLowerCase().includes(this.search_val.toLowerCase())
|| item.tags.toLowerCase().includes(this.search_val.toLowerCase()) 
|| item.address.toLowerCase().includes(this.search_val.toLowerCase()) 
|| item.price==this.search_val;   
});
}
},
watch:{
// whenever question changes, this function will run
search_val: function (v)
{
//$('.item-home-box').find('.lazy').removeClass('entered ').removeClass('loaded').removeAttr('data-ll-status');
$('img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
pageview: function ()
{
this.$refs.horizontal_item.scrollToIndex(this.pageview);
}
}
}
</script>
<style scoped>
.home-item-list-box{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;
overflow-y: hidden;scrollbar-width:none;
}
.swip-item-home{
display: flex;width:100%;margin-bottom: 0px;
max-width:100%;padding:7px;
margin: 0;padding-bottom: 0px;border: none;
box-sizing: content-box;overflow-x: scroll;overflow-y: hidden;
scroll-snap-type: x mandatory;
scrollbar-width:none;
-webkit-overflow-scrolling: touch;
position:relative;
}
.listbox-home-warp{
display:flex; flex-wrap:wrap;height:fit-content;
width: 100%; position: relative;
touch-action: none;touch-action: pan-x pan-y;
position:relative;font-family: 'Noto Kufi Arabic';font-weight:400;
margin:0;justify-content: center;

}
.listbox-home-box{
display: grid;width: 100%; 
grid-template-columns:repeat(auto-fill,minmax(300px,1fr));
grid-auto-rows: 128px; gap:14px;
/*padding-bottom:100px;*/
touch-action: pan-x pan-y; height: fit-content;
justify-content: center;
align-items: flex-start;
overflow-y: scroll; overflow-x: hidden; 
scrollbar-color: auto; scrollbar-width: none;
scroll-snap-type: mandatory;
padding-bottom:14px;
}
.listbox-home-item{
position:relative;background: #fff;
display:flex;flex-wrap:wrap;
justify-content:center;text-align:center;align-items:center;
width:100%;height:128px;max-height:128px;
z-index: 0;margin:0;cursor: pointer;
touch-action: none; touch-action: pan-x pan-y;
/*box-shadow: 0px 4px 4px 0px #f5f5f5;*/
overflow: hidden;
/*border-radius:5px 5px 5px 5px;*/
border:1px solid #ededed;
}
.listbox-home-imgbox{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
max-width:40%;
overflow: hidden;scrollbar-width: none;
}
.listbox-home-img{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
height:128px;max-width:100%;
/*border-radius:5px 5px 5px 5px;*/
}
.listbox-home-data{
display: flex;height:100%;max-height:128px;max-width:60%;
align-items: center;justify-content: center;
scrollbar-width: none;
overflow: hidden;z-index: 0;
}
.listbox-home-data-box{
position:relative;width: 100%;height:128px;
display: flex;flex-wrap: wrap; 
justify-content: center; justify-items:center;
text-align:center;z-index: 0;
}
</style>
<style scoped>
.listbox-home-data-name{
display: flex;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;align-content:center;
}
.listbox-home-data-name span{
display:flex;justify-content:right;
white-space:nowrap;max-width:75%;font-size:14px;font-weight:200;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}
.listbox-home-data-address{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
font-size: 12px;font-weight:200;
justify-content: center;color:#000;
align-content:center;
}
.listbox-home-data-address span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:13px;white-space:nowrap;max-width:65%;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}

.listbox-home-data-name img{
padding-right:5px;padding-left:5px;
}

.listbox-home-data-info{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color:#999;justify-content: center;align-content:center;
}
.listbox-home-data-info div{
display:flex;flex-wrap:wrap;justify-content:center;
font-size:12px;white-space:nowrap;align-content:center;
color:#999;scrollbar-width:none;
}
.listbox-home-data-info span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#999;align-content:center;
}
.listbox-home-data-info a{
display:flex;flex-wrap:wrap;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#555;justify-content:right;align-content:center;
}
.listbox-home-data-price{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color: #000; justify-content: center;align-content:center;
}
.listbox-home-data-price span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;
color:#555;align-content:center;
margin:auto;
}
</style>
