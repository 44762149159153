<template>
<div style="display: flex;flex-wrap:wrap;width:36px;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);
border-radius: 4px;height:28px;max-height:28px;width:32px;position:relative;
font-size:12px;font-weight:400;white-space:nowrap;justify-content: center;"
 v-on:click="showInfo('.fix-count-info.like'+'-'+code),set_Like(guid)">
<span class="mdi mdi-18px mdi-heart-outline" style="color:#f7f6f6;line-height:28px;"></span>
<div :class="'animate__animated animate__headShake fix-count-info like'+'-'+code" style="display:none;position:absolute;z-index:1;top:0px;left:30px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
border:1px solid #ddd;background:#fdfdfd;color:#333;font-size:14px;width:72px;height:28px;line-height:28px;">
<span style="padding-left:3px;">تم</span>
<span class="mdi mdi-18px mdi-check" style="color:#2aca7e;line-height:28px;"></span>
</span>
</div>
</div>
<div v-if="gir" style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);position:relative;
border-radius: 4px;height:28px;max-height:28px;width:32px;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;margin-top:7px;
justify-content: center;align-content:center;" v-on:click="showInfo('.fix-count-info.room'+'-'+code)">
<span class="mdi mdi-18px mdi-timelapse" style="color:#f2f2f2;line-height:28px;"></span>
<div :class="'animate__animated animate__headShake fix-count-info room'+'-'+code" style="display:none;position:absolute;z-index:1;top:0px;left:30px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
background:#f44a6f;color:#fdfdfd;font-size:13px;width:100px;height:28px;">
الحالة {{type}}
</span>
</div>
</div>
<div v-if="gir" style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);margin-top:7px;
border-radius: 4px;height:28px;max-height:28px;width:32px;color:#ddd;
font-size:12px;font-weight:400;white-space:nowrap;position:relative;
justify-content: center;align-content:center;" v-on:click="showInfo('.fix-count-info.lounge'+'-'+code)">
<span class="mdi mdi-18px mdi-hand-wave-outline" style="color:#eee;line-height:28px;"></span>
<div :class="'animate__animated animate__headShake fix-count-info lounge'+'-'+code" style="display:none;position:absolute;z-index:1;top:0px;left:30px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
background:#21a9df;color:#fdfdfd;font-size:13px;width:100px;height:28px;">
القير {{gir}}
</span>
</div>
</div>
<div v-if="gir" style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);margin-top:7px;
border-radius: 4px;height:28px;max-height:28px;width:32px;color:#ddd;
font-size:12px;font-weight:400;white-space:nowrap;position:relative;
justify-content: center;align-content:center;" v-on:click="showInfo('.fix-count-info.bathroom'+'-'+code)">
<span class="mdi mdi-18px mdi-speedometer" style="color:#eee;line-height:28px;"></span>
<div :class="'animate__animated animate__headShake fix-count-info bathroom'+'-'+code" style="display:none;position:absolute;z-index:1;top:0px;left:30px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
border:1px solid #ddd;background:#fdfdfd;color:#333;font-size:13px;width:128px;height:28px;">
الموديل {{model}}
</span>
</div>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
export default {
name: 'ItemListImgInfo',
components: {

},
props: ['img','code','guid','type','gir','model','cat_index'],
data(){
return {
iframe:null,
}
},
methods:{
play_vid:function(val){ 
var player = document.getElementById('playvid'+val);
$('video').each(function() {
if($(this).attr("id")!='playvid'+val){
$(this).get(0).pause();
}
});
if (player.paused){
player.play();
//$('#playvid'+val).data('p-state','play');
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
else{
player.pause();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).show();
}
},
play_vid_mute:function(val){ 
var player = document.getElementById('playvid'+val);
if (player.muted){
player.muted = false;
$('#playvid-volume-on-'+val).show();
$('#playvid-volume-off-'+val).hide();
}
else{
player.muted = true;
$('#playvid-volume-on-'+val).hide();
$('#playvid-volume-off-'+val).show();
}
},
lazy_update(){
this.$store.commit("lazy_update");
},
visibilityChanged:function(isVisible,entry,code) {
if(isVisible){
//console.log(entry);
var animation=$("#"+entry.target.id).data('animation');
if(!entry.target.classList.contains(animation)){
//let domElement = entry.target.id;
entry.target.classList.add(animation);
}
this.show_all_info(code,3200);
}
},
show_all_info:function(code,timeout) {
$('.room-'+code).show();
setTimeout(function () {
$('.lounge-'+code).show();
}, 800);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1500);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
},
set_Like:function (guid){
var ck =this.likes.filter(item =>{
return item.like==guid;   
});
if(ck.length<1)
this.$store.commit("push_likes",guid);
this.$cookies.set('likes',this.likes);
}
},
created() {
this.lazy_update();
},
destroyed(){
},
mounted(){
this.lazy_update();
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed:{
...mapState({
hosturl : state => state.hosturl,
currency : state => state.currency,
search_val: state => state.search_val,
likes: state => state.likes,
}),
},
watch:{
}
}
</script>
<style>

</style>
<style scoped>

</style>
