<template>
<div class="frp" style="width:94%;height:auto;max-width:575px;
margin-right:auto;margin-left:auto;
background-color:#fff;font-family:'Noto Kufi Arabic',sans-serif;">
<div class="frp" style="justify-content: right;cursor:pointer;
padding-right:24px;padding-left:24px;
width:100%;height:60px;background-color:#fff;">
<span style="font-size:18px;">ترتيب حسب :</span>
</div>
<div class="frp ord-list-box" style="cursor:pointer;width:100%;height:auto;">
<div class="frp ord-list-item" v-opacity v-for="item in filter_nav" 
v-on:click="filter_by(item.val),set_pop_order_val(item.name),set_pop_order_by('')"
style="cursor:pointer;width:45%;height:60px;
border:1px solid #ddd;margin-left:2.5%
;margin-bottom:10px;">
<span class="mdi mdi-18px mdi-checkbox-blank-circle-outline frp" 
 style="position:relative;height:18px;width:18px;
border-radius:50%;background-color:#fff;">
<span class="span-fixbtn" v-if="pop_order_val==item.name"
 style="height:18px;width:18px;">
<span class="span-fixbtn-bg"
 style="height:7px;width:7px;background:#5fda9f;"></span>
</span>
</span>
<span class="frp" style="padding-right:6px;">{{item.name}}</span>
</div>
</div>
<div class="frp btn-ck-fliter" style="cursor:pointer;width:100%;height:auto;">
<span class="frp" style="display:none;
width:94%;height:60px;font-size:18px;color:#fff;
background: rgba(78, 78, 78, 0.8);margin-right:auto;margin-left:auto;
border-radius:5px;margin-top:5px;margin-bottom:14px;">
تطبيق
</span>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import VueHorizontal from 'vue-horizontal';
import CountItemByFilter from '../components/CountItemByFilter.vue'
export default {
name: 'ItemOrderBy',
components: {
VueHorizontal,CountItemByFilter
},
//props: {msg: String},
data(){
return {
nav_select:'',
nav_show:'',
filter_nav:[
{name:'الاحدث',val:'code_desc'},
{name:'الاقدم',val:'code'},
{name:'اعلى سعر',val:'price_desc'},
{name:'اقل سعر',val:'price'}
]
}
},
methods: {
set_pop_order_by:function(val){ 
this.$store.commit("set_pop_order_by",val);
},
set_pop_filter_by:function(val){ 
this.$store.commit("set_pop_filter_by",val);
},
set_pop_order_val:function(val){ 
this.$store.commit("set_pop_order_val",val);
},
set_pop_filter_val:function(val){ 
this.$store.commit("set_pop_filter_val",val);
},
filter_by:function (val) {
this.$store.commit("set_filter_name",val);
$('img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
lazy_update(){
this.$store.commit("lazy_update");
}
},
watch:{
close_pup:function(){
if(this.close_pup!='') {
this.nav_show='';
}
}
},
mounted(){
//var old_px = $('.j7app-main-home').css("padding-top");
//$('.j7app-main-home').css("padding-top","0px")
//alert(css);
},
//
computed: {
...mapState({
filter_name: state => state.filter_name,
filter_ck: state => state.filter_ck,
filter_val: state => state.filter_val,
filter_count: state => state.filter_count,
selctedCat: state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
likes : state => state.likes,
viewCat : state => state.viewCat,
hide_navbar: state => state.hide_navbar,
close_pup: state => state.close_pup,
pop_order_val: state => state.pop_order_val,
pop_filter_val: state => state.pop_filter_val,
pageview : state => state.pageview
})
}
}
</script>
<style scoped>
.frp{display:flex;flex-wrap:wrap;align-content:center;
justify-content: center;align-items:center;}
.span-fixbtn{position:absolute;top:0px;z-index:1;
display: flex;flex-wrap:wrap;justify-content:center;
align-content:center;align-items:center;
}
.span-fixbtn-bg{
position:relative;display: flex;flex-wrap:wrap;justify-content:center;
align-content:center;align-items:center;
border-radius:50%;z-index:1;margin-left:0.1px;
}
.top-bar-pc{
position: relative; z-index: 0;
display: flex;flex-wrap:wrap;
width:100%;height:auto;
align-content:flex-start;justify-content:center;
margin-right:auto;margin-left:auto;
background:#fff;
}
</style>

