<template>
<div class="j7-view-item-info-box" style="padding-left:15px;padding-right:15px;display: flex; flex-wrap: wrap;width:100%;color:#888;white-space:nowrap;
margin:auto;line-height:45px;font-family: 'Noto Kufi Arabic', sans-serif;">
<div style="display: flex; flex-wrap: wrap;justify-content:center;align-content:center;border:1px solid #eee;border-left:none; position:relative;width:33.33%;">
<a style="font-size:15px;font-weight:500;color:#888;border-radius:2px;width:22px;height:22px;justify-content:center;">
{{rooms}}
</a>
<span class="mdi mdi-24px mdi-bed-king-outline" style="color:#888;"></span>
<!--<img src="img/bed.png" style="display:flex; justify-content:center;max-width:20px;height:20px;" />-->
</div>
<div style="display: flex; flex-wrap: wrap;justify-content:center;align-content:center;border:1px solid #eee;border-left:none; position:relative;width:33.33%;">
<a style="font-size:15px;font-weight:500;color:#888;border-radius:2px;width:22px;height:22px;justify-content:center;">
{{lounges}}</a>
<span class="mdi mdi-24px mdi-sofa-outline" style="color:#888;"></span>
<!--<img src="img/living-room.png" style="display:flex; justify-content:center;max-width:20px;height:20px;" />-->
</div>
<div style="display: flex; flex-wrap: wrap;justify-content:center;align-content:center;border:1px solid #eee;position:relative;width:33.33%;">
<a style="font-size:15px;font-weight:500;color:#888;border-radius:2px;width:22px;height:22px;justify-content:center;">
{{bathrooms}}</a>
<span class="mdi mdi-24px mdi-shower" style="color:#888;"></span>
<!--<img src="img/shower.png" style="display:flex; justify-content:center;max-width:20px;height:20px;" />-->
</div>
</div>
</template>
<script>
export default {
name: 'ItemRinfo',
components: {},
props: ['rooms','lounges','bathrooms'],
data(){
return {
}
},
methods: {
},
watch:{

}
}
</script>

