<template>
<div class="animate__animated animate__bounceInRight top-bar-pc"
v-on:click.self="nav_show=false" v-if="view_Nav=='menu'"
style="position:relative;z-index:100;">
<!-- v-on:click="view_Nav=='admenu' ? view_Nav='menu' : view_Nav='admenu'" -->
<div class="top-bar-pc-contain" v-on:click.self="nav_show=false">
<div v-on:click="nav_show=false" style="display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
cursor:pointer;overflow:hidden;position:relative;width:112px;height:45px;align-items:center;
">
<span v-if="filter_val" style="color:rgba(78,78,78,.8);
font-size:14px;">{{filter_val}}</span>
<span v-else style="color:rgba(78,78,78,.8);
font-size:14px;">الكل</span>
<CountItemByFilter :item="filter_val" style="padding-right:5px;"></CountItemByFilter>
</div>
<div class="nitem-filter-box" style="display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;align-items:center;
cursor:pointer;position:relative;width:auto;
margin-right:auto;
border-radius:4px;height:45px;">
<div style="display:flex;flex-wrap:wrap;align-items:center;
justify-content:left;align-content:center;height:auto;
position:relative;z-index:1;width:auto;">
<div v-opacity v-on:click="set_pop_order_by('show')" style="
display:flex;flex-wrap:wrap;align-items:center;
justify-content:left;align-content:center;height:auto;
position:relative;z-index:1;min-width:auto;height:100%;">
<span v-if="pop_order_val!=''" style="font-size:14px;">{{pop_order_val}}</span>
<span v-else style="font-size:14px;">ترتيب حسب</span>
<span class="mdi mdi-24px mdi-chevron-down" 
 style="display: flex;flex-wrap:wrap;align-content:center;justify-content: left;
height:24px;width:24px;border-radius:100%;background-color:#fff;">
</span>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import VueHorizontal from 'vue-horizontal';
import CountItemByFilter from '../components/CountItemByFilter.vue'
export default {
name: 'ListItemNav',
components: {
VueHorizontal,CountItemByFilter
},
//props: {msg: String},
data(){
return {
nav_select:'',
nav_show:'',
pg_width:window.innerWidth,
filter_nav:[
{name:'الاحدث',val:'code_desc'},
{name:'الاقدم',val:'code'},
{name:'اعلى سعر',val:'price_desc'},
{name:'اقل سعر',val:'price'}
]
}
},
methods: {
set_pop_order_by:function(val){ 
this.$store.commit("set_pop_order_by",val);
},
set_pop_filter_by:function(val){ 
this.$store.commit("set_pop_filter_by",val);
},
filter_by:function (val) {
this.$store.commit("set_filter_name",val);
}
},
watch:{
close_pup:function(){
if(this.close_pup!='') {
this.nav_show='';
}
}
},
mounted(){
//var old_px = $('.j7app-main-home').css("padding-top");
//$('.j7app-main-home').css("padding-top","0px")
//alert(css);
},
//
computed: {
...mapState({
filter_name: state => state.filter_name,
filter_ck: state => state.filter_ck,
filter_val: state => state.filter_val,
filter_count: state => state.filter_count,
selctedCat: state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
likes : state => state.likes,
viewCat : state => state.viewCat,
hide_navbar: state => state.hide_navbar,
close_pup: state => state.close_pup,
pop_order_val: state => state.pop_order_val,
pageview : state => state.pageview
})
}
}
</script>
<style scoped>
.top-bar-pc{
position: relative; z-index: 0;
display: flex;flex-wrap:wrap;
width:100%;height:auto;
align-content:flex-start;justify-content:center;
margin-right:auto;margin-left:auto;
background:#fff;
}
.top-bar-pc-contain{
display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;height:auto;
font-family:'Noto Kufi Arabic',sans-serif;color:rgba(78,78,78,.8);font-size:16px;
position:relative;width:100%;margin:auto;background:#fff;
border-bottom:1px solid #eee;
}
@media (min-width: 255px) and (max-width: 575px){
.top-bar-pc-contain{width:90%;}
}
@media (min-width: 922px){
.top-bar-pc-contain{
width:100%;margin-top:7px;
padding-right:48px;padding-left:48px;
}
}
</style>

