<template>
<div class="animate__animated animate__bounceInRight top-bar-pc"
style="border-bottom:1px solid #ddd;">
<!-- v-on:click="view_Nav=='admenu' ? view_Nav='menu' : view_Nav='admenu'" -->
<div class="top-bar-pc-contain" style="display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;height:100px;
position:relative;width:100%;margin:auto;max-width:100%;
">
<div class="topbar-box" style="display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;
position:relative;width:100%;">
<!--<div class="topbar-right" v-on:click="$router.push('/')"
 style="display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
cursor:pointer;overflow:hidden;position:relative;width:118px;">
<img src="/img/nj.png" style="display:flex;flex-wrap:wrap;justify-content:center;
max-height:100px;position:relative;" />
</div>-->
<div class="topbar-center" v-on:click="$router.push('/')"
 style="display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
cursor:pointer;overflow:hidden;position:relative;width:100%;">
<NavbarPc></NavbarPc>
</div>
</div>
</div>

</div>
</template>
<script>
import { mapState } from 'vuex';
import VueHorizontal from 'vue-horizontal';
import NavbarPc from '../components/NavbarPc.vue'
export default {
name: 'TopBarNavPC',
components: {VueHorizontal,NavbarPc},
//props: {msg: String},
data(){
return {
input_val:'',serach_open:false
}
},
methods: {
lazy_update(){
this.$store.commit("lazy_update");
},
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
SetViewCat: function (val) {
this.$store.commit("SetViewCat", val);
},
Set_search_val: function ()
{
if(this.input_val.length > 0)
{this.$store.commit("Set_search_val", this.input_val);this.lazy_update();} 
}, 
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetHideNav: function (val) {
this.$store.commit("SetHideNav", val);
},
setpage: function (val) {
this.$store.commit("setpage", val);
},
setPaddingTop: function () {
if(this.hide_navbar=='menu'){
$('.main-home-page').css('padding-top','152px');
}
else{$('.main-home-page').css('padding-top','60px');}
}
},
watch:{
// whenever question changes, this function will run
input_val: function (v) { 
if (v.length > 0) {this.$store.commit("Set_search_val", v);}
if (v.length == 0) {this.$store.commit("Set_search_val", '');}
//this.$refs.horizontal.scrollToIndex(this.pageview + 1);
},
pageview: function () {
//this.$refs.horizontal.scrollToIndex(this.pageview + 1);
}
},
mounted(){
//var borowser=navigator.userAgent.toString().toLowerCase();
//if(borowser.includes('windows')||borowser.includes('mac')){
//$('.left-bar-mar').css('padding-left','38px');
//$('.right-bar-mar').css('padding-right','18px');
//}
},
//
computed: {
...mapState({
selctedCat: state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
likes : state => state.likes,
viewCat : state => state.viewCat,
view_Nav : state => state.view_Nav,
hide_navbar: state => state.hide_navbar,
pageview : state => state.pageview
})
}
}
</script>

<style scoped>
.vbtnframes {
transform: scale(1,1);
animation: vbtnload 1.4s linear infinite alternate;
}
.spanframes {
animation: spanload 1.4s linear infinite alternate;
}
.imgframes {
animation: imgload 1.8s linear infinite alternate;
}
.favframes {
transform: scale(1,1);
animation: favload 1.4s linear infinite alternate;
}
@keyframes favload{
0% {opacity:0.7;}
25% {opacity:0.8;}
50% {opacity:0.6;transform: scale(0.9,0.9);}
75% {opacity:0.4;transform: scale(0.95,0.95);}
100% {opacity:0.2;transform: scale(1.1,1.1);}
}
@keyframes imgload{
0% {opacity:0.7;}
50% {opacity:0.6;transform: scale(0.9,0.9);}
75% {opacity:0.4;transform: scale(0.1,1);}
100% {opacity:0.2;transform: scale(1,1);}
}
@keyframes spanload{
0% {opacity:0.6;color:#aaaaaa;}
25% {opacity:0.8;color:#aaaaaa;}
50% {opacity:0.6;color:#4e4e4e;}
75% {opacity:0.4;color:#aaaaaa;}
100% {opacity:0.8;color:#aaaaaa;}
}
@keyframes vbtnload{
0% {opacity:0.6;transform: scale(0.95,0.95);}
25% {opacity:0.8;transform: scale(1,1);}
50% {opacity:0.6;}
75% {opacity:0.8;transform: scale(0.95,0.95);}
100% {opacity:0.6;transform: scale(1,1);}
}
</style>
<style scoped>
.j7app-spacer{
position: relative;z-index: 0;justify-content:center;
display: flex;align-items: center;justify-items:center;
}
.top-bar-pc{
position: relative; z-index: 0;
display: flex;flex-wrap:wrap;
width:100%;height:auto;
align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
background:#fff;overflow:hidden;
}
.j7app-vbtn{
position: relative;background-color: transparent;
display: inline-flex;flex: 0 1 auto;flex-grow: 0;flex-shrink: 1;
flex-basis: auto;border-radius: 0;
font-size: 14px;height: inherit;
max-width: 168px;min-width: 80px;
color: rgba(0,0,0,.54);cursor: pointer;
text-transform: none;box-shadow: none;
white-space: nowrap;user-select: none;
align-items: center;justify-content: center;
text-decoration: none;outline: 0;
-webkit-appearance: button;border-radius: 50%;
transition-duration: .28s;
transition-property: box-shadow,transform,opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.j7app-vbtn-icon{
position: relative;background-color:#f9f9f9;
display: inline-flex;flex: 0 1 auto;flex-grow: 0;flex-shrink: 1;
flex-basis: auto;border-radius: 0;
font-size: 14px;height: inherit;
color: rgba(0,0,0,.54);cursor: pointer;
text-transform: none;box-shadow: none;
white-space: nowrap;user-select: none;
align-items: center;justify-content: center;
text-decoration: none;outline: 0;border:1px solid #eee;
-webkit-appearance: button;border-radius: 50%;
transition-duration: .28s;
transition-property: box-shadow,transform,opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.j7app-span-icon{
display:flex;width:100%;
color:#666;
justify-content:center;align-content:center;
}
.j7app-span-icon.active{color:#e03d53;}
</style>
<style scoped>
.absolute-serach-warp{
position:absolute;
width:100%;height:55px;z-index:10;
}
.absolute-serach-box{
position:relative; background:#fff;
display:flex;flex-wrap:wrap;margin-right:auto;
height:55px;width:100%;padding:0 14px 0 14px;
justify-content:center;align-content:center;
}
.absolute-serach-box-input{
display:flex;
border:1px solid #ddd;
}
@media (min-width: 200px) and (max-width: 575px){
.j7app-appbar{
height:55px;
align-content:center;
justify-content:center;
background:#fff;overflow:hidden;
}
.right-bar{
display:flex;flex-wrap:wrap;
align-content:center;max-width:192px;
width:25%;height:100%;
justify-content:center;margin-right:0px;
}
.left-bar{
display:flex;flex-wrap:wrap;height:100%;
align-content:center;
justify-content:center;
width:auto;overflow:hidden;margin-left:7px;
margin-right:auto;
}
}
@media (min-width: 600px){
.right-bar{
display:flex;flex-wrap:wrap;
align-content:center;max-width:192px;
width:100%;height:100%;
justify-content:center;margin-right:0px;
}
.left-bar{
display:flex;flex-wrap:wrap;height:100%;
align-content:center;
width:100%;overflow:hidden;margin-left:7px;
margin-right:auto;max-width:145px;
}
}
</style>
