<template>
<div class="j7-view-item-small-desc">
<div class="small-desc-html" v-html="desc">

</div>
</div>
</template>
<script>
export default {
name: 'ItemDesc',
components: {},
props: ['desc'],
data(){
return {
}
},
methods: {
},
watch:{

}
}
</script>
<style scoped>
.j7-view-item-small-desc{font-family: 'Noto Kufi Arabic', sans-serif;
width:100%;display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
margin:auto;
padding-left:14px;padding-right:14px;}
.small-desc-html{
display: flex; flex-wrap: wrap;width:94%;justify-content:right;align-content:center;
color:#2e2e2e;font-weight:500;white-space: pre-wrap;font-size:16px;text-align:right;
}

@media (min-width: 1024px){
.j7-view-item-small-desc{
justify-content:right;text-align:right;padding-top:14px;
}
.small-desc-html{
text-align:right;justify-content:right;
padding-right:14px;padding-left:14px;padding:14px;
}
}

</style>
