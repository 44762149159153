<template>
<div v-if="img.type.includes('video')" class="home_vide_player"
 style="backface-visibility: hidden;display:flex;flex-wrap:wrap;align-content:center;
justify-content:center;position:relative;height:100%;width:100%;">
<video preload="none"
 v-on:click.self="play_vid(code+'-c-'+cat_index)" :id="'playvid'+code+'-c-'+cat_index"
 :data-poster="hosturl+'/media/md'+img.poster"
class="lazy video-js vjs-default-skin"
 muted defaultMuted playsinline contextmenu="return false" style="
width:100%;height:100%;object-fit:cover;
border-radius:8px;text-align:center;
background:#4e4e4e url('/img/loadbg1.gif');
background-position:center;background-repeat:no-repeat;background-size:75px;
backface-visibility: hidden!important;position:relative;z-index:-100;user-select:none;">
<source :src="hosturl+'/media/'+img.src" :type="img.type" />
<!--<source  />-->
Your browser does not support the video tag.
</video>
<div class="home_vide_player_btn" style="position:absolute;z-index:10;
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
height:100%;width:100%;top:0px;right:0px;"
v-on:click="play_vid(code+'-c-'+cat_index)">
<span :id="'playvid-btn-on-'+code+'-c-'+cat_index" class="mdi mdi-48px mdi-play-circle-outline" style="color:#f7f6f6;opacity:0.8;"></span>
<span :id="'playvid-btn-off-'+code+'-c-'+cat_index" class="mdi mdi-48px mdi-pause" style="display:none;color:#f7f6f6;opacity:0.8;"></span>
</div>
<div class="home_vide_player_mute" style="position:absolute;z-index:10;
display:flex;flex-wrap:wrap;align-content:center;justify-content:right;
height:55px;width:55px;padding-right:14px;padding-left:14px;
top:55px;right:0px;"
v-on:click="play_vid_mute(code+'-c-'+cat_index)">
<span :id="'playvid-volume-on-'+code+'-c-'+cat_index" class="mdi mdi-24px mdi-volume-high" style="display:none;color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
<span :id="'playvid-volume-off-'+code+'-c-'+cat_index" class="mdi mdi-24px mdi-volume-off" style="color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
import videojs from 'video.js'; 
export default {
name: 'ItemListVideo',
components: {

},
props: ['img','code','cat_index'],
data(){
return {
iframe:null,
}
},
methods:{
play_vid:function(val){ 
var player = document.getElementById('playvid'+val);
//var vid = videojs(player);
$('video').each(function() {
if($(this).attr("id")!='playvid'+val){
$(this).get(0).pause();
}
});
if (player.paused){
//https://cdn.onekod.com/media/576p.m3u8
//vid.play();
player.play();
//player.play();
//$('#playvid'+val).data('p-state','play');
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
else{
player.pause();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).show();
}
},
play_vid_mute:function(val){ 
var player = document.getElementById('playvid'+val);
if (player.muted){
player.muted = false;
$('#playvid-volume-on-'+val).show();
$('#playvid-volume-off-'+val).hide();
}
else{
player.muted = true;
$('#playvid-volume-on-'+val).hide();
$('#playvid-volume-off-'+val).show();
}
},
lazy_update(){
this.$store.commit("lazy_update");
},
visibilityChanged:function(isVisible,entry,code) {
if(isVisible){
//console.log(entry);
var animation=$("#"+entry.target.id).data('animation');
if(!entry.target.classList.contains(animation)){
//let domElement = entry.target.id;
entry.target.classList.add(animation);
}
this.show_all_info(code,3200);
}
},
show_all_info:function(code,timeout) {
$('.room-'+code).show();
setTimeout(function () {
$('.lounge-'+code).show();
}, 800);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1500);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
}
},
created() {
this.lazy_update();
},
destroyed(){
},
mounted(){
this.lazy_update();
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed:{
...mapState({
hosturl : state => state.hosturl,
currency : state => state.currency,
search_val: state => state.search_val,
likes: state => state.likes,
}),
},
watch:{
}
}
</script>
<style>

</style>
