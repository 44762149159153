<template>
<div class="menu-item-box listbox-home-warp" style="position:relative;touch-action: pan-x pan-y!important;">
<!--  id="v-pan-box" v-pan="(e) => pan_item(e,'v-pan-box')"
v-panend="(e) => pan_item_end(e,'v-pan-box')" -->
<div class="story-box-view listbox-home-box animate__animated animate__fadeIn" 
style="background-color:none;min-width:320px;overflow:hidden;touch-action: pan-x pan-y!important;">
<swiper :options="swiperOption" dir="rtl" ref="mySwiper_Strory"
 @slideChange="show_all_info($refs.mySwiper_Strory.$swiper.activeIndex,3200),show_data='',play_vid_change($refs.mySwiper_Strory.$swiper.activeIndex)"
style="display:flex;flex-wrap:wrap;align-content:center;
margin-right:auto;margin-left:auto;position:relative;z-index:0;
width:100%;height:100%;max-height:100%;overflow:hidden;">
<swiper-slide class="listbox-home-item animate__animated" :id="'animate-item'+index"
v-for="(item, index_item) in orderBy(posts, 'code',-1)"
 style="touch-action: pan-x pan-y!important;height:100%;max-height:100%;position:relative;z-index:0;" 
data-animation="animate__fadeInRightBig"
v-if="item.active==true"
:data-row="index">
<!--v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, item.code)"-->
<!--<router-link to="/item" replace></router-link>-->
<!--hosturl+'/media/sm'+img.src-->
 <!--v-on:click="set_pop_story('');"--> 

<swiper :options="swiperImgOption" dir="rtl" ref="imgSwiper" style="display:flex; flex-wrap:wrap;
touch-action: pan-x pan-y!important;
width:100%;height:100%;align-content:center;justify-content:center;position:relative;z-index:0;">
<swiper-slide class="listbox-home-imgbox" v-for="(img, index_img) in orderBy(item.imgaes, 'type',-1)"
 style="position:relative;height:100%;position:relative;z-index:0;">

<div class="listbox-home-img img-pan" style="user-select:none;touch-action: pan-x pan-y!important;
position:relative;z-index:0;overflow:hidden;">
<div v-if="img.type&&img.type.includes('video')" class="home_vide_player" style="backface-visibility: hidden;display:flex;flex-wrap:wrap;align-content:center;
justify-content:center;position:relative;height:100%;width:100%;">
<video :data-src="hosturl+'/media/'+img.src" :type="img.type"
 v-on:click.self="play_vid(index_item)" :id="'playvid'+index_item"
 poster="#" class="videof lazy" muted defaultMuted playsinline style="
height:100%;width:100%;object-fit: cover;border-radius:8px;text-align:center;
backface-visibility: hidden!important;position:relative;z-index:-100;">
<!--<source  />-->
Your browser does not support the video tag.
</video>
<div class="home_vide_player_btn" style="position:absolute;z-index:10;
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
height:100%;width:100%;top:0px;right:0px;"
v-on:click="play_vid(index_item)">
<span :id="'playvid-btn-on-'+index_item" class="mdi mdi-48px mdi-play-circle-outline" style="color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
<span :id="'playvid-btn-off-'+index_item" class="mdi mdi-48px mdi-pause" style="display:none;color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
</div>
<div class="home_vide_player_mute" style="position:absolute;z-index:10;
display:flex;flex-wrap:wrap;align-content:center;justify-content:right;
height:55px;width:55px;padding-right:14px;padding-left:14px;
top:55px;right:0px;"
v-on:click="play_vid_mute(index_item)">
<span :id="'playvid-volume-on-'+index_item" class="mdi mdi-24px mdi-volume-high" style="display:none;color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
<span :id="'playvid-volume-off-'+index_item" class="mdi mdi-24px mdi-volume-off" style="color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
</div>
</div>
<img v-on:click.self=""
 alt="يتم التحميل..." class="lazy" style="background-image:url('/img/loadbg1.gif');
background-position:center;background-repeat:no-repeat;background-size:75px;
position:relative;z-index:0;width:100%;height:100%;object-fit:cover;
touch-action: pan-x pan-y!important;"
draggable="false" :data-src="hosturl+'/media/'+img.src"
>
</div>
</swiper-slide>
<div class="swiper-pagination" slot="pagination"
style="bottom:0px!important;margin-right:auto!important;background: rgba(0,0,0,.4);
height:32px;display:flex;flex-wrap:wrap;align-content:center;justify-content:center;">
</div>
</swiper>
<div class="listbox-home-imgkm" 
style="position:absolute;top:0px;right:0px;display: flex;flex-wrap:wrap;
height:60px;max-height:60px;width:100%;justify-content: center; align-content:center; z-index:1;">
<div class="bk_Like_fix_btn" v-on:click="$router.push('/')" style="display: flex;flex-wrap:wrap;
border-radius: 4px;height:60px;max-height:60px;width:auto;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;padding-right:7px;padding-top:7px;
justify-content: center;align-content:center;padding-left:7px;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.2);
border-radius: 4px;height:42px;max-height:42px;width:auto;color:rgba(255,255,255,.9);
white-space:nowrap;padding-left:7px;padding-right:7px;
justify-content: center;align-content:center;">
<span class="mdi mdi-18px mdi-chevron-right" style="display: flex;flex-wrap:wrap;color:#f7f6f6;
opacity:0.8;line-height:42px;align-content:center;">
</span>
<span style="font-size:14px;line-height:40px;">
رجوع <span style="display:none;">{{view_index}}</span>
</span>
</div>
</div>
<div class="set_Like_fix_btn" v-on:click="showInfo('.fix-count-info.like'+'-'+item.code),set_Like(item.guid)" 
style="display: flex;flex-wrap:wrap;margin-right:auto;
border-radius: 4px;height:60px;max-height:60px;width:auto;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;padding-top:7px;
justify-content: center;align-content:center;padding-left:7px;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.2);
border-radius: 4px;height:42px;max-height:42px;width:42px;position:relative;
white-space:nowrap;justify-content: center;">
<span class="mdi mdi-24px mdi-heart-outline" style="color:#f7f6f6;line-height:42px;opacity:0.8;"></span>
<div :class="'animate__animated animate__headShake fix-count-info like'+'-'+item.code" 
style="display:none;position:absolute;z-index:1;top:0px;left:35px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
border:1px solid #ddd;background:#fdfdfd;color:#333;font-size:14px;width:72px;height:42px;line-height:42px;">
<span style="padding-left:3px;">تم</span>
<span class="mdi mdi-18px mdi-check" style="color:#2aca7e;line-height:42px;"></span>
</span>
</div>
</div>
</div>
</div>
<div class="listbox-home-imginfo"
style="position:absolute;top:33.33%;right:0px;display: flex;flex-wrap:wrap;height:92px;
font-family: 'Noto Kufi Arabic', sans-serif;
width:92px;justify-content: right; align-content:flex-start; z-index:10;padding:7px;">
<div style="display: flex;flex-wrap:wrap;width:36px;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);position:relative;
border-radius: 4px;height:36px;max-height:36px;width:36px;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;margin-top:14px;
justify-content: center;align-content:center;" v-on:click="showInfo('.fix-count-info.room'+'-'+index_item)">
<span class="mdi mdi-24px mdi-bed-king-outline" style="color:#f7f6f6;padding:5px;opacity:0.8;
display: flex;flex-wrap:wrap;justify-content: center;align-content:center;"></span>
<div :class="'animate__animated animate__headShake fix-count-info room'+'-'+index_item"
 style="display:none;position:absolute;z-index:1;top:0px;right:35px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
background:#f44a6f;color:#fdfdfd;font-size:13px;width:100px;height:36px;">
عدد الغرف {{item.rooms}}
</span>
</div>
</div>
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);margin-top:14px;
border-radius: 4px;height:36px;max-height:36px;width:36px;color:#ddd;
font-size:12px;font-weight:400;white-space:nowrap;position:relative;
justify-content: center;align-content:center;" v-on:click="showInfo('.fix-count-info.lounge'+'-'+index_item)">
<span class="mdi mdi-24px mdi-sofa-outline" style="color:#f7f6f6;line-height:36px;opacity:0.8;"></span>
<div :class="'animate__animated animate__headShake fix-count-info lounge'+'-'+index_item"
 style="display:none;position:absolute;z-index:1;top:0px;right:36px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
background:#21a9df;color:#fdfdfd;font-size:13px;width:100px;height:36px;">
عدد الصالات {{item.lounges}}
</span>
</div>
</div>
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);margin-top:14px;
border-radius: 4px;height:36px;max-height:36px;width:36px;color:#ddd;
font-size:12px;font-weight:400;white-space:nowrap;position:relative;
justify-content: center;align-content:center;" v-on:click="showInfo('.fix-count-info.bathroom'+'-'+index_item)">
<span class="mdi mdi-24px mdi-shower" style="color:#f7f6f6;line-height:36px;opacity:0.8;"></span>
<div :class="'animate__animated animate__headShake fix-count-info bathroom'+'-'+index_item" 
style="display:none;position:absolute;z-index:1;top:0px;right:36px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
border:1px solid #ddd;background:#fdfdfd;color:#333;font-size:13px;width:128px;height:36px;">
عدد دورة المياة {{item.bathrooms}}
</span>
</div>
</div>
</div>
</div>
<div class="listbox-home-imgcat" v-on:click="guid_view=item.guid,show_data='dt'" 
style="background: rgba(0,0,0,.4);position:absolute;bottom:32px;right:0px; display: flex;flex-wrap:wrap;
width:100%;justify-content: center;align-content:center;z-index:1;">
<div class="listbox-home-data" style="display: flex;flex-wrap:wrap;height:75px;align-content:center;">
<div class="listbox-home-data-name" style="width:94%;justify-content:right;line-height:32px;
position:relative;z-index:0;
font-size:16px;font-weight:400;align-items:center;color:#f7f6f6;white-space:nowrap;">
<span>
{{item.title}}
</span>
<span class="mdi mdi-24px mdi-chevron-left" style="color:#eee;line-height:32px;"></span>
<span class="listbox-home-data-mater" style="margin-right:auto;font-size:16px;">
{{formatPrice(item.price)}} <span v-if="item.price.includes('0')">{{currency}}</span>
</span>
</div>
<div class="listbox-home-data-address" style="width:94%;justify-content:right;opacity:0.8;line-height:32px;
align-items:center;color:#f7f6f6;white-space:nowrap;">
<span class="mdi mdi-18px mdi-map-marker-multiple" style="color:#f7f6f6;line-height:18px;"></span>
<span style="padding-right:3px;font-size:15px;font-weight:400;">
{{item.address}} 
</span>
<span class="listbox-home-data-mater" style="margin-right:auto;font-size:15px;font-weight:400;">
450 م <span class="mdi mdi-18px mdi-walk" style="color:#f7f6f6;line-height:18px;"></span>
</span>
</div>
</div>
</div>

</swiper-slide>
</swiper>
<div v-show="show_data=='dt'" v-on:click="show_data=''" 
class="Bottom-Nav-Story" style="display:flex;flex-wrap:wrap;width:100%;height:100%;
position:absolute;z-index:100;top:0px;padding:14px;align-content:center;margin:auto;
background: rgba(0, 0, 0, 0.1);
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);
overflow-x:hidden;overflow-y:scroll;">
<ItemViewStory v-show="show_data=='dt'" 
:guid="guid_view"></ItemViewStory>
</div>
</div>

</div>
</template>
<script>
import {mapState} from 'vuex';
import ItemViewStory from './ItemViewStory.vue'
import Vue2Filters from 'vue2-filters'
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
//import 'swiper/css/swiper.min.css';
export default {
name: 'ItemStorySwipePage',
components: {Swiper,SwiperSlide,ItemViewStory},
mixins: [Vue2Filters.mixin],
//props: {msg: String},
data(){
return {
guid_view:'',
show_data:'',
swiperOption:{
slidesPerView: 'auto',
direction: 'vertical',
grabCursor: true,
//watchSlidesProgress: true,
//mousewheelControl: true,
//keyboardControl: true,
//grabCursor: true,
autoHeight: false,
spaceBetween: 0,
allowTouchMove: true,
pagination: {
el: '.swiper-pagination_story',
clickable: true
},
//autoplay:{
//delay:10000,
//disableOnInteraction: false
//},
navigation: {
nextEl: ".swiper-button-next",
prevEl: ".swiper-button-prev"
}
},
swiperImgOption:{
slidesPerView: 'auto',
//zoom: true,
autoHeight: false,
spaceBetween: 0,
//direction: 'vertical',
pagination: {
el: '.swiper-pagination',
clickable: true
},
navigation: {
nextEl: ".swiper-button-next",
prevEl: ".swiper-button-prev"
}
},
view_index:0,
aos:null,
item_like:[],
iframe:null,
width: 0,
scrollWidth: 0,
left: 0,
deltaX: 0,
deltaY: 0,
pan_up_max: 0,
pan_down_max: 0,
index: 0,
vh:0,
swaip:0,load:'show',
ref_item_box:''
}
},
mounted(){
var vh=window.innerHeight;
$('.story-box-view').css("height",vh);
$('.story-box-view').css("max-height",vh);
$('.Bottom-Nav-Story').css("min-height",vh);
$('.Bottom-Nav-Story').css("height",vh);
$('.Bottom-Nav-Story').css("max-height",vh);
this.lazy_update();
//this.set_pan_height();
//this.set_story_view();
//this.$refs.mySwiper_Strory.$swiper.slideTo(2, 1000, false)
},
methods:{
play_vid_change:function(val){ 
$('video').each(function() {
$(this).get(0).pause();
});
var player = document.getElementById('playvid'+val);
if(player!=null){
if (player.paused){
player.play();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
}
},
play_vid:function(val){ 
var player = document.getElementById('playvid'+val);
$('video').each(function() {
if($(this).attr("id")!='playvid'+val){
$(this).get(0).pause();
}
});
if (player.paused){
player.play();
//$('#playvid'+val).data('p-state','play');
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
else{
player.pause();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).show();
}
},
play_vid_mute:function(val){ 
var player = document.getElementById('playvid'+val);
if (player.muted){
player.muted = false;
$('#playvid-volume-on-'+val).show();
$('#playvid-volume-off-'+val).hide();
}
else{
player.muted = true;
$('#playvid-volume-on-'+val).hide();
$('#playvid-volume-off-'+val).show();
}
},
orderImg: function (val) {
var img=[];
img=val.sort((a, b) => a.home - b.home);
return img;
},
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
pan_item: function (e, val){
var el = document.getElementById(val);
//e.deltaX // e.target
el.style.transform = "translateY("+e.deltaY+"px)";
//$('#'+val).css("transform","translateY(" + e.deltaY + "px)")
if(e.deltaY>200||e.deltaY<-200){
$('.pop-up-box.item-story').css("opacity","0.8");
}
else{
$('.pop-up-box.item-story').css("opacity","1");
}
this.deltaY=e.deltaY;
},
pan_item_end: function (e, val){
//e.deltaX // e.target
var deltaNowY = 0;
deltaNowY = e.deltaY;
var el = document.getElementById(val);
//$(el).hide();
if (deltaNowY > this.pan_down_max || deltaNowY < this.pan_up_max){
this.set_pop_story('');
el.style.transform = "translateY(0px)";
$('.pop-up-box.item-story').css("opacity","1");
}
else{
el.style.transform = "translateY(0px)";
$('.pop-up-box.item-story').css("opacity","1");
this.deltaY=0;
//$('#'+val).css("transform","translateY(0px)")
//$('.pop-up-box.item-story').css("transform","translateY(0px)")
}
},
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
set_pan_height:function () {
if(window.innerHeight/2<=200){
this.pan_up_max=-200;
this.pan_down_max=200;
}
else if(window.innerHeight/2<=300){
this.pan_up_max=-300;
this.pan_down_max=300;
}
else if(window.innerHeight/2<=400){
this.pan_up_max=-400;
this.pan_down_max=400;
}
else if(window.innerHeight/2>400){
this.pan_up_max=-400;
this.pan_down_max=400;
}
else{
this.pan_up_max=-150;
this.pan_down_max=150;
}
},
set_story_view:function () {
if(this.story_guid!=''){
var index = this.posts.findIndex(x => x.guid == this.story_guid);
if(index>-1){
this.$refs.mySwiper_Strory.$swiper.slideTo(index, 1000, false);
}
this.view_index=index;
}
},
dis_like : function (guid) {
var i = 0;
this.likes.filter(item =>{
if(item.like==guid){
this.likes.splice(i,1);
}
i++;
});
this.lazy_update();
$('img.lazy').removeAttr('data-ll-status');
this.$cookies.set('likes',this.likes);
},
set_Like:function (guid){
var ck =this.likes.filter(item =>{
return item.like==guid;   
});
if(ck.length<1)
this.$store.commit("push_likes",guid);
this.$cookies.set('likes',this.likes);
},
formatPrice : function (value) {
var val = parseInt(value);
if (typeof val !== "number") {
return value;
}
if(val>1000&&val<900999){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 0,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else if(val>1000000){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 6,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else{
return value;
}
},
lazy_update(){
this.$store.commit("lazy_update");
},
SetViewCat: function (val) {
this.$store.commit("SetViewCat", val);
},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetPreview : function (val) {
this.$store.commit("SetPreview", val);
},
setitem_index(val){
this.$store.commit("setitem_index", val);
},
setpage:function (val) {
this.$store.commit("setpage", val);
//$('.nav-icon').addClass('mdi-tag-multiple');
//$('.item-home-nbtn').removeClass('active');
//$('.item-home-nbtn:nth-child(' + (val+1) + ')').addClass('active');
//$('.item-home-nbtn:nth-child(' + (val+1) + ')').find('span').removeClass('mdi-tag-multiple').addClass('mdi-checkbox-marked');
},
visibilityChanged:function(isVisible,entry,code) {
if(isVisible){
//console.log(entry);
var animation=$("#"+entry.target.id).data('animation');
if(!entry.target.classList.contains(animation)){
//let domElement = entry.target.id;
entry.target.classList.add(animation);
}
this.show_all_info(code,3200);
}
},
show_all_info:function(code,timeout) {
setTimeout(function () {
$('.room-'+code).show();
}, 800);
setTimeout(function () {
$('.lounge-'+code).show();
}, 1200);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1600);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
$('.story-box-view img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
}
},
created() {
this.lazy_update();
window.addEventListener('scroll', this.scrollFunction);
},
destroyed(){
window.removeEventListener('scroll', this.scrollFunction);
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
items : state => state.items,
posts : state => state.posts,
viewCat : state => state.viewCat,
hosturl : state => state.hosturl,
currency : state => state.currency,
hide_navbar : state => state.hide_navbar,
preview_index : state => state.preview_index,
pageview:state => state.pageview,
story_guid:state => state.story_guid,
pop_story:state => state.pop_story,
preview: state => state.preview,
likes: state => state.likes,
height_navbar : state => state.height_navbar

}),
filteredList(){
var like_posts=[];
this.likes.map(litem =>{
this.posts.map(pitem =>{
if(pitem.guid==litem.like){
like_posts.push(pitem);
}
})
});
return like_posts;   
}
},
watch:{
// whenever question changes, this function will run
story_guid: function ()
{
this.set_story_view();
},
pageview: function ()
{
//this.$refs.horizontal_item.scrollToIndex(this.pageview);
}
}
}
</script>
<style>
.swiper-pagination-bullet{background:#f5f5f5!important;width:10px!important;height:10px!important;}
.swiper-pagination-bullet-active{background: rgba(255,255,255,.4)!important;}
</style>
<style scoped>
.home-item-list-box{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;
overflow-y: hidden;scrollbar-width:none;
}
.swip-item-home{
display: flex;width:100%;margin-bottom: 0px;
max-width:100%;padding:7px;
margin: 0;padding-bottom: 0px;border: none;
box-sizing: content-box;overflow-x: scroll;overflow-y: hidden;
scroll-snap-type: x mandatory;
scrollbar-width:none;
-webkit-overflow-scrolling: touch;
position:relative;
}
.listbox-home-warp{
display:flex; flex-wrap:wrap;height:auto;
width: 100%; position: relative;
touch-action: pan-x pan-y;
position:relative;font-family: 'Noto Kufi Arabic';font-weight:400;
margin:0;justify-content: center;

}
.listbox-home-box{
display:flex; flex-wrap:wrap;width: 100%; 
/*padding-bottom:100px;*/
touch-action: pan-x pan-y; height: 100%;
justify-content: center;
align-items: flex-start;
overflow-y: scroll; overflow-x: hidden; 
scrollbar-color: auto; scrollbar-width: none;
scroll-snap-type: mandatory;
}
.listbox-home-item{
position:relative;
display:flex;flex-wrap:wrap;
justify-content:center;text-align:center;align-items:center;
width:100%;
z-index: 0;cursor: pointer;
touch-action: none; touch-action: pan-x pan-y;
/*box-shadow: 0px 4px 4px 0px #f5f5f5;*/
overflow: hidden;
/*border-radius:5px 5px 5px 5px;*/
/*border-bottom:1px solid #ededed;*/

}
.listbox-home-imgbox{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
max-width:100%;width:100%;height:100%;
overflow: hidden;scrollbar-width: none;
}
.listbox-home-img{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
height:100%;width:100%;max-width:100%;overflow:scroll;

/*border-radius:5px 5px 5px 5px;*/
}
.listbox-home-data{
display: flex;
align-items: center;justify-content: center;
scrollbar-width: none;width:100%;max-width:94%;
overflow: hidden;z-index: 0;
}
.listbox-home-data-box{
position:relative;width: 100%;max-height:128px;
display: flex;flex-wrap: wrap;
justify-content: center; justify-items:center;
text-align:center;z-index: 0;
}
</style>
<style scoped>
.listbox-home-data-name{
display: flex;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;align-content:center;
}
.listbox-home-data-name span{
display:flex;justify-content:right;
max-width:75%;
overflow-x:scroll;overflow-y:hidden;
scrollbar-width:none!important;
}
.listbox-home-data-address{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;color:#000;
align-content:center;
}
.listbox-home-data-address span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:13px;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}

.listbox-home-data-name img{
padding-right:5px;padding-left:5px;
}

.listbox-home-data-info{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color:#999;justify-content: center;align-content:center;
}
.listbox-home-data-info div{
display:flex;flex-wrap:wrap;justify-content:center;
font-size:12px;white-space:nowrap;align-content:center;
color:#999;scrollbar-width:none;
}
.listbox-home-data-info span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#999;align-content:center;
}
.listbox-home-data-info a{
display:flex;flex-wrap:wrap;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#555;justify-content:right;align-content:center;
}
.listbox-home-data-price{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color: #000; justify-content: center;align-content:center;
}
.listbox-home-data-price span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;
color:#555;align-content:center;
margin:auto;
}
</style>
