<template>
<div class="j7-view-item-title" style="">
<span style="display: flex;flex-wrap: wrap;width:auto;height:auto; 
justify-content:center;align-content:center;font-size:16px;color:#4e4e4e;">
{{title}}
</span>
</div>
</template>
<script>
import { mapState} from "vuex";

export default {
name: 'ItemTitle',
components: {},
props: ['title'],
data(){
return {
}
},
methods: {
},
mounted() {
this.$store.commit("set_page_title",this.title+' | '+this.cust_name);
},
watch:{

},
computed: {
...mapState({
page_title : state => state.page_title,
cust_name : state => state.cust_name
})
}
}
</script>
<style scoped>
.j7-view-item-title{
display: flex;flex-wrap:wrap;width:100%;
justify-content:center;align-content:center;
font-weight:400;font-family: 'Noto Kufi Arabic', sans-serif;
color:#4e4e4e;white-space:normal;cursor:pointer;
height:56px;margin:auto;padding-left:14px;padding-right:14px;
align-content:center;line-height:56px;
}
@media (min-width: 800px) and (max-width: 1200px){
.j7-view-item-title{
height:56px;align-content:center;
}
}

@media (min-width: 1200px){
.j7-view-item-title{
height:56px;align-content:center;
}
.j7-view-item-title span{
height:56px;align-content:center;
}
}

</style>
