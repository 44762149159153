<template>
<div style="display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;
position:relative;width:auto;height:auto;">
<span v-if="Count_List.length>0">({{Count_List.length}})</span>
</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
name:'CountItemByCat',
components: {},
props: ['item'],
data(){
return{
val:this.item
}
},
methods: {

},
watch:{

},
mounted(){

},
//
computed: {
Count_List(){
return this.posts.filter(item =>
{
//return this.search_val.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
return item.tags.toLowerCase().includes(this.val.toLowerCase())
|| item.title.toLowerCase().includes(this.val.toLowerCase());   
});
},
...mapState({
filter_name: state => state.filter_name,
filter_ck: state => state.filter_ck,
filter_val: state => state.filter_val,
filter_count: state => state.filter_count,
//db : state => state.db,
posts : state => state.posts,
cats : state => state.cats
})
}
}
</script>

