import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
import Vue2Filters from 'vue2-filters'
import UUID from "vue-uuid";
import VueObserveVisibility from 'vue-observe-visibility'
import VueCookies from 'vue-cookies'
//import CoolLightBox from 'vue-cool-lightbox'
//import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
//import Vue2TouchEvents from 'vue2-touch-events'
//import VueAwesomeSwiper from 'vue-awesome-swiper'
//import VueCookies from 'vue-cookies'
//import 'element-ui/lib/theme-chalk/index.css';
//import 'swiper/css/swiper.min.css';
import App from './App.vue'
import router from './router'
import store from './store'
//const app = createApp(App)
//import directive from './directive'
//import '@/hammer.min.v2.0.7.js';

Vue.config.productionTip = false;
Vue.use(VueCookies, { 
path: '/'
})
//Vue.use(VueAwesomeSwiper);
Vue.use(VueSweetalert2);
Vue.use(UUID);
Vue.use(VueObserveVisibility);
Vue.directive('observe-visibility', VueObserveVisibility.ObserveVisibility);
//Vue.use(Vue2TouchEvents);
Vue.use(Vue2Filters)
//Vue.use(swal);

Vue.directive('rippleffect', { 
inserted: function (el, binding) {
var color = binding.value === undefined ? '#fff' : binding.value;
el.style.position = 'relative';
el.style.overflow = 'hidden';
el.onmousedown = function (ev)
{
var ripple = document.createElement("div");
ripple.className = 'ripple-effect';
var circle = document.createElement('div');
circle.className = 'anka-ripple';
var offset = this.getBoundingClientRect()
var x = ev.pageX - offset.left - document.body.scrollLeft
var y = ev.pageY - offset.top - document.body.scrollTop
circle.setAttribute('style', 'background: ' + color + '; top: ' + y + 'px; left: ' + x + 'px;')
circle.append(ripple)
this.append(circle)
ev.stopPropagation()
setTimeout(function () {
circle.parentNode.removeChild(circle)
}, 400)
}
el.ontouchstart = function (ev)
{
var ripple = document.createElement("div");
ripple.className = 'ripple-effect';
var circle = document.createElement('div');
circle.className = 'anka-ripple';
var offset = this.getBoundingClientRect()
var x = ev.pageX - offset.left - document.body.scrollLeft
var y = ev.pageY - offset.top - document.body.scrollTop
circle.setAttribute('style', 'background: ' + color + '; top: ' + y + 'px; left: ' + x + 'px;')
circle.append(ripple)
this.append(circle)
ev.stopPropagation()
setTimeout(function () {
circle.parentNode.removeChild(circle)
}, 400)
}
}
})
Vue.directive('rippled', { 
inserted: function (el) {
el.style.position = 'relative'
el.style.overflow = 'hidden'
el.onclick = function (ev) {
var circle = document.createElement('div')
circle.className = 'anka-ripple';
this.append(circle)
ev.stopPropagation()
setTimeout(function () {
circle.parentNode.removeChild(circle)
}, 400)
}
}
})
Vue.directive('opacity', { 
inserted: function (el) {
el.onmousedown = function (ev) {
//el.style.opacity = binding.value;
el.style.opacity = '0.8';
ev.stopPropagation();
} 
el.onmouseup = function (ev){
el.style.opacity = '1';
ev.stopPropagation();
}
el.ontouchstart = function (ev)
{
//el.style.opacity = binding.value;
 el.style.opacity = '0.8';
ev.stopPropagation();
}
el.ontouchend = function (ev)
{
el.style.opacity = '1';
ev.stopPropagation();
}
}
})
Vue.directive("panend", {
bind: function (el, binding) {
if (typeof binding.value === "function") {
const mc = new Hammer(el);
mc.on("panend", binding.value);
}
}
});
Vue.directive("tap", {
bind: function (el, binding) {
if (typeof binding.value === "function") {
const mc = new Hammer(el);
mc.on("tap", binding.value);
}
}
});
Vue.directive("press", {
bind: function (el, binding) {
if (typeof binding.value === "function") {
const mc = new Hammer(el);
//mc.get("press").set({event: 'press',pointer: 1, threshold: 5,time: 500});
 mc.on("press", binding.value);
}
}
});
Vue.directive("pan-ripple", {
 bind: function (el) {
  var mc = new Hammer(el);
  mc.add(new Hammer.Press({
   event: 'press',
   pointer: 1,
   threshold: 1000,
   time: 1,
  }));
   //mc.get("press").set({event: 'press',pointer: 1, threshold: 5,time: 500});
  mc.on("press", function () {
    el.style.position = 'relative'
    el.style.overflow = 'hidden'
   var ripple = document.createElement('div')
   ripple.className = 'anka-ripple';
   el.append(ripple)
    setTimeout(function () {
     ripple.parentNode.removeChild(ripple)
     }, 400)
  });
 }
});
Vue.directive("pan", {
 bind: function (el, binding) {
  if (typeof binding.value === "function") {
   var mc = new Hammer(el, {
    direction: Hammer.DIRECTION_HORIZONTAL
   });
   //mc.get("press").set({event: 'press',pointer: 1, threshold: 5,time: 500});
   mc.on("pan", binding.value);
  }
 }
});

////Vue.directive("pan", {
////bind: function (el, binding) {
////if (typeof binding.value === "function") {
////const mc = new Hammer(el);
////mc.get("pan").set({ direction: Hammer.DIRECTION_ALL });
////mc.on("pan", binding.value);
////}
////}
////});

Vue.directive("pandown", {
bind: function (el, binding) {
if (typeof binding.value === "function") {
const mc = new Hammer(el);
//Hammer.DIRECTION_VERTICAL + Hammer.DIRECTION_HORIZONTAL + Hammer.DIRECTION_ALL
//mc.get('pan').set({ direction: mc.DIRECTION_HORIZONTAL });
//mc.get('swipe').set({
//  direction: Hammer.DIRECTION_ALL
//  //threshold: 1, 
//  //velocity:0.1
//});
mc.get("pan").set({ direction: Hammer.DIRECTION_VERTICAL });
mc.on("pandown", binding.value);
}
}
});

Vue.directive("panend-ver", {
bind: function (el, binding) {
if (typeof binding.value === "function") {
const mc = new Hammer(el);
mc.get("pan").set({ direction: Hammer.DIRECTION_VERTICAL });
mc.on("panend", binding.value);
}
}
});
Vue.directive("pandown-ver", {
bind: function (el, binding) {
if (typeof binding.value === "function") {
const mc = new Hammer(el);
mc.get("pan").set({ direction: Hammer.DIRECTION_VERTICAL });
mc.on("pandown", binding.value);
}
}
});
var preventNav= function(e){
/*if (!this.shouldPrevent) return*/
e.preventDefault();
e.returnValue = "";
};
new Vue({
router,
store,
render: h => h(App),
created: () => {
//store.commit("Swal_Success",'')
},
mounted(){

}
}).$mount('#app')
