<template>
<div class="j7-view-item-address" style="
display: flex;flex-wrap:wrap;width:100%;min-height:38px;
justify-content:center;align-content:center;line-height:38px;
font-weight:500;font-family: 'Noto Kufi Arabic', sans-serif;
cursor:pointer;color:#4e4e4e;white-space:nowrap;
margin:auto;">
<div style="border:1px solid #eee;background:#fff;line-height:55px;
border-radius:4px;align-content:center;
display: flex; flex-wrap: wrap;width:100%;justify-content:center;position:relative;">
<a :href="'https://api.whatsapp.com/send/?phone='+'9660566081758'+'&text='+text+'&type=phone_number&app_absent=0'" style="display: flex; flex-wrap: wrap;font-size:16px;padding-right:18px;width:100%;
height:72px;line-height:72px;
position:relative;justify-content:center;background:#f9f9f9;align-content:center;"  target="_blank">
<span style="display: flex; flex-wrap: wrap;height:55px;line-height:55px;
justify-content:center;align-content:center;padding-right:5px;">
تواصل معنا الان
</span>
<span style="display: flex; flex-wrap: wrap;height:55px;line-height:55px;
justify-content:center;align-content:center;padding-right:5px;">
<img src="/img/whatsapp.svg" style="display:flex;flex-wrap: wrap;
width:24px;height:24px;max-height:24px;object-fit:contain;" />
</span>
</a>
<div style="display: flex; flex-wrap: wrap;font-size:16px;height:72px;line-height:72px;
position:relative;width:100%;justify-content:center;align-content:center;padding-right:18px;">
<span style="padding-right:5px;display: flex; flex-wrap: wrap;height:38px;line-height:38px;justify-content:center;align-content:center;">
{{price}} {{currency}} -
</span>
<span style="display: flex; flex-wrap: wrap;height:45px;line-height:45px;
justify-content:center;align-content:center;padding-right:5px;">
شامل الضريبة
</span>
<span style="display: flex; flex-wrap: wrap;height:45px;line-height:45px;
justify-content:center;align-content:center;padding-right:5px;">
<img src="/img/done.svg" style="display:flex;flex-wrap: wrap;
width:12px;height:12px;max-height:12px;object-fit:contain;" />
</span>
</div>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';

export default {
name: 'ItemPrice',
components: {},
props: ['price','text'],
data(){
return {
}
},
methods: {
},
watch:{

},
computed: {
...mapState({
hosturl : state => state.hosturl,
currency : state => state.currency
}),
},
}
</script>

