import { render, staticRenderFns } from "./SideBarPc.vue?vue&type=template&id=4f347859&scoped=true&"
import script from "./SideBarPc.vue?vue&type=script&lang=js&"
export * from "./SideBarPc.vue?vue&type=script&lang=js&"
import style0 from "./SideBarPc.vue?vue&type=style&index=0&id=4f347859&prod&lang=css&"
import style1 from "./SideBarPc.vue?vue&type=style&index=1&id=4f347859&prod&scoped=true&lang=css&"
import style2 from "./SideBarPc.vue?vue&type=style&index=2&id=4f347859&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f347859",
  null
  
)

export default component.exports