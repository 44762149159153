<template>
<div class="j7app-appbar" style="position:relative;z-index:0;">
<div v-on:click="$router.push('/')" class="j7app-spacer b-btn-back" style="line-height:42px!important;cursor:pointer;margin-right:14px;height:48px;max-height:48px;overflow:hidden;">
<span class="mdi mdi-24px mdi-chevron-right" style="color:#4e4e4e;line-height:42px!important;"></span>
<span style="font-size:16px;font-family: 'Noto Kufi Arabic', sans-serif;color:#4e4e4e;line-height:42px!important;">رجوع</span>
</div>
<div v-on:click="$router.push('/')" class="j7app-spacer b-logo" style=" margin-right:auto;margin-left:auto;cursor:pointer;
height:48px;max-height:48px;min-width:150px;max-width:150px;overflow:hidden;justify-content:center;align-items:center;">
<img src="/img/sm-logo-x.png" style="line-height:48px;display:flex;justify-content:center;height:18px;object-fit:contain;" />
<span style="padding-right:4px;padding-left:4px;font-family: 'Noto Kufi Arabic', sans-serif;font-size:16px;
color:#4e4e4e;line-height:48px;">
عقارات الرياض
</span>
</div>
<div class="j7app-spacer b-help" style="margin-left:14px;position:relative;z-index:0;">
<div class="j7app-vbtn-icon" style="position:relative;">
<span class="mdi mdi-24px mdi-dots-vertical j7app-span-icon" style="color:#888;"></span>
</div>
</div>
<div class="fix_btn_menu" style="min-height:50vh;">
<div class="fix_btn_menu_item" style="padding:14px;border-top:1px solid #eee;border-bottom:1px solid #eee;">
<span class="mdi mdi-24px mdi-delete j7app-span-icon" style="display:flex;color:#888;padding-left:7px;width:55px;"></span>
<span style="display:flex;font-family: 'Noto Kufi Arabic', sans-serif;font-size:16px;">الغاء جميع تسجيلات الاعجاب</span>
</div>
</div>
</div>
</template>
<script>
export default {
name: 'TopLikebar',
components: {},
//props: {msg: String},
data(){
return {
}
},
methods: {
},
watch:{

}
}
</script>

<style scoped>
.fix_btn_menu{position:absolute;top:55px;left:0px; display:flex;
flex-wrap:wrap;align-content:center;justify-content:center;
z-index:1;width:100%;min-height:72px;background-color:rgb(0,0,0,0.8);
}
.fix_btn_menu_item{position:relative;display:flex;
align-items:center;justify-content:center;background-color:#fff;
z-index:1;width:100%;min-height:72px;
}
.j7app-spacer{
position: relative;z-index: 0;
display: flex;
}
.j7app-appbar{
position: relative; z-index: 0;
display: flex;width:100%;height:55px;
align-items: center;max-width:1366px;
margin-right:auto;margin-left:auto;
background:#fff;
}
.j7app-vbtn{
position: relative;background-color: transparent;
display: flex;
flex-basis: auto;border-radius: 0;
font-size: 14px;height: inherit;
max-width: 168px;min-width: 80px;
color: rgba(0,0,0,.54);cursor: pointer;
text-transform: none;box-shadow: none;
white-space: nowrap;user-select: none;
align-items: center;justify-content: center;
text-decoration: none;outline: 0;
-webkit-appearance: button;border-radius: 50%;
transition-duration: .28s;
transition-property: box-shadow,transform,opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.j7app-vbtn-icon{
position: relative;
display: inline-flex;flex: 0 1 auto;flex-grow: 0;flex-shrink: 1;
flex-basis: auto;border-radius: 0;
font-size: 14px;height: inherit;
height: 38px;width:38px;
max-height: 38px;max-width:38px;
cursor: pointer;
text-transform: none;box-shadow: none;
white-space: nowrap;user-select: none;
align-items: center;justify-content: center;
text-decoration: none;outline: 0;
-webkit-appearance: button;border-radius: 50%;
transition-duration: .28s;
transition-property: box-shadow,transform,opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.j7app-span-icon{
display:flex;width:100%;
height:24px;line-height:24px;
justify-content:center;align-content:center;
}
.j7app-span-icon.active{color:#e03d53;}
.j7app-vbtn-icon:hover{background-color:#f5f5f5;border:1px solid #eee;}
</style>
<style scoped>
.absolute-serach-warp{
position:absolute;top:0;right:0;
width:calc(100%);height:55px;z-index:100;
}
.absolute-serach-box{
position:relative; background:#fff;
display:flex;flex-wrap:wrap;margin-right:auto;
height:55px;width:100%;padding:0 14px 0 14px;
justify-content:center;align-content:center;
}
.absolute-serach-box-input{
display:flex;height:36px;width:calc(100% - 100px);
margin-right:14px;margin-left:14px;padding:0 14px 0 14px;
border-radius:10px;border:1px solid #ddd;
}
@media (min-width: 600px){
.absolute-serach-warp{width:calc(100% - 270px);right:125px;}
}
</style>
