<template>
<div class="home-tags-box">
<div class="home-tags-box-sw">
<swiper :options="swiperv2" dir="rtl"
style="position:relative;display:flex;align-content:center;margin-right:auto;margin-left:auto;
width:calc(100% - 100px);overflow:hidden;justify-content:center;">
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'شيول')">#شيول</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'كتر')">#كتربلر</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'بوكلين')">#بوكلين</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'قريدر')">#قريدر</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'بلدوزر')">#بلدوزر</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'DR9')">#DR9</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'950')">#950M</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'980')">#980C</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'XGMA')">#XGMA</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'JCB')">#JCB</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'بوبكات')">#بوبكات</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'مرسيدس')">#مرسيدس</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'مان')">#راس مان</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'2021')">#2021</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'202')">#2020</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'201')">#2010</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'2014')">#2014</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'2017')">#2017</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a style="min-width:120px;" v-on:click="$router.push('/tags/'+'بطاق')">#بطاقة جمركية</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a style="min-width:120px;" v-on:click="$router.push('/tags/'+'اكتروس')">#راس اكتروس</a>
</swiper-slide>

</swiper>
<div class="swbt2-prv swiper-button-prev" slot="button-prev" 
 style="color:#ddd;position:absolute;top:25px;left:0px;"></div>
<div class="swbt2-nxt swiper-button-next" slot="button-next" 
 style="color:#ddd;position:absolute;top:25px;right:0px;"></div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
export default {
name: 'ViewTags2',
components: {
Swiper,SwiperSlide
},
//props: {msg: String},
data(){
return{
swiperv2:{
slidesPerView: 'auto',
autoHeight: true,
spaceBetween: 0,
pagination: {
el: '.swiper-pagination',
clickable: true
},
navigation: {
nextEl: ".swbt2-prv",
prevEl: ".swbt2-nxt"
}
},
}
},
methods:{

},
watch:{

},
computed: {
...mapState({
setup: state => state.setup,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-tags-box{
/*background: linear-gradient(to bottom right, #fff 50%,#f1f1f1 20%,#fdfdfd 30%);*/
position:relative;display: flex;flex-wrap:wrap;
width:100%;height:75px;color:#4e4e4e;
position:relative;z-index:0;
/*padding:14px;*/
margin-right:auto;margin-left:auto;
font-family: 'Cairo', sans-serif;font-weight:400;
cursor:pointer;justify-content: right;align-content:center;
}
.home-tags-box-sw{
/*background: linear-gradient(to bottom right, #fff 50%,#f1f1f1 20%,#fdfdfd 30%);*/
position:relative;display: flex;flex-wrap:wrap;
width:100%;height: auto;color:#4e4e4e;
position:relative;z-index:0;
margin-right:auto;margin-left:auto;
font-family: 'Cairo', sans-serif;font-weight:400;
cursor:pointer;justify-content: right;
}
.home-tags-slide{
position:relative;
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
font-size:16px;color:#4e4e4e;height:50px;border-radius:8px;
margin-left:12px;margin-bottom:12px;width:85px;
border-top:1px solid #ccc;
border-right:1px solid #ccc;
border-bottom:1px solid #cdcdcd;
border-left:1px solid #ccc;
}
.home-tags-box a{
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
font-size:16px;color:#4e4e4e;width:100%;
}
.home-tags-box a:link{color:#4e4e4e;}
.home-tags-box a:visited{color:#4e4e4e;}

@media (min-width: 200px) and (max-width: 575px){
.home-tags-box{
justify-content:center;
}
}
@media (min-width: 800px) and (max-width: 1200px){

}
@media (min-width: 1200px){

}

</style>