<template>
<div class="qr-view-temp j7rap" style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:15px;color:#4e4e4e;
z-index:0;border:1px solid #eee;
background:#f7f6f6;box-shadow: 0px 5px 5px 0px #f7f6f6;border-radius:5%;
width:400px;height:460px;padding:14px;">
<div class="j7rap" style="height:45px;text-align:right;margin-top:7px;">
<span style="color:#353c45;width:auto;height:24px;font-size:20px;">امسح الباركود الظاهر أمامك</span>
</div>
<div class="j7rap" style="height:auto;margin-top:7px;padding-bottom:24px; text-align:right;">
<span style=" color:#353c45;width:auto;height:24px;font-size:20px;line-height:24px;text-align:right;">
 واستمتع بأحدث العروض  
</span>
</div>
<div class="j7rap" style="width:auto;height:200px;margin-top:24px;">
<img src="/img/qrv2.svg" style="height:100%; object-fit:cover;overflow:hidden;" />
<spn class="j7rap" style="color:#fff;width:auto;height:24px;font-size:20px;direction:ltr;
position:absolute;top:-30px; right:0; background:#353c45;height:40px;min-width:100%;border-radius:10px 10px 0px 0px;
">  
<span class="j7rap" style=" background:#fff;color:#353c45;width:80px;height:32px;
margin-left:7px;
">102</span>
<span class="j7rap" style="width:auto;height:24px;border-radius:10px 10px 0px 0px;">: الكود</span>
</spn>
</div>
</div>
</template>

<script>
import { mapState } from 'vuex';
//import TopNavbar from '../components/TopNavbar.vue'


export default {
name: 'TestMode',
components: {
},
data() {
return {
code_view:'',
prog:0
}
},
methods: {
emit_load_state:function(event){ 
this.prog = event;
}
},
mounted() {
this.$store.commit("set_page_title",this.cust_name);
},
updated() {
//alert('updated');
},
computed: {
...mapState({
pageview : state => state.pageview,
view_Nav : state => state.view_Nav,
cust_name : state => state.cust_name
})
}
}
</script>
<style scoped>
/*
background:linear-gradient(45deg, #f5f5f5,#e6e6e6);
background:#f7f6f6;
*/
.j7app-main-com{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
}
.j7app-header-home{
position: fixed;z-index:1;
display: flex; flex-wrap: wrap;
width: 100%;margin-right:auto;margin-left:auto;max-height:158px;
align-items: flex-start;touch-action: pan-x;
border-radius:0; border: 0px solid transparent;
background:#f7f6f6;
padding:0;
}
.j7app-main-home{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
background:#fff;
}
.j7flex{
display: flex;
align-content:center;justify-content:center;width:100%;height:100%;
position:relative;margin:auto;
}
.j7rap{
display: flex;flex-wrap: wrap;
align-content:center;justify-content:center;width:100%;height:100%;
position:relative;margin:auto;
}
</style>