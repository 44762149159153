<template>
<div class="j7-view-item-address" style="
display: flex;flex-wrap:wrap;width:100%;min-height:38px;
justify-content:center;align-content:center;line-height:38px;
font-weight:500;font-family: 'Noto Kufi Arabic', sans-serif;
cursor:pointer;color:#4e4e4e;white-space:nowrap;
margin:auto;padding-left:15px;padding-right:15px;">
<div style="border-top:1px solid #f4f4f4;line-height:55px;

display: flex; flex-wrap: wrap;width:100%;justify-content:center;position:relative;">
<span style="padding-right:3px;font-size:14px;position:relative;">
<span class="mdi mdi-18px mdi-map-marker-multiple" style="color:#888;line-height:38px;"></span>
{{address}} 
</span>
</div>
</div>
</template>
<script>
export default {
name: 'ItemAddress',
components: {},
props: ['address'],
data(){
return {
}
},
methods: {
},
watch:{

}
}
</script>

