<template>
<div class="PageHome j7app-main-com" dir="rtl" style="position:relative;background:#f7f6f6;">
<div class="home-nav j7app-header-home" style="box-shadow: 0px 1px 1px 0px #f7f6f6;max-width:1366px;background:#fff;">
<TopLikebar style=""></TopLikebar>
</div>
<div class="main-home-page j7app-main-home" style="max-width:1366px;background:#fff;"
 :style="view_Nav=='menu'&&header_type=='fixed'?'padding-top:152px;':''">
<ItemLike></ItemLike>
</div>
<BottomNav></BottomNav>
</div>
</template>

<script>
import { mapState } from 'vuex';
import TopLikebar from '../components/TopLikebar.vue'
import BottomNav from '../components/BottomNav.vue'
import Footer from '../components/Footer.vue'
import ItemLike from '../components/ItemLike.vue'
export default {
name: 'Likes',
components: {
TopLikebar,BottomNav,Footer,ItemLike

},
data() {
return {
prog:0
}
},
methods: {
emit_load_state:function(event){ 
this.prog = event;
}
},
mounted() {
//document.title=this.cust_name;
},
updated() {
//alert('updated');
},
watch:{
prog(){
if(this.prog==100) {
$('.LoadBar').hide();
}
}
},
computed: {
...mapState({
pageview : state => state.pageview,
view_Nav : state => state.view_Nav,
likes : state => state.likes,
cust_name : state => state.cust_name
})
}
}
</script>
<style>
/*
background:linear-gradient(45deg, #f5f5f5,#e6e6e6);
background:#f7f6f6;
*/
.j7app-main-com{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
}
.j7app-header-home{
position: fixed;z-index:1;
display: flex; flex-wrap: wrap;
width: 100%;margin-right:auto;margin-left:auto;max-height:158px;
align-items: flex-start;touch-action: pan-x;
border-radius:0; border: 0px solid transparent;
box-shadow: 0px 1px 1px 0px #f7f6f6;background:#f7f6f6;
padding:0;
}
.j7app-main-home{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
}
@media (min-width: 1200px){
.j7app-header-home{position: relative;width: 100%!important;}
.j7app-main-home{padding-top:0;}
}
</style>