<template>
<div class="listbox-home-imginfo"
style="position:absolute;top:33.33%;right:0px;display: flex;flex-wrap:wrap;height:92px;
font-family: 'Noto Kufi Arabic', sans-serif;
width:92px;justify-content: right; align-content:flex-start; z-index:10;padding:7px;">
<div style="display: flex;flex-wrap:wrap;width:36px;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);position:relative;
border-radius: 4px;height:36px;max-height:36px;width:36px;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;margin-top:14px;
justify-content: center;align-content:center;" v-on:click="showInfo('.fix-count-info.room'+'-'+index)">
<span class="mdi mdi-24px mdi-timelapse" style="color:#f7f6f6;padding:5px;opacity:0.8;
display: flex;flex-wrap:wrap;justify-content: center;align-content:center;"></span>
<div :class="'animate__animated animate__headShake fix-count-info room'+'-'+index"
 style="display:none;position:absolute;z-index:1;top:0px;right:35px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
background:#f44a6f;color:#fdfdfd;font-size:13px;width:100px;height:36px;">
الحالة {{item.type}}
</span>
</div>
</div>
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);margin-top:14px;
border-radius: 4px;height:36px;max-height:36px;width:36px;color:#ddd;
font-size:12px;font-weight:400;white-space:nowrap;position:relative;
justify-content: center;align-content:center;" v-on:click="showInfo('.fix-count-info.lounge'+'-'+index)">
<span class="mdi mdi-24px mdi-hand-wave-outline" style="color:#f7f6f6;line-height:36px;opacity:0.8;"></span>
<div :class="'animate__animated animate__headShake fix-count-info lounge'+'-'+index"
 style="display:none;position:absolute;z-index:1;top:0px;right:36px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
background:#21a9df;color:#fdfdfd;font-size:13px;width:128px;height:36px;">
القير {{item.gir}}
</span>
</div>
</div>
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);margin-top:14px;
border-radius: 4px;height:36px;max-height:36px;width:36px;color:#ddd;
font-size:12px;font-weight:400;white-space:nowrap;position:relative;
justify-content: center;align-content:center;" v-on:click="showInfo('.fix-count-info.bathroom'+'-'+index)">
<span class="mdi mdi-24px mdi-speedometer" style="color:#f7f6f6;line-height:36px;opacity:0.8;"></span>
<div :class="'animate__animated animate__headShake fix-count-info bathroom'+'-'+index" 
style="display:none;position:absolute;z-index:1;top:0px;right:36px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
border:1px solid #ddd;background:#fdfdfd;color:#333;font-size:13px;width:100px;height:36px;">
الموديل {{item.model}}
</span>
</div>
</div>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
//import 'swiper/css/swiper.min.css';
export default {
name: 'ItemStoryInfoBar',
components: {},
props: ['item','index'],
data(){
return {

}
},
mounted(){

},
methods:{
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
dis_like : function (guid) {
var i = 0;
this.likes.filter(item =>{
if(item.like==guid){
this.likes.splice(i,1);
}
i++;
});
this.lazy_update();
$('img.lazy').removeAttr('data-ll-status');
this.$cookies.set('likes',this.likes);
},
set_Like:function (guid){
var ck =this.likes.filter(item =>{
return item.like==guid;   
});
if(ck.length<1)
this.$store.commit("push_likes",guid);
this.$cookies.set('likes',this.likes);
},
lazy_update(){
this.$store.commit("lazy_update");
},
show_all_info:function(code,timeout) {
setTimeout(function () {
$('.room-'+code).show();
}, 800);
setTimeout(function () {
$('.lounge-'+code).show();
}, 1200);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1600);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
$('.story-box-view img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
}
},
created() {
},
destroyed(){
},
updated() {
},
computed: {
...mapState({
//db : state => state.db,
hosturl : state => state.hosturl,
pop_story:state => state.pop_story,
likes: state => state.likes
}),
filteredList(){
var like_posts=[];
this.likes.map(litem =>{
this.posts.map(pitem =>{
if(pitem.guid==litem.like){
like_posts.push(pitem);
}
})
});
return like_posts;   
}
},
watch:{

}
}
</script>

