<template>
<div class="sidebar-pc" style="border-left:1px solid #eee;">
<div class="sidebar-header" 
style="font-size:18px;display:flex;flex-wrap:wrap;height:auto;
width:100%;justify-content:center;align-content:center;font-weight:400;
align-items:center;font-family: 'Noto Kufi Arabic', sans-serif;
color:rgba(72, 72, 72,.9);height:101px;
border-bottom:1px solid #eee;">
<img src="img/darajaaa-logo-128.png" style="display:flex;flex-wrap:wrap;
justify-content:center;
max-height:60px;position:relative;padding-left:5px;align-content:center;" />
 <!--الدراجة العنيفة-->
</div>
<div class="sidebar-nav-box">
<div class="sidebar-nav" :class="nav_index==index ? 'active':''"
 v-opacity="0.6" 
 v-on:click=""
style="align-content:center;align-items:center;">
<span class="mdi mdi-24px mdi-play-box-outline nfooter-icon active"></span>
<span class="nfooter-span" 
style="font-size:18px;padding-right:7px;">الرئيسية</span>
</div>
<div class="sidebar-nav" :class="nav_index==index ? 'active':''"
 v-opacity="0.6" 
 v-on:click=""
style="align-content:center;align-items:center;">
<span class="mdi mdi-24px mdi-book-outline nfooter-icon"></span>
<span class="nfooter-span"
 style="font-size:18px;padding-right:7px;">اوقات العمل</span>
</div>
<div class="sidebar-nav" :class="nav_index==index ? 'active':''"
 v-opacity="0.6" 
 v-on:click=""
style="align-content:center;align-items:center;">
<span class="mdi mdi-24px mdi-phone-in-talk nfooter-icon"></span>
<span class="nfooter-span" 
style="font-size:18px;padding-right:7px;">اتصل بنا</span>
</div>
<div class="sidebar-nav" :class="nav_index==index ? 'active':''"
 v-opacity="0.6" 
 v-on:click=""
style="align-content:center;align-items:center;">
<span class="mdi mdi-24px mdi-map-marker-radius nfooter-icon"></span>
<span class="nfooter-span" 
style="font-size:18px;padding-right:7px;">الموقع</span>
</div>
</div>
<div class="sidebar-item-box">
<div class="sidebar-item" :class="nav_index==index ? 'active':''"
 v-opacity="0.6" v-for="(item, index) in cats"
 v-on:click="nav_index=index,setpage(index),SetViewCat(item.link,item.Aname)"
style="align-content:center;align-items:center;">
<span class="mdi mdi-24px mdi-pound nav-icon-ck" 
 style="display: flex;opacity:0.8;padding-left:1px;padding-right:1px;
flex-wrap:wrap;height:24px;width:24px;border-radius:100%;align-content:center;justify-content: center;">
</span>
<a class="nav-icon_titel sidebar-span">
{{item.Aname}}<CountItemByCat :item="item.Aname" style="padding-right:5px;"></CountItemByCat>
</a>
</div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import VueHorizontal from 'vue-horizontal';
import CountItemByCat from '../components/CountItemByCat.vue'
export default {
name: 'SideBarPc',
components: {VueHorizontal,CountItemByCat},
//props: {msg: String},
data(){
return {
nav_index:0,
prev_val:1,
i:1 
}
},
//this.$store.commit("set_obs_show", 'hide');
mounted(){

},
methods: {
next: function () {
this.$refs.horizontal.scrollToIndex(this.cats.length-1);
},
prev: function (){
//if(this.i>this.nav_index){this.i=0;this.nav_index=-1;}
//else{this.i++;this.prev_val=this.nav_index-this.i;}
if(this.nav_index>1){
this.$refs.horizontal.scrollToIndex(this.prev_val);
}
else {
this.$refs.horizontal.scrollToIndex(1);
this.i=0;
}
},
Navhorizontal:function (val){
//alert(0);
//this.$store.commit("Navhorizontal", val);
},
SetViewCat: function (val,name) {
this.$store.commit("set_pop_search",'')
this.$store.commit("SetViewCat", val);
this.$store.commit("set_filter_val", name);
this.$store.commit("Set_search_val", name);
},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetHideNav: function (val) {
this.$store.commit("SetHideNav", val);
},
setpage: function (val) {
this.$store.commit("setpage", val);
this.$store.commit("set_obs_show", 'hide');
//var fotter=$('#animate-footer-maps').offset().top;
//$('.j7app-containr').scrollTop(1);
$('.j7app-containr').animate({
scrollTop: 1
}, 800, function(){
});
}
},
watch:{
// whenever question changes, this function will run
pageview: function () {
this.$refs.horizontal.scrollToIndex(this.pageview + 1);
}
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
viewCat : state => state.viewCat,
hide_navbar: state => state.hide_navbar,
pageview : state => state.pageview
})
}
}
</script>
<style>
.j7app-navbar-item{border-radius:32px;}
</style>
<style scoped>
.nav-icon-ck{
color:#4e4e4e;
}
.nav-icon-ck.active{
background: rgba(16, 39, 95,.75);
color:rgba(255,255,255,.8);
}
.sidebar-span.active {
border:1px solid rgba(16, 39, 95,.2);
background:rgba(222, 50, 73,.8);
color: #fff;
}
.sidebar-pc{
position: fixed;z-index: 1;
display: flex;flex-wrap:wrap;
width:360px;font-family: 'Noto Kufi Arabic', sans-serif;
align-items: center;
background:#fff;
touch-action: pan-x pan-y;
margin-right:auto;margin-left:auto;
}
.sidebar-nav-box{
display: flex; flex-wrap: wrap;height:auto;width:100%;
font-size: 13px;font-weight: 400;text-align: center;
align-content: center;justify-content: center;
background:#fff;
user-select: none;cursor: pointer;
padding:14px;margin-right:auto;margin-left:auto;
}
.sidebar-item-box{
display: flex; flex-wrap: wrap;height:auto;width:auto;
font-size: 13px;font-weight: 400;text-align: center;
align-content: center;justify-content: center;
background:#fff;
user-select: none;cursor: pointer;
padding:14px;margin-right:auto;margin-left:auto;
}
.sidebar-nav{
display: flex; flex-wrap: wrap;height:60px;width:100%;
font-size: 13px;font-weight: 400;text-align: center;
align-content: center;justify-content: center;
background:#fff;border-bottom:1px solid rgba(16, 39, 95,.1);
color:rgba(16, 39, 95,.75);color:#4e4e4e;
user-select: none;cursor: pointer;border-radius:4px;
padding:10px;margin-bottom:14px;
transition-property: transform;
touch-action: pan-x !important;
}
.sidebar-nav span{color:#4e4e4e;}
.sidebar-item{
display: flex; flex-wrap: wrap;height:50px;width:auto;
font-size: 13px;font-weight: 400;text-align: center;
align-content: center;justify-content: center;
background:#fff;border:1px solid rgba(16, 39, 95,.1);
color:rgba(16, 39, 95,.75);color:#4e4e4e;
border-radius:32px;
user-select: none;cursor: pointer;
padding:10px;margin-right:10px;margin-top:14px;
transition-property: transform;
touch-action: pan-x !important;
}
.sidebar-span{
display: flex; width: auto;max-height:42px;font-size:13px; 
align-items: flex-start; justify-content: center;
font-family: 'Cairo', sans-serif;font-weight:400;padding-right:5px;
}
.sidebar-item img{
display:flex; justify-content:center;
max-width:55px;height:14px;
border-radius:50%;background:#fff;
}
.sidebar-item:focus {
border-top:1px solid #d5d5d5;
border-bottom:1px solid #a5b4b5;
border-right:1px solid #d5d5d5;
border-left:1px solid #a5b4b5;
color: #1e3d60;
}
.sidebar-item:hover {
background:rgba(222, 50, 73,.8);
color: #fff;
}
.sidebar-item.rippel {
background: linear-gradient(45deg, #faf9f8,#fff);
border-color:#ccc;
color: #1a1a1a;
}
</style>
<style scoped>
@media (min-width: 1200px){
.sidebar-span{font-size:16px;}
.sidebar-item img{display:none;}
}
</style>