<template>
<div class="home-footer-info-box" style="align-items:center;">
<img src="/img/logo-md.png" style="display:flex;max-height:75px;object-fit:contain;overflow:hidden;" />
<div class="info-edit-box" style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:15px;color:#4e4e4e;"
v-if="setup[0].whos" v-html="setup[0].whos">
</div>
<div class="snap-chat-box">
<a href="https://www.snapchat.com/add/wer999y" target="_blank"
style="font-family: 'Noto Kufi Arabic', sans-serif;color:#4e4e4e;
display:flex;flex-wrap:wrap;width:100%;justify-content:center;">
<span style="font-size:16px;display:flex;flex-wrap:wrap;width:100%;justify-content:center;margin-bottom:10px;">تابعنا على سناب شات</span>
<img src="/img/snap.png" style="display:flex;max-height:128px;object-fit:contain;overflow:hidden;opacity:0.9;" />
<span style="font-size:18px;display:flex;flex-wrap:wrap;width:100%;justify-content:center;">wer999y</span>
</a>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
name: 'FooterInfo',
components: {},
//props: {msg: String},
data(){
return {
     
}
},
methods: {

},
watch:{

},
computed: {
...mapState({
setup:state => state.setup,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.snap-chat-box{
display:flex;flex-wrap:wrap;width:100%;
justify-content:center;align-content:center;align-items:center;
padding-top:18px; border-top:1px solid #eee;
}
.home-footer-info-box{
position:relative;display:flex;
padding:14px;padding-top:24px;
flex-wrap:wrap;
width:100%;height:300px;
align-content:flex-start;justify-content:center;
}
.info-edit-box{
position:relative;display:flex;
flex-wrap:wrap;margin-bottom:14px;margin-top:14px;
width:100%;height:auto;font-size:13px;line-height:24px;
align-content:center;justify-content:center;text-align:center;
}
.info-edit-box p{
position:relative;display:flex;
flex-wrap:wrap;
width:100%;height:auto;font-size:13px;line-height:24px;
align-content:center;justify-content:center;text-align:center;
}
.home-footer-info-box img{

}
@media (min-width: 200px){
.home-footer-info-box{
height:auto;
}
.info-edit-box{
width:94%;padding:0px;text-align:center;
margin-right:auto;margin-left:auto;
}
}
@media (min-width: 450px){
.home-footer-info-box{
height:auto;
}
.info-edit-box{
width:100%;padding-left:50px;padding-right:50px;
margin-right:auto;margin-left:auto;
}
}
@media (min-width: 600px) and (max-width: 800px){
.snap-chat-box{display:none;}
.home-footer-info-box{
height:300px;
}
.info-edit-box{
width:100%;padding-left:0px;padding-right:0px;
margin-right:auto;margin-left:auto;text-align:center;
overflow-x:hidden;overflow-y:auto;max-height:168px;
scrollbar-width:none;
}
}
@media (min-width: 800px) and (max-width: 991px){
.snap-chat-box{display:none;}
}
@media (min-width: 992px) and (max-width: 1400px){
.snap-chat-box{display:none;}
.home-footer-info-box{
height:300px;
}
.info-edit-box{
width:100%;padding-left:0px;padding-right:0px;
margin-right:auto;margin-left:auto;padding-top:10px;
overflow-x:hidden;overflow-y:auto;max-height:168px;
scrollbar-width:none;
}

}

@media (min-width: 1400px){
.snap-chat-box{display:none;}
.info-edit-box{
width:100%;padding-left:24px;padding-right:24px;
margin-right:auto;margin-left:auto;
}
}
</style>