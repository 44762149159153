<template>
<div id="home-hero-warp" class="j7rap home-hero-warp" style="
background:#4e4e4e url(/img/hero-sm.jpg);
background-size:cover;background-position: center;background-repeat:no-repeat;
overflow:hidden;">
<div class="j7rap home-hero-main" style="padding-bottom:0px;height:auto;">
<div class="j7rap home-hero-serach-box" style="position:relative;height:auto;">
<div class="j7rap home-hero-top" style="height:auto;">
<img src="/img/injaz_style.svg" style="height:auto;display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
max-height:100px;max-width:100px;position:relative;" />
<span style="font-size:15px;display:flex;flex-wrap:wrap;height:25px;width:100%;justify-content:center;align-content:center;color:rgba(88, 105, 141,.9);padding:0!important;">
شركة اساس الإنجاز للخدمات العقارية
</span>
<!--<span class="mdi mdi-48px mdi-menu" style="color:rgba(51, 82, 130,.8);position:relative;width:55px;display:flex;flex-wrap:wrap;align-content:center;justify-content:center;"></span>-->
<!--<span style="display:none;">{{search_tags}} - {{search_list.length}}
</span>
<span style="display:none;" v-for="item in address_List">{{search_tags}} - {{search_list.length}}
</span>-->
</div>
<div class="home-hero-title">
<span style="color:rgba(88, 105, 141,.9);font-size:16px;">
اختر العقار المناسب لك
</span>
</div>
<div class="hero-form-box loc" style="position:relative;">
<label for="location" style="position:relative;">الموقع</label> 
<input v-model="search_loc" v-on:click="pop_select_loc=false"  v-opacity type="text" id="search_loc" name="address" required="required" class="input-form-box" placeholder="أدخِل موقع" autocomplete="off">
<span v-if="search_loc!=''" v-on:click="search_loc='',pop_select_loc=false" v-opacity style="position:relative;z-index:1; display:flex;flex-wrap:wrap;align-content:center;justify-content:center;">
<span class="mdi mdi-24px mdi-close" style="color:rgba(78, 78, 78,.5);position:absolute; top:0; left:0;height:55px; width:55px; display:flex;flex-wrap:wrap;align-content:center;justify-content:center;"></span>
</span>
<div v-if="pop_fix_loc&&search_loc!=''&&address_List.length>1" class="fix-loc-txt" style="z-index:1;border-radius:0px 0px 24px 24px;">
<div class="fix-loc-txt-main">
<span v-if="item" v-on:click="search_loc=item,pop_fix_loc=false,pop_select_loc=true"
 class="fix-loc-txt-span" v-for="item in address_List" style="color:rgba(75, 75, 75,.8);">
<span class="mdi mdi-18px mdi-map-marker" style="color:rgba(75, 75, 75,.8);display:flex;flex-wrap:wrap;align-content:center;justify-content:center;padding-left:7px;"></span>
{{item}} 
</span>
</div>
</div>
</div>
<div class="hero-form-box">
<label>العقار :</label> 
<div class="list-hero-item">
<div v-opacity class="list-hero-btn" v-on:click="search_op1='بيع'" style="padding-left:24px;margin-right:auto;">
<span class="mdi mdi-24px mdi-checkbox-blank-circle-outline" style="color:rgba(78, 78, 78,.8);position:relative;">
<span v-if="search_op1=='بيع'" style="position:absolute;top:6px;right:6.5px;height:12px;width:12px;background:#5fda9f;border-radius:50%;"></span>
</span>
<span style="padding-right:7px;">بيع</span>
</div>
<div v-opacity class="list-hero-btn" v-on:click="search_op1='تأجير'" style="padding-left:10px;margin-left:auto;">
<span class="mdi mdi-24px mdi-checkbox-blank-circle-outline" style="color:rgba(78, 78, 78,.8);position:relative;">
<span v-if="search_op1=='تأجير'" style="position:absolute;top:6px;right:6.5px;height:12px;width:12px;background:#5fda9f;border-radius:50%;"></span>
</span>
<span style="padding-right:7px;">تأجير</span>
</div>
</div>
</div>
<div v-if="search_op1!='تأجير'" class="hero-form-box">
<label >النوع :</label> 
<div class="list-hero-item">
<div v-opacity class="list-hero-btn" v-on:click="search_op2='شقه'" style="padding-left:14px;margin-right:auto;">
<span class="mdi mdi-24px mdi-checkbox-blank-circle-outline" style="color:rgba(78, 78, 78,.8);position:relative;">
<span v-if="search_op2=='شقه'" style="position:absolute;top:6px;right:6.5px;height:12px;width:12px;background:#5fda9f;border-radius:50%;"></span>
</span>
<span style="padding-right:7px;">شقه</span>
</div>
<div v-opacity class="list-hero-btn" v-on:click="search_op2='فيلا'" style="padding-left:14px;">
<span class="mdi mdi-24px mdi-checkbox-blank-circle-outline" style="color:rgba(78, 78, 78,.8);position:relative;">
<span v-if="search_op2=='فيلا'" style="position:absolute;top:6px;right:6.5px;height:12px;width:12px;background:#5fda9f;border-radius:50%;"></span>
</span>
<span style="padding-right:7px;">فيلا</span>
</div>
<div v-opacity class="list-hero-btn" v-on:click="search_op2='دور'" style="margin-left:auto;">
<span class="mdi mdi-24px mdi-checkbox-blank-circle-outline" style="color:rgba(78, 78, 78,.8);position:relative;">
<span v-if="search_op2=='دور'" style="position:absolute;top:6px;right:6.5px;height:12px;width:12px;background:#5fda9f;border-radius:50%;"></span>
</span>
<span style="padding-right:7px;">دور</span>
</div>
</div>
</div>
<div v-if="search_op1=='تأجير'" class="hero-form-box">
<label >النوع :</label> 
<div  class="list-hero-item">
<div v-opacity class="list-hero-btn" v-on:click="search_op2='سنوي'" style="padding-left:14px;margin-right:auto;">
<span class="mdi mdi-24px mdi-checkbox-blank-circle-outline" style="color:rgba(78, 78, 78,.8);position:relative;">
<span v-if="search_op2=='سنوي'" style="position:absolute;top:6px;right:6.5px;height:12px;width:12px;background:#5fda9f;border-radius:50%;"></span>
</span>
<span style="padding-right:7px;">سنوي</span>
</div>
<div v-opacity class="list-hero-btn" v-on:click="search_op2='شهري مؤثث'" style="margin-left:auto;">
<span class="mdi mdi-24px mdi-checkbox-blank-circle-outline" style="color:rgba(78, 78, 78,.8);position:relative;">
<span v-if="search_op2=='شهري مؤثث'" style="position:absolute;top:6px;right:6.5px;height:12px;width:12px;background:#5fda9f;border-radius:50%;"></span>
</span>
<span style="padding-right:7px;">شهري</span>
</div>
</div>
</div>
<div class="hero-form-box">
<label>الحالة :</label> 
<div class="list-hero-item">
<div v-opacity class="list-hero-btn" v-on:click="search_op3='مؤثثه'" style="margin-right:auto;padding-left:14px;">
<span class="mdi mdi-24px mdi-checkbox-blank-circle-outline" style="color:rgba(78, 78, 78,.8);position:relative;">
<span v-if="search_op3=='مؤثثه'" style="position:absolute;top:6px;right:6.5px;height:12px;width:12px;background:#5fda9f;border-radius:50%;"></span>
</span>
<span style="padding-right:7px;">مؤثثه</span>
</div>
<div v-opacity class="list-hero-btn" v-on:click="search_op3='غير مؤثثه'" style="margin-left:auto;">
<span class="mdi mdi-24px mdi-checkbox-blank-circle-outline" style="color:rgba(78, 78, 78,.8);position:relative;">
<span v-if="search_op3=='غير مؤثثه'" style="position:absolute;top:6px;right:6.5px;height:12px;width:12px;background:#5fda9f;border-radius:50%;"></span>
</span>
<span style="padding-right:7px;">غير مؤثثه</span>
</div>
</div>
</div>
<div class="hero-form-box search" style="padding:0;">
<div class="list-hero-item search" style="justify-content:center;">
<div v-opacity class="list-hero-btn" v-on:click="ck_posts" style="background:rgba(78, 78, 78,.8);width:100%;height:72px;border-radius:50px;">
<span style="font-size:18px;font-weight:bold;color:#f4f4f4;">بحث</span>
</div>
</div>
</div>
<div v-if="search_msg=='show'" class="j7rap" style="padding:0;background:none;height:auto;justify-content:center;">
<div class="hero-form-msg" style="padding:14px;background:rgba(255, 255, 255,.95); ">
<div style="font-size:16px;color:rgba(50, 50, 50,.8);
display:flex; flex-wrap:wrap;justify-content:center;width:100%;max-width:100%;">
<span class="mdi mdi-24px mdi-alert-circle-outline" 
style="color:rgba(235, 30, 57,.8);position:relative;width:24px;padding-top:3px;line-height:24px;"></span>
<span style="max-width:calc(100% - 45px);text-align:justify;padding-right:10px;">{{search_msg}}</span>
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
name: 'HomeHero',
components: {},
//props: ['maps','latitude','longitudes'],
data(){
return {
pop_fix_loc:false,
pop_select_loc:false,
search_msg:'',
search_list:[], 
search_count:-1, 
search_loc:'', 
search_op1:'',     
search_op2:'',     
search_op3:'',  
search_op4:'',  
search_tags:''  
}
},
mounted(){
//this.lazy_update();
this.set_doc_height();
window.addEventListener('resize', this.set_doc_height());
//list-hero-btn
//this.$refs.mySwiper_Strory.$swiper.slideTo(2, 1000, false)
},
methods: {
set_pop_hero:function(val){ 
this.$store.commit("set_pop_hero",val);
},
set_doc_height:function(){ 
var vh=window.innerHeight;
$('#home-hero-warp').css("height",vh);
$('#home-hero-warp').css("max-height",vh);
},
array_remove_duplicat:function(arr) {
let unique = [];
arr.forEach(element => {
if (!unique.includes(element)) {
unique.push(element);
}
});
return unique;
},
ck_posts:function(){
this.search_tags=''; 
if(this.search_op1!=''){this.search_tags=this.search_op1+',';}
if(this.search_op2!=''){this.search_tags+=this.search_op2;}
if(this.search_op3=='مؤثثه'){this.search_tags+=','+this.search_op3;}
if(this.search_tags==''){this.search_tags='الكل';}
this.$store.commit("set_filter_val", this.search_tags);
//search_tags=search_op1+','+search_op2+','+search_op3
//posts
if(this.search_loc!=''){
this.search_list=this.posts.map(item =>
{
item.fprice=item.price.toString().replace(',','').replace(',','');
return item;
}).filter(item =>
{
//return this.search_val.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
return this.search_tags.toLowerCase().split(',').every(v => item.tags.toLowerCase().includes(v)) 
&&this.search_loc.toLowerCase().split('').every(v => item.address.toLowerCase().includes(v));
});
}
else{
this.search_list=this.posts.map(item =>
{
item.fprice=item.price.toString().replace(',','').replace(',','');
return item;
}).filter(item =>
{
//return this.search_val.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
return this.search_tags.split(',').every(v => item.tags.toLowerCase().includes(v));
});
}
if(this.search_list.length>0){
this.$store.commit("set_search_list",this.search_list);
this.$store.commit("set_pop_search",this.search_tags);
this.set_pop_hero('hide');
}
else{
this.$store.commit("Swal_Error_Time", 'عفوا نوع العقار غير متاح الرجاء تحديد خيارات بحث اخرى..');
this.search_msg="عفوا نوع العقار غير متاح الرجاء تحديد خيارات بحث اخرى..";
}
}
},
watch:{
search_loc(){
if(this.search_loc!=''&&this.pop_select_loc==false){
this.pop_fix_loc=true;
}
},
pop_select_loc(){
}
},
computed: {
address_List(){
var ck=this.posts.map(item =>
{
if(item.address.toLowerCase().includes(this.search_loc.toLowerCase())){
return item.address;
}
});
return this.array_remove_duplicat(ck);
},
...mapState({
posts : state => state.posts,
filter_val : state => state.filter_val,
search_val: state => state.search_val,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-hero-title-pc{
display:flex;flex-wrap:wrap;height:72px;
width:100%;justify-content:center;align-content:center;
position:relative;
}
.home-hero-title{
display:flex;flex-wrap:wrap;height:72px;
width:100%;justify-content:center;align-content:center;position:relative;
}
.fix-loc-txt{
background:#fff;position:absolute;top:60px;right:0px;
display:flex;flex-wrap:wrap;justify-content:center;margin-right:auto;margin-left:auto;
height:auto;width:100%;
padding-bottom:14px;
}
.fix-loc-txt-main{
display:flex;flex-wrap:wrap;width:94%;
min-height:84px;justify-content:center;
margin-right:auto;margin-left:auto;max-height:255px;overflow-y:scroll;overflow-x:hidden;scrollbar-width:thin;}
.fix-loc-txt-main::-webkit-scrollbar{
background: none;width:10px;height:0px; }
.fix-loc-txt-span{
display:flex;flex-wrap:wrap;width:94%;
height:55px;justify-content:center; align-content:center;
margin-right:auto;margin-left:auto;border-bottom:1px solid #ddd;
}
.fix-loc-txt-span:last-child{}
.home-hero-warp{
font-family: 'Noto Kufi Arabic', sans-serif;font-size:15px;}
.h2{
display:flex;flex-wrap:wrap;white-space:normal;
width:100%;justify-content:center;position:relative;
}
.hero-form-box{
display:flex;flex-wrap:wrap;white-space:normal;
width:90%;justify-content:center;align-content:center;
background:#fff;height:72px;
padding-right:24px;
padding-left:24px;
border-radius:24px;
margin-bottom:15px;
}
.hero-form-box.loc{height:84px;}
.hero-form-msg{
display:flex;flex-wrap:wrap;white-space:normal;
width:auto;justify-content:center;align-content:center;
background:#fff;height:72px;max-width:300px;border-radius:4px;
}
.hero-form-box label{
position:relative;
display:flex;flex-wrap:wrap;white-space:normal;
width:100%;justify-content:right;align-content:center;
height:16px;font-weight:800;font-size:13px;color:#4e4e4e;
padding-right:15px;padding-top:16px;
}
.list-hero-item{
position:relative;border:none;
background-color: transparent;padding-top:10px;
display:flex;flex-wrap:wrap;white-space:normal;
width:100%;justify-content:right;align-content:center;
height:55px;
}
.home-hero-top{
height:auto!important;max-height:172px;position:relative;
z-index:1;padding-right:24px;padding-left:24px;}
.list-hero-item.loc{padding-top:0px;}
.list-hero-item.search{padding-top:0px;background:none;}
.hero-form-box.search{background:none;}
.list-hero-btn{
position:relative;border:none;
background-color: transparent;
display:flex;flex-wrap:wrap;white-space:normal;
justify-content:center;align-content:center;
height:100%;
}
.input-form-box{
position:relative;border:none;
background-color: transparent;
font-weight:400;font-size:16px;color:#4e4e4e;
border:0;border-radius:0;outline:none;
box-shadow:none;padding:0;
padding-right: 15px;height:55px;
padding-left: 15px;cursor:pointer;text-align:center;
display:flex;flex-wrap:wrap;white-space:normal;
width:100%;justify-content:center;align-content:center;
}
.view{
display:flex;flex-wrap:wrap;white-space:normal;
width:100%;justify-content:center;
}
@media (min-width: 360px) and (max-width: 600px){
.hero-form-box{max-width:320px;}
.home-hero-title-pc{display:none;}
}
@media (min-width: 600px) and (max-width: 799px){
.hero-form-box{width:43%;margin-left:14px;height:84px;}
.home-hero-title-pc{display:none;}
.home-hero-title{height:100px;}
.home-hero-title span{font-size:22px!important;}
}
@media (min-width: 800px) and (max-width: 1200px){
.fix-loc-txt-main{max-height:100px;max-height:100px;}
.hero-form-box{width:35%;margin-left:14px;height:84px;}
.home-hero-title{height:100px;}
.home-hero-title span{font-size:22px!important;}
}
@media (min-width: 1200px) and (max-width: 1400px){
.home-hero-title{}
.fix-loc-txt-main{max-height:100px;max-height:250px;}
.hero-form-box{width:22%;margin-left:14px;height:84px;}
.home-hero-top{position:fixed;top:20%;}
.home-hero-title{height:100px;}
.home-hero-title span{font-size:22px!important;}
}
@media (min-width: 1400px){
.fix-loc-txt-main{max-height:200px;}
.hero-form-box{width:auto;height:84px;margin-left:14px;border:1px solid #aaaaaa;}
.hero-form-box.search{width:200px;border:none;}
.home-hero-top{position:fixed;top:20%;}
.home-hero-title{height:100px;}
.home-hero-title span{font-size:22px!important;}
}
</style>
