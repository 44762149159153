<template>
<div class="top_btn j7app-navbar" v-if="view_Nav=='menu'">
<div class="nav-item-prev" v-on:click="prev"
style="position:absolute;right:0px;display: flex;flex-wrap:wrap;cursor:pointer;
height:65px;width:65px;align-content:center;justify-content: center;">
<span v-rippleffect class="mdi mdi-24px mdi-chevron-right" 
 style="display: flex;
flex-wrap:wrap;height:32px;width:32px;border-radius:100%;
align-content:center;justify-content: center;">
</span>
</div>
<div class="swiper_nav j7app-navbar-swbox" v-show="view_Nav=='menu'">
<vue-horizontal class="horizontal" ref="horizontal" snap="none" :button="false">
<!--item-home-nbtn--> 
<div class="j7app-navbar-item" :class="nav_index==index ? 'active':''"
 v-opacity="0.6" v-for="(item, index) in cats"
 v-on:click="nav_index=index,setpage(index),SetViewCat(item.link,item.Aname)"
style="align-content:center;align-items:center;">
<!--<span v-if="nav_index==index" class="mdi mdi-18px mdi-chevron-down nav-icon-ck" 
:class="nav_index==index ? 'active':''"
 style="display: flex;
flex-wrap:wrap;height:18px;width:18px;border-radius:100%;align-content:center;justify-content: center;">
</span>-->
<a class="nav-icon_titel j7app-navbar-item-name">
{{item.Aname}}<CountItemByCat :item="item.Aname" style="padding-right:5px;"></CountItemByCat>
</a>
</div>
</vue-horizontal>
</div>
<div class="nav-item-next" v-on:click="next"
style="position:absolute;left:0px;display: flex;flex-wrap:wrap;cursor:pointer;
height:65px;width:65px;align-content:center;justify-content: center;">
<span v-rippleffect class="mdi mdi-24px mdi-chevron-left" 
 style="display: flex;
flex-wrap:wrap;height:32px;width:32px;border-radius:100%;
align-content:center;justify-content: center;">
</span>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import VueHorizontal from 'vue-horizontal';
import CountItemByCat from '../components/CountItemByCat.vue'
export default {
name: 'NavbarPc',
components: {VueHorizontal,CountItemByCat},
//props: {msg: String},
data(){
return {
nav_index:0,
prev_val:1,
pg_width:window.innerWidth,
i:1 
}
},
//this.$store.commit("set_obs_show", 'hide');
mounted(){

},
methods: {
next: function () {
this.$refs.horizontal.scrollToIndex(this.cats.length-1);
},
prev: function (){
//if(this.i>this.nav_index){this.i=0;this.nav_index=-1;}
//else{this.i++;this.prev_val=this.nav_index-this.i;}
if(this.nav_index>1){
this.$refs.horizontal.scrollToIndex(this.prev_val);
}
else {
this.$refs.horizontal.scrollToIndex(1);
this.i=0;
}
},
Navhorizontal:function (val){
//alert(0);
//this.$store.commit("Navhorizontal", val);
},
SetViewCat: function (val,name) {
this.$store.commit("set_pop_search",'')
this.$store.commit("SetViewCat", val);
this.$store.commit("set_filter_val", name);
this.$store.commit("Set_search_val", name);
},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetHideNav: function (val) {
this.$store.commit("SetHideNav", val);
},
setpage: function (val) {
this.$store.commit("setpage", val);
this.$store.commit("set_obs_show", 'hide');
//var fotter=$('#animate-footer-maps').offset().top;
//$('.j7app-containr').scrollTop(1);
$('.j7app-containr').animate({
scrollTop: 1
}, 800, function(){
});
}
},
watch:{
// whenever question changes, this function will run
pageview:function(){
if(this.view_Nav=='menu'){
this.$refs.horizontal.scrollToIndex(this.pageview + 1);
}
}
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
viewCat : state => state.viewCat,
hide_navbar: state => state.hide_navbar,
pageview : state => state.pageview
})
}
}
</script>
<style>
.j7app-navbar-item{border-radius:32px;}
</style>
<style scoped>
.nav-icon-ck{
color:rgba(16, 39, 95,.75);
}
.nav-icon-ck.active{
background: rgba(16, 39, 95,.75);
color:rgba(255,255,255,.8);
}
.j7app-navbar{
/*
background:#f7f6f6;
background-color: rgba(18,19,20,.1);
*/
position: relative;z-index: 1;
display: flex;width:100%;height:92px;
max-height:92px;align-items: center;
background-color:#fff;
color: #272727;
touch-action: pan-x pan-y;
margin-right:auto;margin-left:auto;
padding-right:55px;padding-left:55px;
}
.j7app-navbar-swbox{
position: relative;
height:55px;max-height: 55px;z-index: 1; 
overflow: hidden;
touch-action: pan-x !important;
margin-right: auto;margin-left: auto;  
padding-right: 7px;padding-left: 7px 
}
.j7app-navbar-item{
display: flex; flex-wrap: wrap;height:55px;min-width:150px;
font-size: 13px;font-weight: 400;text-align: center;
align-content: center;justify-content: center;
background:#fff;border:1px solid rgba(16, 39, 95,.1);
border-radius:32px;color:#4e4e4e;
/*border-radius:50%;*/
user-select: none;cursor: pointer;
margin-left: 12px;
transition-property: transform;
touch-action: pan-x !important;
}
.j7app-navbar-item.active {
border:1px solid rgba(16, 39, 95,.1);
background:rgba(222, 50, 73,.8);
color: #fff;
}
.j7app-navbar-item-name{
display: flex; width: auto;max-height:42px;font-size:13px; 
align-items: flex-start; justify-content: center;
font-family: 'Cairo', sans-serif;font-weight:400;padding-right:5px;
}
.j7app-navbar-item img{
display:flex; justify-content:center;
max-width:55px;height:14px;
border-radius:50%;background:#fff;
}
.j7app-navbar-item:focus {
border-top:1px solid #d5d5d5;
border-bottom:1px solid #a5b4b5;
border-right:1px solid #d5d5d5;
border-left:1px solid #a5b4b5;
color: #1e3d60;
}
.j7app-navbar-item:hover {
background:rgba(222, 50, 73,.8);
color: #fff;
}
.j7app-navbar-item.rippel {
background: linear-gradient(45deg, #faf9f8,#fff);
border-color:#ccc;
color: #1a1a1a;
}
</style>
<style scoped>
@media (min-width: 1200px){
.j7app-navbar{width:auto;height:172px;
border-top:1px solid #f5f5f5;
}
.j7app-navbar-swbox{width:auto;margin:auto;}
.j7app-navbar-item{
}
.j7app-navbar-item{
border:1px solid rgba(64, 78, 115,.11);
}
.j7app-navbar-item-name{font-size:16px;}
.j7app-navbar-item img{display:none;}
}
</style>