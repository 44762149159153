<template>
<div class="home-footer" v-on:click="">
<div v-if="setup.length>0" class="home-footer-warp animate__animated animate__bounceInUp">
<div id="animate-footer-contact" class="home-footer-item-box animate__animated" data-animation="animate__fadeInRight"
 v-observe-visibility="visibilityChanged">
<FooterContact></FooterContact>
</div>
<div id="animate-footer-time" class="home-footer-item-box animate__animated" data-animation="animate__fadeInLeft"
 v-observe-visibility="visibilityChanged">
<FooterTime></FooterTime>
</div>
<div id="animate-footer-maps" class="home-footer-item-box animate__animated" data-animation="animate__fadeInRight"
 v-observe-visibility="visibilityChanged">
<FooteMaps></FooteMaps>
</div>
<div id="animate-footer-info" class="home-footer-item-box animate__animated" data-animation="animate__fadeInLeft"
 v-observe-visibility="visibilityChanged">
<FooterInfo></FooterInfo>
</div>
<div class="home-footer-copyright-box" style="background:#fff;">
<a href="https://onekod.com/"
target="_blank" style="font-size:16px;color:#4e4e4e;user-select:text!important;text-decoration:none;">
برمجة وتصميم - ون كود  - 2024 
</a>
</div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import FooterSocial from '../components/FooterSocial.vue';
import FooterInfo from '../components/FooterInfo.vue';
import FooterContact from '../components/FooterContact.vue';
import FooteMaps from '../components/FooteMaps.vue';
import FooterTime from '../components/FooterTime.vue';
export default {
name: 'Footer',
components: {FooterInfo,FooterSocial,FooterContact,FooteMaps,FooterTime},
//props: {msg: String},
data(){
return {

}
},
methods: {
visibilityChanged:function(isVisible, entry) {
if(isVisible){
//console.log(entry.target.id);
var animation=$("#"+entry.target.id).data('animation');
if(!entry.target.classList.contains(animation)){
//let domElement = entry.target.id;
entry.target.classList.add(animation);
}
}
}
},
watch:{

},
computed: {
...mapState({
setup: state => state.setup,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-footer{
/*background: linear-gradient(to bottom right, #fff 50%,#f1f1f1 20%,#fdfdfd 30%);*/
position:relative;display: flex;flex-wrap:wrap;
width:100%;height: auto;color:#4e4e4e;
padding-top:7px;position:relative;z-index:0;
/*padding:14px;*/
padding-bottom:68px;
font-family: 'Cairo', sans-serif;font-weight:400;
overflow:hidden;cursor:pointer;justify-content: center;
}
.home-footer-warp{
position:relative;z-index:0;display: flex;flex-wrap:wrap;width: 100%;
max-width:auto; justify-content: center;
align-content:center;margin-right:auto;margin-left:auto;

}
.home-footer-item-box{
position:relative;display:flex;background:rgba(255,255,255,0.85);
position:relative;z-index:0;
flex-wrap:wrap;border-bottom:1px solid #f1f3f4;
width:100%;box-shadow: 0px 5px 5px 0px rgba(82, 63, 105, 0.05);
align-content:center;justify-content:center;
}
.home-footer-copyright-box{
position:relative;display:flex;
flex-wrap:wrap;
width:100%;height:auto;padding-top:24px;padding-bottom:24px;
align-content:center;justify-content:center;
}
.home-footer-copyright-box a{
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
font-size:16px;color:#777;
}

@media (min-width: 600px) and (max-width: 800px){
.home-footer-item-box{
width:49.33%;border:none;
margin:auto;min-height:300px;max-height:300px;overflow:hidden;
border:1px solid #f1f3f4;margin-bottom:7px;
}
}
@media (min-width: 800px) and (max-width: 1200px){
.home-footer{padding-bottom:7px;}
.home-footer-item-box{
width:49.75%;border:none;
margin:auto;min-height:300px;max-height:300px;overflow:hidden;
border:1px solid #f1f3f4;margin-bottom:7px;
}
}
@media (min-width: 1200px){
.home-footer{padding-bottom:7px;}
.home-footer-copyright-box{
padding-top:24px;padding-bottom:24px;
}
.home-footer-item-box{
width:24.75%;border:none;
margin:auto;min-height:300px;max-height:300px;overflow:hidden;
margin-bottom:7px;margin-right:0.25%;
border:1px solid #f1f3f4;
}
.home-footer-item-box:first-child{margin-right:0;}
.home-footer-item-box:nth-last-child(-n+2){
margin-left:0;margin-right:0.25%;
}

}

</style>