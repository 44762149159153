<template>
<div class="j7-view-item-title" style="">
<span style="display: flex;flex-wrap: wrap;width:auto;height:auto;
align-content:flex-start;">
{{title}} 
</span>
</div>
</template>
<script>
import { mapState} from "vuex";

export default {
name: 'ItemTitlePc',
components: {},
props: ['title','address'],
data(){
return {
}
},
methods: {
},
mounted() {
this.$store.commit("set_page_title",this.title+' | '+this.cust_name);
},
watch:{

},
computed: {
...mapState({
page_title : state => state.page_title,
cust_name : state => state.cust_name
})
}
}
</script>
<style scoped>
.j7-view-item-title{
display: flex;flex-wrap:wrap;width:100%;
justify-content:center;align-content:center;
font-weight:400;font-family: 'Noto Kufi Arabic', sans-serif;
color:#4e4e4e;white-space:normal;cursor:pointer;
height:45px;margin:auto;padding-left:14px;padding-right:14px;
align-content:flex-start;
}
@media (min-width: 800px) and (max-width: 1200px){
.j7-view-item-title{
height:auto;align-content:flex-start;
}
}

@media (min-width: 1200px){
.j7-view-item-title{
height:auto;align-content:flex-start;
justify-content:right;text-align:right;
}
.j7-view-item-title span{
height:auto;align-content:flex-start;padding-right:14px;
}
}

</style>
