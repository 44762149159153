<template>
<div 
 class="menu-item-box listbox-home-warp" style="position:relative;">
<div id="v-pan-box" v-pan="(e) => pan_item(e,'v-pan-box')"
v-panend="(e) => pan_item_end(e,'v-pan-box')" class="--scroll_items-- listbox-home-box animate__animated animate__fadeIn" style="
background:#4e4e4e;min-width:320px;overflow:hidden;">
<div v-opacity class="listbox-home-item animate__animated" :id="'animate-item'+(index+1)"
v-for="(item, index_item) in posts" style="height:100%;max-height:100%;" 
data-animation="animate__fadeInRightBig"
v-if="item.active==true"
:data-row="index" v-show="index_item==view_index">
<!--<router-link to="/item" replace></router-link>-->
<!--hosturl+'/media/sm'+img.src-->
<div style="display:flex; flex-wrap:wrap;width:100%;height:100%;align-content:center;justify-content:center;">
<div class="listbox-home-imgbox" v-for="(img, index_img) in item.imgaes" v-if="img.home"
 style="position:relative;height:100%;">
<div class="listbox-home-imgkm" v-on:click="dis_like(item.guid)" 
style="position:absolute;top:0px;right:0px;display: flex;flex-wrap:wrap;
height:38px;max-height:38px;width:auto;justify-content: center; align-content:center; z-index:1;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);
border-radius: 4px;height:36px;max-height:36px;width:auto;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;
justify-content: center;align-content:center;padding-left:7px;padding-right:7px;">
<span class="mdi mdi-12px mdi-heart-outline" style="display: flex;flex-wrap:wrap;color:#eee;line-height:36px;align-content:center;">
</span>
<span style="line-height:36px;padding-right:3px;">
{{view_index}}
</span>
</div>
</div>
<div class="listbox-home-imgcat" v-on:click="$router.push('/'+item.guid),setitem_index(index)" 
style="background: rgba(0,0,0,.4);position:absolute;bottom:0px;right:0px; display: flex;flex-wrap:wrap;
width:100%;justify-content: center;align-content:center;z-index:1;">
<div class="listbox-home-data" style="display: flex;flex-wrap:wrap;height:85px;align-content:center;">
<div class="listbox-home-data-name" style="width:94%;height:36px;justify-content:right;
font-size:15px;font-weight:400;align-items:flex-start;color:#f7f6f6;white-space:nowrap;">
<span>
{{item.title}}
</span>
<span class="mdi mdi-24px mdi-chevron-left" style="color:#eee;line-height:32px;"></span>
<span class="listbox-home-data-mater" style="margin-right:auto;">
{{item.price}}
<span v-if="!item.price.toString().includes('سوم')" style="font-size:14px;padding-left:5px;padding-right:5px;">{{currency}}</span>
</span>
</div>
<div class="listbox-home-data-address" style="width:94%;justify-content:right;opacity:0.8;
align-items:center;font-size:13px;font-weight:400;color:#f7f6f6;white-space:nowrap;">
<span class="mdi mdi-18px mdi-map-marker-multiple" style="color:#f7f6f6;line-height:18px;"></span>
<span style="padding-right:3px;">
{{item.address}} 
</span>
<span class="listbox-home-data-mater" style="margin-right:auto;">
450 م <span class="mdi mdi-18px mdi-walk" style="color:#f7f6f6;line-height:18px;"></span>
</span>
</div>
</div>
</div>
<div class="listbox-home-img img-pan" style="user-select:none;background-color:#000;
position:relative;z-index:0;overflow:hidden;">
<div class="next-img" v-on:click.self="posts.length-1>view_index&&deltaY==0?view_index=view_index+1:view_index=index_item"  
style="position:absolute;top:0;left:0;
display: flex;flex-wrap:wrap;width:33.33%;height:100%;z-index:1;">
</div>
<div class="prev-img" v-on:click.self="view_index>0&&deltaY==0?view_index=view_index-1:view_index=index_item" 
style="position:absolute;top:0;right:0;color:#ddd;
display: flex;flex-wrap:wrap;width:33.33%;height:100%;z-index:1;">
<span>{{index_item}}</span>
</div>
<img v-on:click.self="posts.length-1>view_index&&deltaY==0?view_index=view_index+1:view_index=index_item"
 alt="يتم التحميل..." class="lazy" style="
position:relative;z-index:0;width:100%;height:100%;object-fit:cover;"
draggable="false" :data-src="img.home==true?hosturl+'/media/'+img.src:''">
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
export default {
name: 'ItemStory',
components: {},
//props: {msg: String},
data(){
return {
view_index:0,
aos:null,
item_like:[],
iframe:null,
width: 0,
scrollWidth: 0,
left: 0,
deltaX: 0,
deltaY: 0,
pan_up_max: 0,
pan_down_max: 0,
index: 0,
vh:0,
swaip:0,load:'show',
ref_item_box:''
}
},
methods:{
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
pan_item: function (e, val){
var el = document.getElementById(val);
//e.deltaX // e.target
el.style.transform = "translateY("+e.deltaY+"px)";
//$('#'+val).css("transform","translateY(" + e.deltaY + "px)")
if(e.deltaY>200||e.deltaY<-200){
$('.pop-up-box.item-story').css("opacity","0.8");
}
else{
$('.pop-up-box.item-story').css("opacity","1");
}
this.deltaY=e.deltaY;
},
pan_item_end: function (e, val){
//e.deltaX // e.target
var deltaNowY = 0;
deltaNowY = e.deltaY;
var el = document.getElementById(val);
//$(el).hide();
if (deltaNowY > this.pan_down_max || deltaNowY < this.pan_up_max){
this.set_pop_story('');
el.style.transform = "translateY(0px)";
$('.pop-up-box.item-story').css("opacity","1");
}
else{
el.style.transform = "translateY(0px)";
$('.pop-up-box.item-story').css("opacity","1");
this.deltaY=0;
//$('#'+val).css("transform","translateY(0px)")
//$('.pop-up-box.item-story').css("transform","translateY(0px)")
}
},
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
set_pan_height : function () {
if(window.innerHeight/2<=200){
this.pan_up_max=-200;
this.pan_down_max=200;
}
else if(window.innerHeight/2<=300){
this.pan_up_max=-300;
this.pan_down_max=300;
}
else if(window.innerHeight/2<=400){
this.pan_up_max=-400;
this.pan_down_max=400;
}
else if(window.innerHeight/2>400){
this.pan_up_max=-400;
this.pan_down_max=400;
}
else{
this.pan_up_max=-150;
this.pan_down_max=150;
}
},
set_story_view:function () {
if(this.story_guid!=''){
var index = this.posts.findIndex(x => x.guid == this.story_guid);
this.view_index=index;
}
},
dis_like : function (guid) {
var i = 0;
this.likes.filter(item =>{
if(item.like==guid){
this.likes.splice(i,1);
}
i++;
});
this.lazy_update();
$('img.lazy').removeAttr('data-ll-status');
this.$cookies.set('likes',this.likes);
},
set_Like:function (guid){
var ck =this.likes.filter(item =>{
return item.like==guid;   
});
if(ck.length<1)
this.$store.commit("push_likes",guid);
this.$cookies.set('likes',this.likes);
},
formatPrice : function (value) {
var val = parseInt(value);
if (typeof val !== "number") {
return value;
}
if(val>1000&&val<900999){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 0,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else if(val>1000000){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 6,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else{
return value;
}
},
lazy_update(){
this.$store.commit("lazy_update");
},
SetViewCat: function (val) {
this.$store.commit("SetViewCat", val);
},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetPreview : function (val) {
this.$store.commit("SetPreview", val);
},
setitem_index(val){
this.$store.commit("setitem_index", val);
},
setpage:function (val) {
this.$store.commit("setpage", val);
//$('.nav-icon').addClass('mdi-tag-multiple');
//$('.item-home-nbtn').removeClass('active');
//$('.item-home-nbtn:nth-child(' + (val+1) + ')').addClass('active');
//$('.item-home-nbtn:nth-child(' + (val+1) + ')').find('span').removeClass('mdi-tag-multiple').addClass('mdi-checkbox-marked');
},
visibilityChanged:function(isVisible,entry,code) {
if(isVisible){
//console.log(entry);
var animation=$("#"+entry.target.id).data('animation');
if(!entry.target.classList.contains(animation)){
//let domElement = entry.target.id;
entry.target.classList.add(animation);
}
this.show_all_info(code,3200);
}
},
show_all_info:function(code,timeout) {
$('.room-'+code).show();
setTimeout(function () {
$('.lounge-'+code).show();
}, 600);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1200);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
}
},
created() {
this.lazy_update();
window.addEventListener('scroll', this.scrollFunction);
},
destroyed(){
window.removeEventListener('scroll', this.scrollFunction);
},
mounted(){
this.lazy_update();
$('.listbox-home-box').css("height",window.innerHeight);
$('.listbox-home-box').css("max-height",window.innerHeight);
this.set_pan_height();
this.set_story_view();
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
items : state => state.items,
posts : state => state.posts,
viewCat : state => state.viewCat,
hosturl : state => state.hosturl,
currency : state => state.currency,
hide_navbar : state => state.hide_navbar,
preview_index : state => state.preview_index,
pageview:state => state.pageview,
story_guid:state => state.story_guid,
pop_story:state => state.pop_story,
preview: state => state.preview,
likes: state => state.likes,
height_navbar : state => state.height_navbar

}),
filteredList(){
var like_posts=[];
this.likes.map(litem =>{
this.posts.map(pitem =>{
if(pitem.guid==litem.like){
like_posts.push(pitem);
}
})
});
return like_posts;   
}
},
watch:{
// whenever question changes, this function will run
story_guid: function ()
{
this.set_story_view();
},
pageview: function ()
{
//this.$refs.horizontal_item.scrollToIndex(this.pageview);
}
}
}
</script>
<style scoped>
.home-item-list-box{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;
overflow-y: hidden;scrollbar-width:none;
}
.swip-item-home{
display: flex;width:100%;margin-bottom: 0px;
max-width:100%;padding:7px;
margin: 0;padding-bottom: 0px;border: none;
box-sizing: content-box;overflow-x: scroll;overflow-y: hidden;
scroll-snap-type: x mandatory;
scrollbar-width:none;
-webkit-overflow-scrolling: touch;
position:relative;
}
.listbox-home-warp{
display:flex; flex-wrap:wrap;height:fit-content;
width: 100%; position: relative;
touch-action: none;touch-action: pan-x pan-y;
position:relative;font-family: 'Noto Kufi Arabic';font-weight:400;
margin:0;justify-content: center;

}
.listbox-home-box{
display:flex; flex-wrap:wrap;width: 100%; 
/*padding-bottom:100px;*/
touch-action: pan-x pan-y; height: fit-content;
justify-content: center;
align-items: flex-start;
overflow-y: scroll; overflow-x: hidden; 
scrollbar-color: auto; scrollbar-width: none;
scroll-snap-type: mandatory;
}
.listbox-home-item{
position:relative;
display:flex;flex-wrap:wrap;
justify-content:center;text-align:center;align-items:center;
width:100%;
z-index: 0;cursor: pointer;
touch-action: none; touch-action: pan-x pan-y;
/*box-shadow: 0px 4px 4px 0px #f5f5f5;*/
overflow: hidden;
/*border-radius:5px 5px 5px 5px;*/
/*border-bottom:1px solid #ededed;*/

}
.listbox-home-imgbox{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
max-width:100%;width:100%;height:100%;
overflow: hidden;scrollbar-width: none;
}
.listbox-home-img{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
height:100%;width:100%;max-width:100%;overflow:scroll;

/*border-radius:5px 5px 5px 5px;*/
}
.listbox-home-data{
display: flex;
align-items: center;justify-content: center;
scrollbar-width: none;width:100%;max-width:94%;
overflow: hidden;z-index: 0;
}
.listbox-home-data-box{
position:relative;width: 100%;max-height:128px;
display: flex;flex-wrap: wrap;
justify-content: center; justify-items:center;
text-align:center;z-index: 0;
}
</style>
<style scoped>
.listbox-home-data-name{
display: flex;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;align-content:center;
}
.listbox-home-data-name span{
display:flex;justify-content:right;
max-width:75%;
overflow-x:scroll;overflow-y:hidden;
scrollbar-width:none!important;
}
.listbox-home-data-address{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;color:#000;
align-content:center;
}
.listbox-home-data-address span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:13px;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}

.listbox-home-data-name img{
padding-right:5px;padding-left:5px;
}

.listbox-home-data-info{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color:#999;justify-content: center;align-content:center;
}
.listbox-home-data-info div{
display:flex;flex-wrap:wrap;justify-content:center;
font-size:12px;white-space:nowrap;align-content:center;
color:#999;scrollbar-width:none;
}
.listbox-home-data-info span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#999;align-content:center;
}
.listbox-home-data-info a{
display:flex;flex-wrap:wrap;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#555;justify-content:right;align-content:center;
}
.listbox-home-data-price{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color: #000; justify-content: center;align-content:center;
}
.listbox-home-data-price span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;
color:#555;align-content:center;
margin:auto;
}
</style>
