<template>
<div class="home-footer-box-time">
<div class="home-footer-time-title">
<h4>اوقات العمل </h4>
<span class="mdi mdi-36px mdi-timelapse" style="color:#4e4e4e;margin-top:0px;"></span>
</div>
<div class="home-footer-time-item" v-if="setup[0]"  
 v-html="setup[0].time_work">
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
name: 'FooterTime',
components: {},
props: ['time_work'],
data(){
return {
     
}
},
methods: {

},
watch:{

},
computed: {
...mapState({
setup : state => state.setup,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style>
.home-footer-time-item p{
position:relative;display:flex;margin:0px;padding:0;
flex-wrap:wrap;width:100%;height:auto;line-height:normal;
font-family: "Noto Kufi Arabic", sans-serif;font-size:15px;
align-content:center;justify-content:center;text-align:center;
}
</style>
<style scoped>
.home-footer-box-time{
position:relative;display:flex;
flex-wrap:wrap;
width:100%;height:300px;
align-content:flex-start;justify-content:center;
}
.home-footer-time-item{
position:relative;display:flex;flex-wrap:wrap;
width:100%;align-content:center;justify-content:center;
margin-bottom:7px;margin-top:7px;
}
.home-footer-time-item p{
position:relative;display:flex;background:#ccc;
flex-wrap:wrap;width:100%;height:auto;font-size:13px;line-height:24px;
align-content:center;justify-content:center;text-align:center;
}
.home-footer-box-time a{
position:relative;display:flex;flex-wrap:wrap;
width:80%;align-content:center;justify-content:center;
border-radius:25px;
box-shadow: 0px 5px 5px 0px rgba(82, 63, 105, 0.05);
margin-right:auto;margin-left:auto;
border-bottom:1px solid #ddd;border-top:1px solid #ddd;
}
.home-footer-time-title{
position:relative;display:flex;
flex-wrap:wrap;padding-top:24px;
width:100%;height:auto;color:#4e4e4e;
align-content:center;justify-content:center;
}
.home-footer-box-time h4{
position:relative;display:flex;flex-wrap:wrap;padding:0;
width:100%;align-content:center;justify-content:center;
}
.home-footer-time-title{width:100%;max-width:100%;}

@media (min-width: 800px){
.home-footer-box-time{
padding-top:7px;
}
}

</style>