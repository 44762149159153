<template>
<a class="Whats-box-item" 
:href="'https://api.whatsapp.com/send/?phone=966'+
send_number(setup[0].whatsap_support)+'&text='
+text+'&type=phone_number&app_absent=0'"
target="_blank" title="تواصل معنا واتساب">
<div style="display: flex; flex-wrap: wrap;justify-content:center;align-items:center;
height:50px;width:auto;text-decoration:none;padding-right:4px;">
<span style="display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
height: 50px;width:auto;border-radius:50%;text-decoration:none;padding-left:3px;">
<img src="img/whatsapp.svg" style="display:flex;justify-content:center;
height:22px;object-fit:contain;" />
</span>
<span style="display: flex; flex-wrap: wrap;height:32px;background:#fff;
border-radius:4px;
justify-content:center;align-content:center;color:#4e4e4e;">
<span style="padding:4px;padding-right:7px;">تحتاج مساعدة ؟</span>
<strong style="padding:2px;padding-right:4px;">كلمنا واتس اب 👋</strong>
</span>
</div>
</a>
</template>
<script>
import { mapState } from 'vuex';

export default {
name: 'WhatsappRel',
components: {},
props: ['text'],
data(){
return {
}
},
methods: {
send_number:function(val){
var s=val.toString().replace('0', '').replace(/\s/g, '');
return s;
}
},
watch:{

},
computed: {
...mapState({
setup:state => state.setup,
//selctedCat : state => state.selctedCat,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.Whats-box-item{
background:#fff;
border-radius:4px;
background:none;z-index:0;position:relative;cursor:pointer;z-index:0;
display:flex;flex-wrap:wrap;height:55px;width:90%;margin:auto;text-decoration:none;
justify-content:center;align-content:center;padding-bottom:7px;
color:#4e4e4e;font-weight:500;white-space: pre-wrap;font-size:16px;text-align:center;
}
</style>
