<template>
<div class="PageHome j7app-main-com" dir="rtl">
<div class="main-home-page j7app-main-home">

</div>
<div>
</div>
</div>
</template>

<script>
import { mapState } from 'vuex';
//import TopNavbar from '../components/TopNavbar.vue'


export default {
name: 'TestMode2',
components: {
},
data() {
return {
code_view:'',
prog:0
}
},
methods: {
emit_load_state:function(event){ 
this.prog = event;
}
},
mounted() {
this.$store.commit("set_page_title",this.cust_name);
},
updated() {
//alert('updated');
},
computed: {
...mapState({
pageview : state => state.pageview,
view_Nav : state => state.view_Nav,
cust_name : state => state.cust_name
})
}
}
</script>
<style scoped>
/*
background:linear-gradient(45deg, #f5f5f5,#e6e6e6);
background:#f7f6f6;
*/
.j7app-main-com{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
}
.j7app-header-home{
position: fixed;z-index:1;
display: flex; flex-wrap: wrap;
width: 100%;margin-right:auto;margin-left:auto;max-height:158px;
align-items: flex-start;touch-action: pan-x;
border-radius:0; border: 0px solid transparent;
background:#f7f6f6;
padding:0;
}
.j7app-main-home{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
background:#fff;
}

</style>