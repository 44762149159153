<template>
<div class="soldout-bg" style="">
<div style="display:flex;flex-wrap:wrap;width:100%;height:100%;
align-content:center;align-items:center;border:1px solid rgba(230, 41, 71,0.6);
background-color:rgba(230, 41, 71,0.75);
justify-content:center;font-family: 'Noto Kufi Arabic', sans-serif;">
<span style="color:#fff;font-size:18px;">تم البيع !</span>
</div>
</div>
</template>
<script>
export default {
name: 'SoldOutV1',
components: {},
//props: ['text'],
data(){
return {
}
},
methods: {
},
watch:{

}
}
</script>
<style scoped>
.soldout-bg{
position:absolute;top:45px;
display:flex;flex-wrap:wrap;width:55%;height:55px;
border:1px solid #bd3535;
background-color:rgba(230, 41, 71,0.10);
transform: rotate(0deg);
z-index:1;padding:3px;align-content:center;
align-items:center;justify-content:center;
}
@media (min-width:200px) and (max-width: 575px){
.soldout-bg{
position:absolute;top:52%;
width:175px;
}
}
@media (min-width:575px) and (max-width: 1024px){
.soldout-bg{
position:absolute;top:48%;
width:175px;
}
}
@media (min-width:1024px) and (max-width: 1200px){
.soldout-bg{
position:absolute;top:52%;
width:175px;
}
}
@media (min-width:1200px){
.soldout-bg{
position:absolute;top:53%;
width:175px;
}
}
</style>