<template>
<div class="home_vide_player" 
style="backface-visibility: hidden;display:flex;flex-wrap:wrap;align-content:center;
justify-content:center;position:relative;height:100%;width:100%;">
<video preload="none" :src="hosturl+'/media/'+item.src" :type="item.type"
 v-on:click.self="play_vid(index)" :id="'playvid'+index"
 :poster="hosturl+'/media/'+item.poster" class="videof" muted defaultMuted playsinline style="
height:100%;width:100%;object-fit: cover;border-radius:8px;text-align:center;
backface-visibility: hidden!important;position:relative;z-index:-100;">
<!--<source  />-->
Your browser does not support the video tag.
</video>
<div class="home_vide_player_btn" style="position:absolute;z-index:10;
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
height:100%;width:100%;top:0px;right:0px;"
v-on:click="play_vid(index)">
<span :id="'playvid-btn-on-'+index" class="mdi mdi-48px mdi-play-circle-outline" style="color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
<span :id="'playvid-btn-off-'+index" class="mdi mdi-48px mdi-pause"
 style="display:none;color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
</div>
<div class="home_vide_player_mute" style="position:absolute;z-index:10;
display:flex;flex-wrap:wrap;align-content:center;justify-content:right;
height:55px;width:55px;padding-right:14px;padding-left:14px;
top:55px;right:0px;"
v-on:click="play_vid_mute(index)">
<span :id="'playvid-volume-on-'+index" class="mdi mdi-24px mdi-volume-high"
 style="display:none;color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
<span :id="'playvid-volume-off-'+index" class="mdi mdi-24px mdi-volume-off" style="color:#f7f6f6;line-height:28px;opacity:0.8;"></span>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
//import 'swiper/css/swiper.min.css';
export default {
name: 'ItemStoryVideos',
components: {},
props: ['item','index'],
data(){
return {

}
},
mounted(){

},
methods:{
play_vid_change:function(val){ 
$('video').each(function() {
$(this).get(0).pause();
});
var player = document.getElementById('playvid'+val);
if(player!=null){
if (player.paused){
player.play();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
}
},
play_vid:function(val){ 
var player = document.getElementById('playvid'+val);
$('video').each(function() {
if($(this).attr("id")!='playvid'+val){
$(this).get(0).pause();
}
});
if (player.paused){
player.play();
//$('#playvid'+val).data('p-state','play');
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
else{
player.pause();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).show();
}
},
play_vid_mute:function(val){ 
var player = document.getElementById('playvid'+val);
if (player.muted){
player.muted = false;
$('#playvid-volume-on-'+val).show();
$('#playvid-volume-off-'+val).hide();
}
else{
player.muted = true;
$('#playvid-volume-on-'+val).hide();
$('#playvid-volume-off-'+val).show();
}
},
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
dis_like : function (guid) {
var i = 0;
this.likes.filter(item =>{
if(item.like==guid){
this.likes.splice(i,1);
}
i++;
});
this.lazy_update();
$('img.lazy').removeAttr('data-ll-status');
this.$cookies.set('likes',this.likes);
},
set_Like:function (guid){
var ck =this.likes.filter(item =>{
return item.like==guid;   
});
if(ck.length<1)
this.$store.commit("push_likes",guid);
this.$cookies.set('likes',this.likes);
},
lazy_update(){
this.$store.commit("lazy_update");
},
show_all_info:function(code,timeout) {
setTimeout(function () {
$('.room-'+code).show();
}, 800);
setTimeout(function () {
$('.lounge-'+code).show();
}, 1200);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1600);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
$('.story-box-view img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
}
},
created() {
},
destroyed(){
},
updated() {
},
computed: {
...mapState({
//db : state => state.db,
hosturl : state => state.hosturl,
pop_story:state => state.pop_story,
likes: state => state.likes
}),
filteredList(){
var like_posts=[];
this.likes.map(litem =>{
this.posts.map(pitem =>{
if(pitem.guid==litem.like){
like_posts.push(pitem);
}
})
});
return like_posts;   
}
},
watch:{

}
}
</script>

