<template>
<div class="nfooter-box">
<div class="nfooter-warp animate__animated animate__bounceInUp">
<div v-opacity class="nfooter-btn" v-on:click="hand_story('')">
<!--<span class="mdi mdi-24px mdi-widgets-outline nfooter-icon active"></span>-->
<span class="mdi mdi-24px mdi-play-box-outline nfooter-icon active"></span>
<span class="nfooter-span">ستوري</span>
</div>
<div v-opacity class="nfooter-btn BtnViewOrder" v-on:click="popup='time'">
<span class="mdi mdi-24px mdi-book-outline nfooter-icon"></span>
<span class="nfooter-span">اوقات العمل</span>
</div>
<div v-opacity class="nfooter-btn BtnViewCart" v-on:click="popup='contact'">
<span class="mdi mdi-24px mdi-phone-in-talk nfooter-icon"></span>
<span class="nfooter-span">اتصل بنا</span>
</div>
<div v-opacity class="nfooter-btn" v-on:click="popup='maps'">
<span class="mdi mdi-24px mdi-map-marker-radius nfooter-icon"></span>
<span class="nfooter-span">الموقع</span>
</div>
</div>
<div v-if="setup.length>0" v-show="popup!=''" class="pop-up-box" v-on:click.self="popup=''">
<div class="pop-up-com">
<div class="pop-up-com-item animate__animated animate__bounce" v-show="popup=='contact'">
<FooterContact></FooterContact>
</div>
<div class="pop-up-com-item animate__animated animate__bounce" v-show="popup=='time'">
<FooterTime></FooterTime>
</div>
<div class="pop-up-com-item animate__animated animate__bounce" v-show="popup=='maps'">
<FooteMaps></FooteMaps>
</div>
</div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import FooterSocial from '../components/FooterSocial.vue';
import FooterInfo from '../components/FooterInfo.vue';
import FooterContact from '../components/FooterContact.vue';
import FooteMaps from '../components/FooteMaps.vue';
import FooterTime from '../components/FooterTime.vue';

export default {
name: 'BottomNav',
components: {FooterInfo,FooterSocial,FooterContact,FooteMaps,FooterTime},
//props: {msg: String},
data(){
return {
popup:''    
}
},
methods: {
hand_story:function (guid){
//this.set_story_guid(guid);
this.set_pop_story('story');
//animate-item
},
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
btn_show:function (val) {
$(val).hide();
$(val).show();
$(val).removeClass("animate__animated animate__fadeOutLeft animate__faster");
$(val).addClass("animate__animated animate__fadeInLeft animate__faster");
},
btn_back:function (val) {
$(val).hide();
$(val).show();
$(val).removeClass("animate__animated animate__fadeInLeft animate__faster");
$(val).addClass("animate__animated animate__fadeOutLeft animate__faster");
},
SetViewCat: function (val) {
this.$store.commit("SetViewCat", val);
},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetHideNav: function (val) {
this.$store.commit("SetHideNav", val);
},
setpage: function (val) {
this.$store.commit("setpage", val);
},
CountOrderTotal: function (val) {
this.$store.commit("CountOrderTotal", val);
},
SetPreview : function (val) {
this.$store.commit("SetPreview", val);
}
},
watch:{
// whenever question changes, this function will run
pageview: function () {
//this.$refs.horizontal.scrollToIndex(this.pageview + 1);
}
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
viewCat : state => state.viewCat,
setup : state => state.setup,
hide_navbar: state => state.hide_navbar,
pageview : state => state.pageview,
})
}
}
</script>
<style scoped>
.nfooter-box{background:#fff;position:fixed;bottom:0;z-index:10; display: flex;flex-wrap:wrap; width:100%; height: 65px; max-height: 65px;font-family: 'Cairo', sans-serif;font-weight:400;border: 0px solid transparent;box-shadow: 0px 5px 5px 0px rgba(82, 63, 105, 0.05); background-position: center;touch-action: none;overflow:hidden;cursor:pointer;justify-content: center;align-content:center;  }
.nfooter-warp{position:relative;display: flex; width: 100%; height: 65px; max-height: 65px;max-width:800px; justify-content: center;align-content:center;background:#fff;margin-right:auto;margin-left:auto;}
.nfooter-btn{position:relative;display:flex;flex-wrap:wrap;background:#fff;touch-action:none;width:24%;height:100%;justify-content:center;align-content:center;}
.nfooter-btn:hover{opacity:0.8;}
.nfooter-btn.active{background:#FDF7F7;}
.nfooter-span{position:relative; display:flex;width:100%; font-size:13px; color:#4e4e4e;max-height:24px;justify-content:center;align-content:center;}
.nfooter-span.active{color:#4e537d;}
.nfooter-icon{display:flex;width:100%;color:#4e4e4e;height:24px;opacity:0.95;line-height:24px;justify-content:center;align-content:center;}
.nfooter-icon.active{color:#4e4e4e;}
.pop-up-box{z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.5);
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);
display:flex;flex-wrap:wrap;width:100%;min-height: 100vh;max-height:100vh;overflow:hidden;
min-height: -webkit-fill-available; user-select: none;margin-right:auto;margin-left:auto;
align-content:center;justify-content:center;
}
.pop-up-com{z-index:1;position:relative;
display:flex;flex-wrap:wrap;width:100%;height:100%;justify-content:center;
margin-right:auto;margin-left:auto;color:#000;align-content:center;
padding:14px;
}
.pop-up-com-item{
z-index:1;position:relative;background-color:#fff;border-radius:14px;
display:flex;flex-wrap:wrap;width:auto;height:100%;justify-content:center;
margin-right:auto;margin-left:auto;color:#000;align-content:center;
padding-bottom: 24px;
}
.home-footer-box-time{height:100%;width:auto;max-width:320px;}
.home-footer-contact-box{height:100%;width:auto;max-width:320px;}
.home-footer-maps-box{height:100%;width:auto;max-width:375px;}
.home-footer-maps-item-span a{padding-top: 24px!important;}
</style>
<style scoped>
@media (min-width: 600px) and (max-width: 800px) {
.nfooter-box {
position: fixed;
max-width: 100% !important;
}
}
@media (min-width: 1024px){
.nfooter-box{display:none!important;}
}
</style>