import { render, staticRenderFns } from "./ViewStory.vue?vue&type=template&id=5ff8fffa&scoped=true&"
import script from "./ViewStory.vue?vue&type=script&lang=js&"
export * from "./ViewStory.vue?vue&type=script&lang=js&"
import style0 from "./ViewStory.vue?vue&type=style&index=0&id=5ff8fffa&prod&lang=css&"
import style1 from "./ViewStory.vue?vue&type=style&index=1&id=5ff8fffa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff8fffa",
  null
  
)

export default component.exports