<template>
<div class="top_btn j7app-navbar">
<div class="swiper_nav j7app-navbar-swbox">
<vue-horizontal class="horizontal" ref="horizontal_bottom" snap="center" :button="false" @scroll-debounce="onScrollDebounce">
<!--item-home-nbtn--> 
<div class="j7app-navbar-item" :class="nav_index==index ? 'active':''"
 v-opacity="0.6" v-for="(item, index) in cats"
 v-on:click="nav_index=index,setpage(index),SetViewCat(item.link)">
<a class="nav-icon_titel j7app-navbar-item-name">
<img src="img/nj.png" />
<span style="padding-right:7px;">{{item.Aname}}</span>
</a>
</div>
</vue-horizontal>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import VueHorizontal from 'vue-horizontal';
export default {
name: 'NavbarBottom',
components: {VueHorizontal},
//props: {msg: String},
data(){
return {
width: 0,
index: 0,
pages: 0,
nav_index:0     
}
},
methods: {
onScrollDebounce({scrollWidth, width, left}) {
this.width = width
this.index = Math.round(left / width)
this.pages = Math.round(scrollWidth / width)
},
onPageClick(i) {
if (i === this.pages - 1) {
this.$refs.horizontal_bottom.scrollToIndex(this.items.length - 1)
} else {
this.$refs.horizontal_bottom.scrollToLeft(i * this.width)
}
},
Navhorizontal:function (val){
//alert(0);
//this.$store.commit("Navhorizontal", val);
},
SetViewCat: function (val) {
this.$store.commit("SetViewCat", val);

},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetHideNav: function (val) {
this.$store.commit("SetHideNav", val);
},
setpage: function (val) {
this.$store.commit("setpage", val);
this.$refs.horizontal_bottom.scrollToIndex(val + 1)
//var fotter=$('#animate-footer-maps').offset().top;
//$('.j7app-containr').scrollTop(1);
//$('.j7app-containr').animate({scrollTop: 1}, 800, function(){});
}
},
watch:{
// whenever question changes, this function will run
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
viewCat : state => state.viewCat,
view_Nav : state => state.view_Nav,
hide_navbar: state => state.hide_navbar,
pageview : state => state.pageview
})
}
}
</script>

<style scoped>
.j7app-navbar{
/*
background:#f7f6f6;
background-color: rgba(18,19,20,.1);
*/
position: relative;z-index: 1;
display: flex;width: 100%;height: 55px;
max-height: 55px;align-items: center;
background-color:#fff;
color: #272727;
touch-action: pan-x pan-y;
margin-right:auto;margin-left:auto;
}
.j7app-navbar-swbox{
position: relative;
height: 55px;max-height: 55px;z-index: 1; 
overflow: hidden;
touch-action: pan-x !important;
margin-right: auto;margin-left: auto;  
padding-right: 24px;padding-left: 24px 
}
.j7app-navbar-item{
display: flex; flex-wrap: wrap;height: 55px; min-width: 92px;
font-size: 13px;font-weight: 400;text-align: center;
align-content: center;justify-content: center;
background:#fff;color:#1a1a1a;
border:1px solid #f1f3f4;border-radius:5px;
/*border-radius:50%;*/
user-select: none;cursor: pointer;
margin-left: 12px !important;
transition-property: transform;
touch-action: pan-x !important;
}
.j7app-navbar-item-name{
display: flex; width: 100%;max-height:55px;font-size:13px; 
align-items: center; justify-content: center;color:#777;
font-family: 'Cairo', sans-serif;font-weight:400;
}
.j7app-navbar-item img{
display:flex; justify-content:center;
max-width:55px;height:10px;
border-radius:50%;background:#fff;
}
.j7app-navbar-item.active {
border-top:1px solid #d5d5d5;
border-bottom:1px solid #eee;
border-right:1px solid #d5d5d5;
border-left:1px solid #eee;
color:#1a1a1a;
}
.j7app-navbar-item:focus {
border-top:1px solid #d5d5d5;
border-bottom:1px solid #f4cabb;
border-right:1px solid #d5d5d5;
border-left:1px solid #f4cabb;
color: #1a1a1a;
}
.j7app-navbar-item:hover {
border-top:1px solid #d5d5d5;
border-bottom:1px solid #eee;
border-right:1px solid #d5d5d5;
border-left:1px solid #eee;
color: #1a1a1a;
}
.j7app-navbar-item.rippel {
background: linear-gradient(45deg, #faf9f8,#fff);
border-color:#ccc;
color: #1a1a1a;
}
</style>