<template>
<div class="home-item-view-story" style="display: flex; flex-wrap: wrap;
height:100%;align-content:center;justify-content:center;">
<span style="display:none;">{{guid}}</span>
<div class="j7-view-item" v-for="(item, index) in filteredList"
 style="display: flex; flex-wrap: wrap;width:100%;height:100%;align-content:center;justify-content:center;">
<ItemDetaStory :details="item.details" :features="item.features"></ItemDetaStory>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
/*import VueHorizontal from 'vue-horizontal';*/
import ItemDetaStory from './ItemDetaStory.vue';
export default {
name: 'ItemViewStory',
components:{
ItemDetaStory
},
props: ['guid'],
data(){
return {
search_val:this.guid
}
},
methods:{
lazy_update(){
this.$store.commit("lazy_update");
}
},
created() {
},
mounted(){
var vh=window.innerHeight;
$('.home-item-view-story').css("min-height",vh-24);
$('.home-item-view-story').css("height",vh-24);
$('.home-item-view-story').css("max-height",vh-24);
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed: {
...mapState({
posts : state => state.posts
}),
filteredList(){
return this.posts.filter(item =>
{
//return this.search_val.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
return item.guid==this.guid;   
});
}
},
watch:{

}
}
</script>
<style scoped>
.brk24{display:flex;flex-wrap:wrap;width:100%;min-height:24px;position:relative;}
.brk14{display:flex;flex-wrap:wrap;width:100%;min-height:14px;position:relative;}
.home-item-view{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;width:100%;
overflow-y: hidden;scrollbar-width:none;
}
</style>
