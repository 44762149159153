<template>
<div class="menu-item-box listbox-home-warp" style="position:relative;">
<!--  id="v-pan-box" v-pan="(e) => pan_item(e,'v-pan-box')"
v-panend="(e) => pan_item_end(e,'v-pan-box')" -->
<div id="v-pan-box" v-panend="(e) => pan_item_end(e,'v-pan-box')"
class="animate__animated animate__fadeIn listbox-home-box story-box-view"
style="background-color:none;min-width:320px;max-width:1024px;">
<div class="post-number" v-if="page_index>0" style="display:flex;flex-wrap:wrap;top:45%;width:auto;font-size:21px;color:#fff;height:45px;
background:rgba(0,0,0,0.25);height:45px;width:45px;border-radius:50%;
justify-content:center;align-content:center;
position:absolute;z-index:255;">{{page_index}}</div>
 <!--@slideChange="show_all_info($refs.mySwiper_Strory.$swiper.activeIndex,3200),show_data='',play_vid_change($refs.mySwiper_Strory.$swiper.activeIndex)"-->
<div class="listbox-home-item animate__animated"
v-for="(item, index_item) in filteredPost"
:id="'animate-item'+index_item"
 style="touch-action: pan-x!important;height:100%;max-height:100%;position:relative;z-index:0;" 
data-animation="animate__fadeInRightBig"
v-if="item.active==true"
:data-row="index_item">
<div style="display:none;flex-wrap:wrap;position:absolute;z-index:1100;font-family:sans-serif;
top:25px;width:100px;height:auto;justify-content:center;background:#555;">
<span style="color:#ddd;display:flex;flex-wrap:wrap;justify-content:center;height:55px;width:100%;">
{{'animate-item'+index_item}}
</span>
<span style="color:#ddd;display:flex;flex-wrap:wrap;justify-content:center;height:55px;width:100%;">
index : {{index_item}}
</span>
<span style="color:#ddd;display:flex;flex-wrap:wrap;justify-content:center;height:55px;width:100%;">
 page: {{page_index}} </span>
</div>
<swiper :options="swiperImgOption" style="display:flex; flex-wrap:wrap;
width:100%;height:100%;align-content:center;justify-content:center;position:relative;z-index:0;">
<swiper-slide class="listbox-home-imgbox" v-for="(img, index_img) in orderBy(item.imgaes, 'home',-1)"
 style="position:relative;height:100%;touch-action: pan-y!important;position:relative;z-index:0;">
<div class="listbox-home-img img-pan" style="user-select:none;
position:relative;z-index:0;overflow:hidden;">
<!--v-if="img.type&&img.type.includes('video')"--> 
<ItemStoryVideos 
v-if="img.type&&img.type.includes('video')" :item="img" :index="index_item">
</ItemStoryVideos>
<img v-on:click.self=""
 alt="يتم التحميل..." class="lazy" style="background-image:url('/img/loadbg1.gif');
background-position:center;background-repeat:no-repeat;background-size:75px;
position:relative;z-index:0;width:100%;height:100%;object-fit:cover;
touch-action: pan-x pan-y!important;"
draggable="false" :data-src="hosturl+'/media/'+img.src">
</div>
</swiper-slide>
<div class="swiper-pagination" slot="pagination"
style="bottom:0px!important;margin-right:auto!important;background: rgba(0,0,0,.3);
height:32px;display:flex;flex-wrap:wrap;align-content:center;justify-content:center;">
</div>
</swiper>
<ItemStoryTopBar :item="item"></ItemStoryTopBar>
<ItemStoryInfoBar :item="item" :index="index_item"></ItemStoryInfoBar>
<ItemStoryBottomBar :item="item" :index="index_item"></ItemStoryBottomBar>
<div class="swip-home-prv" v-on:click="prv_item" v-show="page_index>0"
style="background:none;position:absolute;top:5px;display: flex;flex-wrap:wrap;
width:50%;justify-content: center;align-content:center;z-index:1;line-height:60px;">
<div style="display: flex;flex-wrap:wrap;height:60px;justify-content: center;align-content:center;margin:auto;">
<span class="mdi mdi-36px mdi-chevron-up" style="justify-content: center;
background: rgba(0,0,0,.2);color:rgba(255,255,255,.8);display: flex;flex-wrap:wrap;height:45px;width:45px;border-radius:100%;align-content:center;"></span>
</div>
</div>
<div class="swip-home-next" v-on:click="next_item" 
style="background:none;position:absolute;bottom:105px;display: flex;flex-wrap:wrap;
width:100%;justify-content: center;align-content:center;z-index:1;line-height:75px;">
<div style="display: flex;flex-wrap:wrap;height:100px;align-content:center;justify-content: center;">
<span class="mdi mdi-36px mdi-chevron-down"
 style="background: rgba(0,0,0,.2);color:rgba(255,255,255,.8);display: flex;
flex-wrap:wrap;height:45px;width:45px;border-radius:100%;align-content:center;justify-content: center;"></span>
</div>
</div>
</div>
</div>

</div>
</template>
<script>
import {mapState} from 'vuex';
import ItemStoryTopBar from './ItemStoryTopBar.vue'
import ItemStoryInfoBar from './ItemStoryInfoBar.vue'
import ItemStoryBottomBar from './ItemStoryBottomBar.vue'
import ItemStoryVideos from './ItemStoryVideos.vue'
import Vue2Filters from 'vue2-filters'
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
//import 'swiper/css/swiper.min.css';
export default {
name: 'ItemStory',
components: {
Swiper,SwiperSlide,
ItemStoryTopBar,ItemStoryInfoBar,
ItemStoryBottomBar,ItemStoryVideos
},
mixins: [Vue2Filters.mixin],
//props: {msg: String},
data(){
return {
height:0,
vh:0,
page:0,
scroll:0,
page_index:0,
guid_view:'',
show_data:'',
swiperOption:{
grabCursor: true,
allowTouchMove: true,
slidesPerView: 'auto',
//freeMode: true,
watchSlidesVisibility: true,
//watchSlidesProgress: true,
//mousewheelControl: true,
//keyboardControl: true,
//slidesPerView: 1,
//grabCursor: true,
autoHeight: false,
breakpoints: {
0: {
direction: 'vertical',
},
},
spaceBetween: 0,
direction: 'vertical',
//allowTouchMove: true,
pagination: {
el: '.swiper-pagination_story',
clickable: true
},
//autoplay:{
//delay:10000,
//disableOnInteraction: false
//},
navigation: {
nextEl: ".swiper-button-next",
prevEl: ".swiper-button-prev"
}
},
swiperImgOption:{
slidesPerView: 'auto',
//zoom: true,
autoHeight: false,
spaceBetween: 0,
//direction: 'vertical',
pagination: {
el: '.swiper-pagination',
clickable: true
},
navigation: {
nextEl: ".swiper-button-next",
prevEl: ".swiper-button-prev"
}
},
view_index:0,
aos:null,
item_like:[],
iframe:null,
width: 0,
scrollWidth: 0,
left: 0,
deltaX: 0,
deltaY: 0,
pan_up_max: 0,
pan_down_max: 0,
index: 0,
vh:0,
swaip:0,load:'show',
ref_item_box:''
}
},
mounted(){
this.lazy_update();
var vh=window.innerHeight;
//v-pan-box
$('.story-box-view').css("height",vh);
$('.story-box-view').css("max-height",vh);
$('.story-box-view .listbox-home-item').css("height",vh);
$('.story-box-view .listbox-home-item').css("max-height",vh);
$('.Bottom-Nav-Story').css("min-height",vh);
$('.Bottom-Nav-Story').css("height",vh);
$('.Bottom-Nav-Story').css("max-height",vh);
this.set_pan_height();
this.set_story_view();
this.page=this.filteredPost.length-1;
//this.$refs.mySwiper_Strory.$swiper.slideTo(2, 1000, false)
},
methods:{
play_vid_change:function(val){ 
$('video').each(function() {
$(this).get(0).pause();
});
var player = document.getElementById('playvid'+val);
if(player!=null){
if (player.paused){
player.play();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
}
},
play_vid:function(val){ 
var player = document.getElementById('playvid'+val);
$('video').each(function() {
if($(this).attr("id")!='playvid'+val){
$(this).get(0).pause();
}
});
if (player.paused){
player.play();
//$('#playvid'+val).data('p-state','play');
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
else{
player.pause();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).show();
}
},
play_vid_mute:function(val){ 
var player = document.getElementById('playvid'+val);
if (player.muted){
player.muted = false;
$('#playvid-volume-on-'+val).show();
$('#playvid-volume-off-'+val).hide();
}
else{
player.muted = true;
$('#playvid-volume-on-'+val).hide();
$('#playvid-volume-off-'+val).show();
}
},
orderImg: function (val) {
var img=[];
img=val.sort((a, b) => a.home - b.home);
return img;
},
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
pan_item: function (e, val){
$(val).css("-webkit-transform", "translateY(" + e.deltaY + "px)");
$(val).css("transform", "translateY(" + e.deltaY + "px)");
//e.deltaY
},
next_item: function (){
if(this.page>this.page_index){
this.page_index=this.page_index+1;
var vh=window.innerHeight;
this.scroll=(this.page_index*vh);
$('.story-box-view').animate({scrollTop: this.scroll}, 300, function(){});
this.play_vid_change(this.page_index);
$('.post-number').fadeIn(200);
setTimeout(function (){
$('.post-number').fadeOut(400);
}, 400);
}
else{this.page_index=this.page;}
},
prv_item: function (){
if(this.page_index>0){
this.page_index=this.page_index-1;
var vh=window.innerHeight;
this.scroll=(this.page_index*vh);
$('.story-box-view').animate({scrollTop: this.scroll}, 300, function(){});
this.play_vid_change(this.page_index);
$('.post-number').fadeIn(200);
setTimeout(function (){
$('.post-number').fadeOut(400);
}, 400);
}
},
pan_item_end: function (e, val){
//e.deltaX // e.target
var el = document.getElementById(val);
this.height=el.scrollHeight;
var vh=window.innerHeight;
var deltaNowY = 0;
deltaNowY = e.deltaY;
if (deltaNowY > 32&&e.offsetDirection==16){
//$('.story-box-view').css("top",vh+"px");
if(this.page_index>0){
this.page_index=this.page_index-1;
}
this.scroll=(this.page_index*vh);
//$('.story-box-view').scrollTop(this.scroll);
$('.story-box-view').animate({scrollTop: this.scroll}, 300, function(){});
this.play_vid_change(this.page_index);
this.show_all_info(this.page_index,3200);
//this.$refs.mySwiper_Strory.$swiper.slidePrev()
}
else if (deltaNowY < -32&&e.offsetDirection==8){
if(this.page>this.page_index){
this.page_index=this.page_index+1;
}
this.scroll=(this.page_index*vh);
//$('.story-box-view').scrollTop(this.scroll);
$('.story-box-view').animate({scrollTop: this.scroll}, 300, function(){});
this.play_vid_change(this.page_index);
this.show_all_info(this.page_index,3200);
//this.$refs.mySwiper_Strory.$swiper.slideNext()
}
else{
el.style.transform = "translateY(0px)";
$('.pop-up-box.item-story').css("opacity","1");
this.deltaY=0;
//$('#'+val).css("transform","translateY(0px)")
//$('.pop-up-box.item-story').css("transform","translateY(0px)")
}
},
set_pan_height:function () {
if(window.innerHeight/2<=200){
this.pan_up_max=-200;
this.pan_down_max=200;
}
else if(window.innerHeight/2<=300){
this.pan_up_max=-300;
this.pan_down_max=300;
}
else if(window.innerHeight/2<=400){
this.pan_up_max=-400;
this.pan_down_max=400;
}
else if(window.innerHeight/2>400){
this.pan_up_max=-400;
this.pan_down_max=400;
}
else{
this.pan_up_max=-150;
this.pan_down_max=150;
}
},
set_story_view:function () {
if(this.story_guid!=''){
var index = this.posts.findIndex(x => x.guid == this.story_guid);
if(index>-1){
this.$refs.mySwiper_Strory.$swiper.slideTo(index, 1000, false);
}
this.view_index=index;
}
},
lazy_update(){
this.$store.commit("lazy_update");
},
visibilityChanged:function(isVisible,entry,code) {
if(isVisible){
console.log(entry.isIntersecting);
//var animation=$("#"+entry.target.id).data('animation');
//if(!entry.target.classList.contains(animation)){
////let domElement = entry.target.id;
//entry.target.classList.add(animation);
//}
this.show_all_info(code,3200);
}
},
show_all_info:function(code,timeout) {
//$('.room-'+code).fadeOut();
//$('.lounge-'+code).fadeOut();
//$('.bathroom-'+code).fadeOut();
setTimeout(function () {
$('.room-'+code).show();
}, 800);
setTimeout(function () {
$('.lounge-'+code).show();
}, 1200);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1600);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
$('.story-box-view img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
}
},
created() {
this.lazy_update();
},
destroyed(){
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
items : state => state.items,
posts : state => state.posts,
viewCat : state => state.viewCat,
hosturl : state => state.hosturl,
currency : state => state.currency,
hide_navbar : state => state.hide_navbar,
preview_index : state => state.preview_index,
pageview:state => state.pageview,
story_guid:state => state.story_guid,
pop_story:state => state.pop_story,
preview: state => state.preview,
likes: state => state.likes,
height_navbar : state => state.height_navbar

}),
filteredPost(){
return this.posts.filter(item =>{
return item.active&&item.available==true;
}).sort((a, b) => b.code - a.code);
},
filteredList(){
var like_posts=[];
this.likes.map(litem =>{
this.posts.map(pitem =>{
if(pitem.guid==litem.like){
like_posts.push(pitem);
}
})
});
return like_posts;   
}
},
watch:{
// whenever question changes, this function will run
story_guid: function ()
{
this.set_story_view();
},
pageview: function ()
{
//this.$refs.horizontal_item.scrollToIndex(this.pageview);
}
}
}
</script>
<style>
.swiper-pagination-bullet{background:#f5f5f5!important;width:10px!important;height:10px!important;}
.swiper-pagination-bullet-active{background: rgba(255,255,255,.4)!important;}
</style>
<style scoped>
.home-item-list-box{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;
overflow-y: hidden;scrollbar-width:none;
}
.swip-item-home{
display: flex;width:100%;margin-bottom: 0px;
max-width:100%;padding:7px;
margin: 0;padding-bottom: 0px;border: none;
box-sizing: content-box;overflow-x: scroll;overflow-y: hidden;
scroll-snap-type: x mandatory;
scrollbar-width:none;
-webkit-overflow-scrolling: touch;
position:relative;
}
.listbox-home-warp{
display:flex; flex-wrap:wrap;height:auto;
width: 100%; position: relative;
touch-action: pan-x pan-y;
position:relative;font-family: 'Noto Kufi Arabic';font-weight:400;
margin:0;justify-content: center;

}
.listbox-home-box{
display:flex; flex-wrap:wrap;width: 100%; 
/*padding-bottom:100px;*/
touch-action:pan-y!important;height: 100%;
justify-content: center;
align-items: flex-start;
overflow-y: scroll;overflow-x: hidden; 
scrollbar-color: auto;scrollbar-width:none;
scroll-snap-type: mandatory;
}
.listbox-home-box::-webkit-scrollbar{display: none;background: none;width:0px;height:0px; }
.listbox-home-item{
position:relative;
display:flex;flex-direction:column;flex-shrink:0;
justify-content:center;text-align:center;align-items:center;
width:100%;height: 100%;
z-index: 0;cursor: pointer;
touch-action: none; touch-action: pan-x pan-y;
/*box-shadow: 0px 4px 4px 0px #f5f5f5;*/
overflow: hidden;
/*border-radius:5px 5px 5px 5px;*/
/*border-bottom:1px solid #ededed;*/

}
.listbox-home-imgbox{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
max-width:100%;width:100%;height:100%;
overflow: hidden;scrollbar-width: none;
}
.listbox-home-img{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
height:100%;width:100%;max-width:100%;overflow:scroll;

/*border-radius:5px 5px 5px 5px;*/
}
.listbox-home-data{
display: flex;
align-items: center;justify-content: center;
scrollbar-width: none;width:100%;max-width:94%;
overflow: hidden;z-index: 0;
}
.listbox-home-data-box{
position:relative;width: 100%;max-height:128px;
display: flex;flex-wrap: wrap;
justify-content: center; justify-items:center;
text-align:center;z-index: 0;
}
</style>
<style scoped>
.listbox-home-data-name{
display: flex;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;align-content:center;
}
.listbox-home-data-name span{
display:flex;justify-content:right;
max-width:75%;
overflow-x:scroll;overflow-y:hidden;
scrollbar-width:none!important;
}
.listbox-home-data-address{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;color:#000;
align-content:center;
}
.listbox-home-data-address span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:13px;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}

.listbox-home-data-name img{
padding-right:5px;padding-left:5px;
}

.listbox-home-data-info{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color:#999;justify-content: center;align-content:center;
}
.listbox-home-data-info div{
display:flex;flex-wrap:wrap;justify-content:center;
font-size:12px;white-space:nowrap;align-content:center;
color:#999;scrollbar-width:none;
}
.listbox-home-data-info span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#999;align-content:center;
}
.listbox-home-data-info a{
display:flex;flex-wrap:wrap;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#555;justify-content:right;align-content:center;
}
.listbox-home-data-price{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color: #000; justify-content: center;align-content:center;
}
.listbox-home-data-price span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;
color:#555;align-content:center;
margin:auto;
}
</style>
