<template>
<div class="j7app" style="">
<!--background: linear-gradient(135deg,black, #343a40);-->
<div v-if="network==true" class="j7app-containr" style="">
<div class="j7app-page" >
<router-view></router-view>
<!--<ItemView v-show="preview==='ViewItem'"></ItemView>-->
<!--<Homepage></Homepage>-->
<!--<KeepAlive>
</KeepAlive>-->
<!--<component :is="preview"></component>-->
<!--<ItemView></ItemView>-->
<!--</KeepAlive>-->
</div>
</div>
<div v-if="network==false" class="page-offline" style="height:100vh;align-content: center;  ">
<div class="animate__animated animate__bounce"
  style="display: flex; flex-wrap: wrap;height:50%; max-height:75%;align-content: center;  align-items: center; justify-content: center; width: 94%; ">
<span style="color: #343a40; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; width: 100%; font-weight: 500; font-size: 24px; height: auto; padding: 12px 0 12px 0; ">لا يوجد اتصال بالانترنت.
</span>
<img draggable="false" style="pointer-events: none; z-index: 2; display: flex; max-width: 94%; max-height: calc(35vh); align-items: center; " src="css/bg/no-internet1.jpg">
<abbr style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; width: 100%; font-weight: 500; font-size: 16px; margin: 24px 0 24px 0;">افتح تطبيق "الإعدادات" وانقر على الشبكة والإنترنت <br />  أوقِف بيانات الجوّال و Wi-Fi وفعِّلها مره اخرى..  <br /> ثم اضغط على اعادة المحاولة .</abbr>
<div style="display: flex; flex-wrap: wrap; color: #fff; cursor: pointer; height: 72px; width: calc( 100% - 24px); justify-content: center; position: relative; ">
<div v-on:click="ck_network" class="item-cart-add" style=" background: linear-gradient(45deg, #fff, #f5f5f5); border: 1px solid #b1b1b1; color: #343a40; height: 60px; margin: 12px 0 12px 0; width: 94%; display: flex; cursor: pointer; align-items: center; justify-content: center; touch-action: pan-y !important; font-size: 22px; ">
إعادة المحاولة
<span class="mdi mdi-36px mdi-gesture-double-tap" style="display: flex; width: 36px; height: 36px; max-height: 36px; align-items: center; justify-content: center; "></span>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import { mapState} from "vuex";
//import Homepage from "./views/Homepage.vue"
//import ItemView from "./views/ItemView.vue"
//import ShopCart from "@/components/ShopCart.vue"
//import CartConfirm from "@/components/CartConfirm.vue"
//import OrderList from "@/components/OrderList.vue"
//import OrderView from "@/components/OrderView.vue"
//import CartList from "@/components/CartList.vue"
//import OptionList from "@/components/OptionList.vue"
export default {
name: 'App',
components: {
      
},
data: () => ({
network:window.navigator.onLine
}),
methods: {
itemswiap: function (e, val) {
//e.deltaX // e.target
var deltaNowX = 0;
var el = val;
deltaNowX = e.deltaX;
//$(el).hide();
//alert(deltaNowX);
if (deltaNowX > 200) {
			
}
else if (deltaNowX < -200) {
				
}
else {
			
}
},
dis_App_Json:function (){
if(this.network==true){
//GetAppJson --- GetApiJson
this.$store.commit("GetApiJson","J6989666-31E7-4757-8D37-E64F131KKKJB");
if(!this.$cookies.isKey("uid")){
this.$cookies.set('uid',this.$uuid.v4());
this.$cookies.set('likes',this.likes);
}
var like=this.$cookies.get("likes");
this.$store.commit("set_likes",like);
}
//this.likes=like;
},
ck_network:function (){
this.network=window.navigator.onLine;
},
scrollFunction: function (){

}
},
created() {
this.dis_App_Json();
},
destroyed(){
},
mounted() {
this.dis_App_Json();
//this.$vuetify.rtl = true;set_page_title
//this.$vuetify.lang.current = 'ar';
this.$store.commit("set_page_title",this.cust_name);
$('.app-main-page').css('display', 'block');
},
watch:{
network(){
this.dis_App_Json();
},
page_title(){
document.title=this.page_title;
}
},
computed: {
...mapState({
page_title : state => state.page_title,
likes : state => state.likes,
network_connect : state => state.network_connect,
cust_name : state => state.cust_name
})
}
};
</script>
<style scoped>
.page-offline{
display: flex;flex-wrap: wrap;width: 100%;margin-right:auto;margin-left:auto;
max-width:100%;direction:rtl;
}
.j7app{
position: relative;display: block;height: 100%;min-height: 100vh;max-height:100%;
width: 100%;touch-action: none;overflow-x:hidden;scrollbar-width:thin;
min-height: -webkit-fill-available; user-select: none;direction:rtl;
margin-right:auto;margin-left:auto;
}
.j7app-containr{
display: flex;flex-wrap: wrap;user-select: none;
 height: 100vh;overflow-y:auto;scroll-behavior:smooth;
height: -webkit-fill-available;width: 100%;margin-right:auto;margin-left:auto;
direction:ltr;
}

.j7app-page{
display: flex;flex-wrap: wrap;width: 100%;margin-right:auto;margin-left:auto;
max-width:100%;direction:rtl;
}
@media (min-width: 800px){
.j7app-page{
/*
max-width:768px;
*/
position:relative;
}
}
</style>