<template>
<div class="view-item-maps-box-pc">
<div class="view-item-maps-title">
<h4 style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:18px;">الموقع </h4>
</div>
<div class="view-item-maps-item">
<a v-if="maps.includes('maps.app.goo.gl')"
style="margin-left:auto;margin-bottom:14px;">
<iframe 
:data-src="'https://www.google.com/maps?q='+latitude+','+longitudes+'&z=17&output=embed'"
style="border:0;" allowfullscreen="" class="lazy"
 referrerpolicy="no-referrer-when-downgrade">
</iframe>
</a>
<a v-else :href="maps" target="_blank"
style="margin-left:auto;margin-bottom:14px;">
<img src="/img/maps.jpg" style="display:flex;justify-content:center;opacity:0.8;
max-height:128px;width:100%;object-fit:cover;padding:5px;border-radius:25px;" />
</a>
</div>
<div class="view-item-maps-item-span">
<span style="display:flex;flex-wrap:wrap;padding-right:14px;padding-left:14px;text-align:center;">
<a :href="maps" target="_blank" style="color:#4e4e4e;line-height:28px;
font-family: 'Noto Kufi Arabic', sans-serif;font-size:16px;">عرض الخريطة بشكل كامل</a>
</span>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
name: 'ItemMapsPc.vue',
components: {},
props: ['maps','latitude','longitudes'],
data(){
return {
     
}
},
methods: {

},
watch:{

},
computed: {
...mapState({
//selctedCat : state => state.selctedCat,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.view-item-maps-box-pc{
position:relative;display:flex;
flex-wrap:wrap;
width:100%;min-height:300px;
align-content:flex-start;justify-content:center;
}
.view-item-maps-item{
position:relative;display:flex;flex-wrap:wrap;
width:100%;align-content:center;justify-content:center;
margin-bottom:7px;margin-top:21px;
}
.view-item-maps-item a{
position:relative;display:flex;flex-wrap:wrap;
width:80%;align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
border-radius:25px;
box-shadow: 0px 5px 5px 0px rgba(82, 63, 105, 0.05);
border-bottom:1px solid #ddd;border-top:1px solid #ddd;
}
.view-item-maps-title{
position:relative;display:flex;
width:100%;height:auto;color:#777;
align-content:center;justify-content:center;
padding-top:24px;
}
.view-item-maps-box-pc h4{
position:relative;display:flex;flex-wrap:wrap;padding:0;
padding-right:10px;
width:auto;align-content:center;justify-content:center;
}
.view-item-maps-item-span{
display:flex;flex-wrap:wrap;white-space:normal;
width:100%;justify-content:center;
}

@media (min-width: 800px){
.view-item-maps-box-pc{
padding-top:7px;
}
.view-item-maps-title{width:100%;max-width:100%;}
.view-item-maps-item a{
width:94%;max-width:94%;}
.view-item-maps-box iframe{width:100%;max-width:100%;max-height:240px;}
}
</style>
<style>
.view-item-maps-box-pc iframe{
border:1px solid #ddd;
position:relative;display:flex;flex-wrap:wrap;
height:360px;
max-height:360px!important;padding:5px;
width:100%;align-content:center;justify-content:center;border-radius:25px;
opacity:0.8;
}
</style>