<template>
<div class="home-slid-box">
<div class="home-slid-box-sw">
<swiper :options="ViewSlider" dir="rtl"
style="position:relative;display:flex;align-content:center;margin-right:auto;margin-left:auto;
width:calc(100% - 100px);overflow:hidden;justify-content:center;margin-top:24px;">
<swiper-slide class="home-slid-slide" style="align-content:flex-start;
border:1px solid #ccc;
border-radius:8px;">
<div class="home-slid-arp" style="height:50px;width:100%;position:relative;
align-content:center;margin-top:18px;">
<img src="/img/pppkip.png" style="display:flex;flex-wrap:wrap;
justify-content:center;position:relative;border-radius:8px;
max-height:50px;object-fit:cover;align-content:center;" />
</div>
<div class="home-slid-arp" style="height:auto;width:98%;position:relative;
align-content:center;">
<span class="home-slid-arp" style="margin-top:18px;height:35px;font-family:Changa, sans-serif;font-size:28px;">
<span style="background:rgba(255,255,255, 0.99);padding:7px;">خدمات الباركنيق</span>
</span>
<span class="home-slid-arp" style="margin-top:7px;height:40px;font-family:Changa, sans-serif;font-size:24px;">
<span style="background:rgba(255,255,255, 0.99);padding:7px;">شهر 350 ريال</span>
<span style="position:absolute;height:3px;width:222px;border-radius:25px;
background:rgba(244,67,54, 0.6);z-index:-1;top:20px;
"></span>
</span>
<div class="home-slid-arp" style="height:auto;width:98%;position:relative;
align-content:center;">
<span class="home-slid-arp" style="width:98%;">- متوفر 24 ساعة</span>
<span class="home-slid-arp" style="width:98%;">- غسيل مجاني كل اسبوع</span>
<span class="home-slid-arp" style="width:98%;">- خصم 10% على الصيانة</span>
</div>
<span class="home-slid-arp" style="margin-top:18px;height:60px;font-family:Changa, sans-serif;font-size:28px;
background: linear-gradient(180deg,#333,#2e2e2e);color:#fff;width:85%;
border-radius:24px;">أشتراك الان..</span>
</div>
</swiper-slide>
<swiper-slide class="home-slid-slide" style="align-content:flex-start;
border:1px solid #ccc;
border-radius:8px;">
<div class="home-slid-arp" style="height:50px;width:100%;position:relative;
align-content:center;margin-top:18px;">
<img src="/img/pppkip.png" style="display:flex;flex-wrap:wrap;
justify-content:center;position:relative;border-radius:8px;
max-height:50px;object-fit:cover;align-content:center;" />
</div>
<div class="home-slid-arp" style="height:auto;width:98%;position:relative;
align-content:center;">
<span class="home-slid-arp" style="margin-top:18px;height:35px;font-family:Changa, sans-serif;font-size:28px;">
<span style="background:rgba(255,255,255, 0.99);padding:7px;">خدمات الباركنيق</span>
</span>
<span class="home-slid-arp" style="margin-top:7px;height:40px;font-family:Changa, sans-serif;font-size:24px;">
<span style="background:rgba(255,255,255, 0.99);padding:7px;">3 اشهر 900 ريال</span>
<span style="position:absolute;height:3px;width:222px;border-radius:25px;
background:rgba(244,67,54, 0.6);z-index:-1;top:20px;
"></span>
</span>
<div class="home-slid-arp" style="height:auto;width:98%;position:relative;
align-content:center;">
<span class="home-slid-arp" style="width:98%;">- متوفر 24 ساعة</span>
<span class="home-slid-arp" style="width:98%;">- غسيل مجاني كل اسبوع</span>
<span class="home-slid-arp" style="width:98%;">- خصم 10% على الصيانة</span>
</div>
<span class="home-slid-arp" style="margin-top:18px;height:60px;font-family:Changa, sans-serif;font-size:28px;
background: linear-gradient(180deg,#333,#2e2e2e);color:#fff;width:85%;
border-radius:24px;">أشتراك الان..</span>
</div>
</swiper-slide>
<swiper-slide class="home-slid-slide" style="align-content:flex-start;
border:1px solid #ccc;
border-radius:8px;">
<div class="home-slid-arp" style="height:50px;width:100%;position:relative;
align-content:center;margin-top:18px;">
<img src="/img/pppkip.png" style="display:flex;flex-wrap:wrap;
justify-content:center;position:relative;border-radius:8px;
max-height:50px;object-fit:cover;align-content:center;" />
</div>
<div class="home-slid-arp" style="height:auto;width:98%;position:relative;
align-content:center;">
<span class="home-slid-arp" style="margin-top:18px;height:35px;font-family:Changa, sans-serif;font-size:28px;">
<span style="background:rgba(255,255,255, 0.99);padding:7px;">خدمات الباركنيق</span>
</span>
<span class="home-slid-arp" style="margin-top:7px;height:40px;font-family:Changa, sans-serif;font-size:24px;">
<span style="background:rgba(255,255,255, 0.99);padding:7px;">6 اشهر 1800</span>
<span style="position:absolute;height:3px;width:222px;border-radius:25px;
background:rgba(244,67,54, 0.6);z-index:-1;top:20px;
"></span>
</span>
<div class="home-slid-arp" style="height:auto;width:98%;position:relative;
align-content:center;">
<span class="home-slid-arp" style="width:98%;">- متوفر 24 ساعة</span>
<span class="home-slid-arp" style="width:98%;">- غسيل مجاني كل اسبوع</span>
<span class="home-slid-arp" style="width:98%;">- خصم 10% على الصيانة</span>
</div>
<span class="home-slid-arp" style="margin-top:18px;height:60px;font-family:Changa, sans-serif;font-size:28px;
background: linear-gradient(180deg,#333,#2e2e2e);color:#fff;width:85%;
border-radius:24px;">أشتراك الان..</span>
</div>
</swiper-slide>
<swiper-slide class="home-slid-slide" style="align-content:flex-start;
border:1px solid #ccc;
border-radius:8px;">
<div class="home-slid-arp" style="height:50px;width:100%;position:relative;
align-content:center;margin-top:18px;">
<img src="/img/pppkip.png" style="display:flex;flex-wrap:wrap;
justify-content:center;position:relative;border-radius:8px;
max-height:50px;object-fit:cover;align-content:center;" />
</div>
<div class="home-slid-arp" style="height:auto;width:98%;position:relative;
align-content:center;">
<span class="home-slid-arp" style="margin-top:18px;height:35px;font-family:Changa, sans-serif;font-size:28px;">
<span style="background:rgba(255,255,255, 0.99);padding:7px;">خدمات الباركنيق</span>
</span>
<span class="home-slid-arp" style="margin-top:7px;height:40px;font-family:Changa, sans-serif;font-size:24px;">
<span style="background:rgba(255,255,255, 0.99);padding:7px;">سنة 3000 ريال</span>
<span style="position:absolute;height:3px;width:222px;border-radius:25px;
background:rgba(244,67,54, 0.6);z-index:-1;top:20px;
"></span>
</span>
<div class="home-slid-arp" style="height:auto;width:98%;position:relative;
align-content:center;">
<span class="home-slid-arp" style="width:98%;">- متوفر 24 ساعة</span>
<span class="home-slid-arp" style="width:98%;">- غسيل مجاني كل اسبوع</span>
<span class="home-slid-arp" style="width:98%;">- خصم 10% على الصيانة</span>
</div>
<span class="home-slid-arp" style="margin-top:18px;height:60px;font-family:Changa, sans-serif;font-size:28px;
background: linear-gradient(180deg,#333,#2e2e2e);color:#fff;width:85%;
border-radius:24px;">أشتراك الان..</span>
</div>
</swiper-slide>
</swiper>
<div class="swiper-button-prev slidbt1-prv" slot="button-prev" 
 style="color:#ddd;position:absolute;top:50%;left:0px;"></div>
<div class="swiper-button-next slidbt1-nxt" slot="button-next"
 style="color:#ddd;position:absolute;top:50%;right:0px;"></div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
export default {
name: 'ViewSlider',
components: {
Swiper,SwiperSlide
},
//props: {msg: String},
data(){
return{
ViewSlider:{
slidesPerView: 'auto',
autoHeight: true,
spaceBetween: 0,
pagination: {
el: '.swiper-pagination',
clickable: true
},
navigation: {
nextEl: ".slidbt1-prv",
prevEl: ".slidbt1-nxt"
}
}
}
},
methods:{

},
watch:{

},
computed: {
...mapState({
setup: state => state.setup,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-slid-arp{
position:relative;display:flex;
flex-wrap:wrap;justify-content:center;
align-content:center;margin-right:auto;margin-left:auto;
}
.home-slid-box{
/*background: linear-gradient(to bottom right, #fff 50%,#f1f1f1 20%,#fdfdfd 30%);*/
position:relative;display: flex;flex-wrap:wrap;
width:100%;height:392px;color:#4e4e4e;
position:relative;z-index:0;
/*padding:14px;*/
margin-right:auto;margin-left:auto;
font-family: 'Cairo', sans-serif;font-weight:400;
cursor:pointer;justify-content: right;
}
.home-slid-box-sw{
/*background: linear-gradient(to bottom right, #fff 50%,#f1f1f1 20%,#fdfdfd 30%);*/
position:relative;display: flex;flex-wrap:wrap;
width:100%;height: auto;color:#4e4e4e;
position:relative;z-index:0;
margin-right:auto;margin-left:auto;
font-family: 'Cairo', sans-serif;font-weight:400;
cursor:pointer;justify-content: right;
}
.home-slid-slide{
position:relative;
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
font-size:16px;color:#4e4e4e;height:360px;border-radius:8px;
margin-left:12px;margin-bottom:12px;width:99.5%;
}
.home-slid-box a{
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
font-size:16px;color:#4e4e4e;width:100%;
}
.home-slid-box a:link{color:#4e4e4e;}
.home-slid-box a:visited{color:#4e4e4e;}

@media (min-width: 200px) and (max-width: 575px){
.home-slid-box{
justify-content:center;
}
}
@media (min-width: 800px) and (max-width: 1200px){
.home-slid-slide{width:32%;}
}
@media (min-width: 1200px){
.home-slid-slide{width:24.25%;}
}

</style>