import { render, staticRenderFns } from "./FooterTime.vue?vue&type=template&id=cffc9f3e&scoped=true&"
import script from "./FooterTime.vue?vue&type=script&lang=js&"
export * from "./FooterTime.vue?vue&type=script&lang=js&"
import style0 from "./FooterTime.vue?vue&type=style&index=0&id=cffc9f3e&prod&lang=css&"
import style1 from "./FooterTime.vue?vue&type=style&index=1&id=cffc9f3e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cffc9f3e",
  null
  
)

export default component.exports