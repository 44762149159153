<template>
<div class="j7-view-details" style="font-family: 'Noto Kufi Arabic', sans-serif;
width:100%;display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
margin:auto;padding-right:14px;padding-left:14px;padding-bottom:18px;
overflow:hidden;">
<div class="j7-view-details-box animate__animated animate__fadeInRightBig" v-if="details.length>0"
 style="display: flex; flex-wrap: wrap;justify-content:center;margin:auto;
align-content:center;height:auto;position:relative;
overflow-x:hidden;overflow-y:scroll;scroll-behavior:smooth;
scrollbar-width:none;">
<div style="line-height:55px;background: #fff;
border-radius:4px;align-content:center;margin:auto;margin-bottom:4px;
display: flex; flex-wrap: wrap;width:97%;justify-content:center;position:relative;">
<div class="j7-view-details-item-name" style="display: flex; flex-wrap: wrap;width:100%;
font-size:16px;height:60px;line-height:60px;
position:relative;justify-content:center;align-content:center;margin:auto;
">
<span style="padding-right:10px;">التفاصيل الاضافية</span>
<span class="mdi mdi-24px mdi-chevron-down" style="color: rgb(102, 102, 102);margin-right:auto;padding-left:14px;"></span>
</div>
</div>
<div style="display: flex;flex-wrap: wrap;justify-content:center;position:relative;
align-content:flex-start;height:auto;max-height:75vh;padding-bottom:7px;
overflow-x:hidden;overflow-y:scroll;scroll-behavior:smooth;
scrollbar-width:none;">
<div class="j7-view-details-item" v-for="(item, index) in details" style="">
<div class="j7-view-details-item-name" style="">
{{item.name}}
</div>
<div class="j7-view-details-item-val" style="">
<a v-if="item.val">{{item.val}}</a>
<a v-if="item.alt" style="padding-right:5px;">{{item.alt}}</a>
</div>
</div>
<div class="j7-view-details-item" v-for="(item, index) in features" style="">
<div class="j7-view-details-item-name" style="">
{{item}}
</div>
<div class="j7-view-details-item-val" style="">
<a v-if="item">
<img src="img/done.svg" style="display:flex;justify-content:center;width:24px;height:24px;max-height:24px;object-fit:contain;" />
</a>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
export default {
name: 'ItemDetaStory',
components: {},
props: ['details','features'],
data(){
return {
}
},
methods: {
},
watch:{

}
}
</script>
<style scoped>
.brk24{display:flex;flex-wrap:wrap;width:100%;min-height:24px;position:relative;}
.brk14{display:flex;flex-wrap:wrap;width:100%;min-height:14px;position:relative;}
.brk5{display:flex;flex-wrap:wrap;width:100%;min-height:5px;position:relative;}
.brk7{display:flex;flex-wrap:wrap;width:100%;min-height:7px;position:relative;}
.mouser{opacity:1;}
.mouser:hover{opacity:0.7;}
.tab-pc-hide{display:none;}
.mouser-bg{background-color:#fff;}
.mouser-bg:hover{background-color:#fcfcfc;}
.shdow{box-shadow: 6px 11px 41px -28px #ddd;
  -webkit-box-shadow: 6px 11px 41px -28px #ddd;

}
.j7-view-details-box{
display:flex;flex-wrap:wrap;width:100%;justify-content:right;align-content:center;

}
.j7-view-details-item{
display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
border: 1px solid #e6e6e6;background: #fff;
padding:5px;width:48%;font-size: 15px;border-radius:8px;
margin-top:1%;margin-left:1%;margin-bottom:1%;margin-right:1%;
}
.j7-view-details-item-name{
display:flex;flex-wrap:wrap;width:100%;justify-content:center;align-content:center;
text-align:center;
}
.j7-view-details-item-val{
display:flex;flex-wrap:wrap;width:100%;justify-content:center;align-content:center;
color:#888;
}
.j7-view-details-item:nth-child(4n+1) {
border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;
}
.j7-view-details-item:nth-child(4n+2) {
border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;
}
.j7-view-details-item:nth-child(4n+3) {
background: #f9f9f9;
}
.j7-view-details-item:nth-child(4n+4) {
background: #f9f9f9;
}
.j7-view-details-item:last-child{
background: #f9f9f9;
}
</style>
<style scoped>
@media (min-width: 800px) and (max-width: 1200px){
.j7-view-details-box{
border-top: none;
}
}
@media (min-width: 1200px){
.j7-view-details-box{
border-top: none;
}
.j7-view-details-item{
display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
border: 1px solid #e6e6e6;
padding:5px;width:30%;font-size: 15px;border-radius:8px;
margin-top:1%;margin-left:1%;margin-bottom:1%;margin-right:1%;
}
}


</style>