<template>
<div class="j7-view-navbar" style="display: flex;width:98%; height:42px;justify-items:center;
font-size:15px;font-weight:500;align-items:center;color:#888;white-space:normal;
cursor:pointer;margin:auto;justify-content:center;
font-family: Cairo, sans-serif;">
<span v-on:click="$router.push('/')" style="display: flex;width:auto;line-height:32px;">
الرئيسية
</span>
<span class="mdi mdi-24px mdi-chevron-left" style="display: flex;width:auto;line-height:32px;"></span>
<span v-for="(item, index) in post_view.cats" v-if="item!='الكل'" style="display: flex;width:auto;line-height:32px;" >
<span>{{item}}</span>
<span class="mdi mdi-24px mdi-chevron-left" style="display: flex;width:auto;line-height:32px;"></span>
</span>
<span class="active" style="display: flex;width:auto;max-width:50%;max-height:32px;line-height:32px;overflow:hidden;">
{{post_view.title}}
</span>
</div>
</template>
<script>
export default {
name: 'ItemNavbar',
components: {},
props: ['postView'],
data(){
return {
post_view:this.postView  
}
},
methods: {

},
created() {

},
mounted() {

},
watch:{

}
}
</script>
<style scoped>
span{opacity:1;color:#888;}
span:hover{opacity:0.8;}
span.active{color:#888;}
</style>
<style scoped>

@media (min-width: 200px) and (max-width: 575px){

}

@media (min-width: 600px) and (max-width: 800px){

}
@media (min-width: 992px) and (max-width: 1400px){


}

@media (min-width: 1400px){

}
</style>