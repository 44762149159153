<template>
<div class="Whats-box-item">
<a style="display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
background:#2db742;
box-shadow: 0px 0px 4px 2px rgba(0,0,0,.2);
-webkit-box-shadow: 0px 0px 4px 2px rgba(0,0,0,.2);
height:45px;width:45px;border-radius:50%;text-decoration:none;"
:href="'https://api.whatsapp.com/send/?phone=966504174134&text='+text+'&type=phone_number&app_absent=0'"
target="_blank" title="تواصل معنا واتساب">
<span class="mdi mdi-24px mdi-whatsapp" style="color:#fff;"></span>
</a>
<a style="display:none;flex-wrap: wrap;justify-content:center;align-content:center;
background:none;padding-right:7px;
height:60px;width:auto;text-decoration:none;"
:href="'https://api.whatsapp.com/send/?phone=966504174134&text='+text+'&type=phone_number&app_absent=0'"
target="_blank" title="تواصل معنا واتساب">
<span style="display: flex; flex-wrap: wrap;height:32px;background:#fff;
box-shadow: 0px 0px 4px 2px rgba(0,0,0,.10);padding:10px;
-webkit-box-shadow: 0px 0px 4px 2px rgba(0,0,0,.10);border-radius:4px;
justify-content:center;align-content:center;color:#4e4e4e;">
<span style="padding:6px;">تحتاج مساعدة ؟</span> <strong style="padding:4px;">كلمنا واتس اب 👋</strong>
</span>
</a>
</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
name: 'Whatsapp',
components: {},
props: ['text'],
data(){
return {
}
},
methods: {
},
watch:{

},
computed: {
...mapState({
setup:state => state.setup,
//selctedCat : state => state.selctedCat,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.Whats-box-item{
background:none;z-index:0;position:relative;cursor:pointer;padding-right:7px;
display: flex; flex-wrap: wrap;height:72px;width:94%;justify-content:right;align-content:center;
color:#4e4e4e;font-weight:500;white-space: pre-wrap;font-size:16px;text-align:center;
}
</style>
