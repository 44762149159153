<template>
<div class="home-footer-maps-box">
<div class="home-footer-maps-title">
<h4 style="color:#4e4e4e;">الموقع </h4>
</div>
<div class="home-footer-maps-item">
<a v-if="setup[0].maps_link"
 v-html="setup[0].maps_link" 
style="margin-left:auto;margin-bottom:14px;">
<!--<iframe 
src="https://www.google.com/maps?q=24.7557387,46.6302148&z=17&output=embed"
style="border:0;" allowfullscreen="" loading="lazy"
 referrerpolicy="no-referrer-when-downgrade">
</iframe>-->
</a>
</div>
<div class="home-footer-maps-small-title" style="width:80%;font-family: 'Noto Kufi Arabic', sans-serif;">
<h4 style="text-align:center;width:94%;font-size:12px;color:#888;display:none;">
طريق خالد بن زيد ، حي الرمال ، الرياض 13251
</h4>
</div>
<!--<div class="home-footer-maps-item-span">
<span style="display:flex;flex-wrap:wrap;font-size:15px;padding-right:14px;padding-left:14px;text-align:center;">
<a href="https://maps.app.goo.gl/MMTaMeke8oEAFH2n8" target="_blank" style="color:#4e4e4e;line-height:28px;">
عرض الخريطة بشكل كامل
</a>
</span>
</div>-->
</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
name: 'FooteMaps',
components: {},
//props: {msg: String},
data(){
return {
     
}
},
methods: {

},
watch:{

},
computed: {
...mapState({
setup:state => state.setup,
//selctedCat : state => state.selctedCat,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style>
.home-footer-maps-box{
position:relative;display:flex;
flex-wrap:wrap;
width:100%;height:300px;
align-content:flex-start;justify-content:center;
}
.home-footer-maps-item{
position:relative;display:flex;flex-wrap:wrap;
width:100%;align-content:center;justify-content:center;
margin-bottom:7px;margin-top:21px;
}
.home-footer-maps-item a{
position:relative;display:flex;flex-wrap:wrap;
width:80%;align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
border-radius:25px;
box-shadow: 0px 5px 5px 0px rgba(82, 63, 105, 0.05);
border-bottom:1px solid #ddd;border-top:1px solid #ddd;
}
.home-footer-maps-box iframe{
position:relative;display:flex;flex-wrap:wrap;max-height:128px;padding:5px;
width:100%;align-content:center;justify-content:center;border-radius:25px;
opacity:0.8;
}
.home-footer-maps-title{
position:relative;display:flex;
width:100%;height:auto;color:#777;
align-content:center;justify-content:center;
padding-top:24px;
}
.home-footer-maps-small-title{
position:relative;display:flex;
width:100%;height:auto;color:#777;
align-content:center;justify-content:center;
}
.home-footer-maps-box h4{
position:relative;display:flex;flex-wrap:wrap;padding:0;
padding-right:10px;
width:auto;align-content:center;justify-content:center;
}
.home-footer-maps-item-span{
display:flex;flex-wrap:wrap;white-space:normal;
width:100%;justify-content:center;
}

@media (min-width: 800px){
.home-footer-maps-box{
padding-top:7px;
}
.home-footer-maps-title{width:100%;max-width:100%;}
.home-footer-maps-item a{
width:94%;max-width:45%;}
.home-footer-maps-box iframe{width:100%;max-width:100%;max-height:112px;}

}
</style>