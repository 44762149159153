<template>
<div class="PageHome j7app-main-com" dir="rtl" style="position:relative;background:#f7f6f6;">
<div class="pop-up-story" v-on:click.self="set_pop_story('')">
<ItemStorySwipePage style="touch-action: pan-x pan-y!important;z-index:999;"></ItemStorySwipePage>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import ItemStorySwipePage from '../components/ItemStorySwipePage.vue'
export default {
name: 'ViewStory',
components: {
ItemStorySwipePage
},
data() {
return {
}
},
methods: {

},
destroyed(){
},
mounted(){
},
updated() {
//alert('updated');
},
watch:{
},
computed: {
...mapState({
pop_story : state => state.pop_story

})
}
}
</script>
<style>
/*
background:linear-gradient(45deg, #f5f5f5,#e6e6e6);
background:#f7f6f6;
*/
.j7app-main-com{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
}
.j7app-header-home{
position: fixed;z-index:1;
display: flex; flex-wrap: wrap;
width: 100%;margin-right:auto;margin-left:auto;max-height:158px;
align-items: flex-start;touch-action: pan-x;
border-radius:0; border: 0px solid transparent;
background:#f7f6f6;
padding:0;
}
.j7app-main-home{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
background:#fff;
}

</style>
<style scoped>
.pop-up-story{z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.5);touch-action: pan-x pan-y!important;
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);
display:flex;flex-wrap:wrap;width:100%;min-height: 100vh;max-height:100vh;overflow:hidden;
min-height: -webkit-fill-available; user-select: none;margin-right:auto;margin-left:auto;
align-items: flex-start;justify-content:center;
cursor:pointer;
}
.pop-up-like{
z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.5);
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);height:100%;
display:flex;flex-wrap:wrap;width:100%;max-height:100vh;overflow:hidden;
max-height: -webkit-fill-available; user-select: none;margin-right:auto;margin-left:auto;
align-content:center;justify-content:center;
cursor:pointer;
}
</style>