<template>
<div class="listbox-home-imgcat" v-on:click.self="guid_view=item.guid,show_data='dt'" 
style="background: rgba(0,0,0,.3);position:absolute;bottom:32px;right:0px; display: flex;flex-wrap:wrap;
width:100%;justify-content: center;align-content:center;z-index:10;">
<div v-on:click.self="guid_view=item.guid,show_data='dt'" class="listbox-home-data" style="display: flex;flex-wrap:wrap;height:75px;align-content:center;">
<div v-on:click.self="guid_view=item.guid,show_data='dt'" class="listbox-home-data-name" style="width:94%;justify-content:right;line-height:32px;
position:relative;z-index:0;
font-size:16px;font-weight:400;align-items:center;color:#f7f6f6;white-space:nowrap;">
<span v-on:click.self="guid_view=item.guid,show_data='dt'" >
{{item.title}}
</span>
<span v-on:click.self="guid_view=item.guid,show_data='dt'" class="mdi mdi-24px mdi-chevron-left" style="color:#eee;line-height:32px;"></span>
<span v-on:click.self="guid_view=item.guid,show_data='dt'" class="listbox-home-data-mater" style="margin-right:auto;font-size:16px;">
{{item.price}} 
<span style="padding-right:5px;" v-if="!item.price.toString().includes(' ')">{{currency}}</span>
</span>
</div>
<div v-on:click.self="guid_view=item.guid,show_data='dt'"  class="listbox-home-data-address" style="width:94%;justify-content:right;opacity:0.8;line-height:32px;
align-items:center;color:#f7f6f6;white-space:nowrap;">
<span v-on:click.self="guid_view=item.guid,show_data='dt'"  style="padding-right:3px;font-size:15px;font-weight:400;align-items:center;align-items:center;">
{{item.model}} <span class="mdi mdi-18px mdi-chevron-left" style="color:#fff;line-height:18px;align-items:center;align-items:center;"></span>
 {{item.type}} 
</span>
<span v-on:click.self="guid_view=item.guid,show_data='dt'" class="listbox-home-data-mater" style="margin-right:auto;font-size:15px;font-weight:400;">
<span style="font-size:13px;padding-left:5px;">{{item.area}}</span>
<span class="mdi mdi-18px mdi-walk" style="color:#f7f6f6;line-height:32px;"></span>
</span>
</div>
</div>
<div v-show="show_data=='dt'" v-on:click="show_data=''" 
class="Bottom-Nav-Story" style="display:flex;flex-wrap:wrap;width:100%;height:100%;
position:fixed;z-index:100;top:0px;padding:14px;align-content:center;margin:auto;
background: rgba(0, 0, 0, 0.1);
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);
overflow-x:hidden;overflow-y:scroll;">
<ItemViewStory v-show="show_data=='dt'" 
:guid="item.guid"></ItemViewStory>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
import ItemViewStory from './ItemViewStory.vue'
//ItemViewStory
//import 'swiper/css/swiper.min.css';
export default {
name: 'ItemStoryBottomBar',
components: {ItemViewStory},
props: ['item'],
data(){
return {
guid_view:'',
show_data:''
}
},
mounted(){

},
methods:{
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
}
},
created() {
},
destroyed(){
},
updated() {
},
computed: {
...mapState({
//db : state => state.db,
hosturl : state => state.hosturl,
pop_story:state => state.pop_story,
currency:state => state.currency
})
},
watch:{

}
}
</script>

<style scoped>
.home-item-list-box{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;
overflow-y: hidden;scrollbar-width:none;
}
.swip-item-home{
display: flex;width:100%;margin-bottom: 0px;
max-width:100%;padding:7px;
margin: 0;padding-bottom: 0px;border: none;
box-sizing: content-box;overflow-x: scroll;overflow-y: hidden;
scroll-snap-type: x mandatory;
scrollbar-width:none;
-webkit-overflow-scrolling: touch;
position:relative;
}
.listbox-home-warp{
display:flex; flex-wrap:wrap;height:auto;
width: 100%; position: relative;
touch-action: pan-x pan-y;
position:relative;font-family: 'Noto Kufi Arabic';font-weight:400;
margin:0;justify-content: center;

}
.listbox-home-box{
display:flex; flex-wrap:wrap;width: 100%; 
/*padding-bottom:100px;*/
touch-action: pan-x pan-y; height: 100%;
justify-content: center;
align-items: flex-start;
overflow-y: scroll; overflow-x: hidden; 
scrollbar-color: auto; scrollbar-width: none;
scroll-snap-type: mandatory;
}
.listbox-home-item{
position:relative;
display:flex;flex-wrap:wrap;
justify-content:center;text-align:center;align-items:center;
width:100%;
z-index: 0;cursor: pointer;
touch-action: none; touch-action: pan-x pan-y;
/*box-shadow: 0px 4px 4px 0px #f5f5f5;*/
overflow: hidden;
/*border-radius:5px 5px 5px 5px;*/
/*border-bottom:1px solid #ededed;*/

}
.listbox-home-imgbox{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
max-width:100%;width:100%;height:100%;
overflow: hidden;scrollbar-width: none;
}
.listbox-home-img{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
height:100%;width:100%;max-width:100%;overflow:scroll;

/*border-radius:5px 5px 5px 5px;*/
}
.listbox-home-data{
display: flex;
align-items: center;justify-content: center;
scrollbar-width: none;width:100%;max-width:94%;
overflow: hidden;z-index: 0;
}
.listbox-home-data-box{
position:relative;width: 100%;max-height:128px;
display: flex;flex-wrap: wrap;
justify-content: center; justify-items:center;
text-align:center;z-index: 0;
}
</style>
<style scoped>
.listbox-home-data-name{
display: flex;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;align-content:center;
}
.listbox-home-data-name span{
display:flex;justify-content:right;
max-width:75%;
overflow-x:scroll;overflow-y:hidden;
scrollbar-width:none!important;
}
.listbox-home-data-address{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;color:#000;
align-content:center;
}
.listbox-home-data-address span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:13px;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}

.listbox-home-data-name img{
padding-right:5px;padding-left:5px;
}

.listbox-home-data-info{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color:#999;justify-content: center;align-content:center;
}
.listbox-home-data-info div{
display:flex;flex-wrap:wrap;justify-content:center;
font-size:12px;white-space:nowrap;align-content:center;
color:#999;scrollbar-width:none;
}
.listbox-home-data-info span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#999;align-content:center;
}
.listbox-home-data-info a{
display:flex;flex-wrap:wrap;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#555;justify-content:right;align-content:center;
}
.listbox-home-data-price{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color: #000; justify-content: center;align-content:center;
}
.listbox-home-data-price span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;
color:#555;align-content:center;
margin:auto;
}
</style>
